import clsx from 'clsx';
import Panel from 'components/Tablist/Panel';
import type { PanelProps } from 'components/Tablist/Panel/types';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './styles.module.css';

const OutletPanel = ({ className, ...props }: Omit<PanelProps, 'children'> & { href: string }) => {
	return (
		<Panel className={clsx(styles.outletPanel, className)} {...props}>
			<Suspense>
				<Outlet />
			</Suspense>
		</Panel>
	);
};

export default OutletPanel;
