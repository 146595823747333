import { ROUTES_URLS } from 'const';
import React, { MouseEvent } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';
import { OrderExtraActionButtonProps } from './types';

const OrderExtraActionButton: React.FC<OrderExtraActionButtonProps> = ({ product }) => {
	const { id } = useParams();

	const lastItemIndex = product.parentIds?.length ?? 1 - 1;
	const filterIds = product.parentIds.slice(0, lastItemIndex); // last item picks exact product, we do not need this

	const searchParamsKeyValuePairArray = filterIds.map((parentId) => `product=${parentId}`);
	const searchParams = `?${searchParamsKeyValuePairArray.join('&')}`;

	const url = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id }) + searchParams;

	const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.stopPropagation();
	};

	return (
		<NavLink data-order-extra-option-button to={url} onClick={handleClick} className={styles.button}>
			<span>...</span>
		</NavLink>
	);
};

export default OrderExtraActionButton;
