import { IService, IServiceType, IServiceWorkType } from 'models/IServices';
import { uuid } from 'utils/shared';

export const servicesData: IService[] = [
	{ id: '00', service: 'Автоперевезення', type: '', workType: '', price: 0, quantity: 0, serviceType: 'regular' },
	{ id: '11', service: 'Послуги вантажників', type: '', workType: '', price: 0, quantity: 0, serviceType: 'regular' },
	{ id: '22', service: 'Послгуни порізки металу', type: '', workType: '', price: 0, quantity: 0, serviceType: 'regular' },
	{
		id: '33',
		service: 'Тонування штукатурки та фарби',
		price: 0,
		quantity: 0,
		colorCode: '',
		baseTone: '',
		collection: '',
		tare: '10л',
		serviceType: 'paint_toning',
	},
];

export const transportationServiceTypesData: IServiceType[] = [];
export const transportationServiceWorkTypesData: IServiceWorkType[] = [];

export const moversServiceTypeData: IServiceType[] = [];
export const moversServiceWorkTypeData: IServiceWorkType[] = [];

export const metalCuttingServiceTypeData: IServiceType[] = [
	{ id: '000', label: 'Порізка Арматури', serviceReference: '22' },
	{ id: '111', label: 'Порізка Листа', serviceReference: '22' },
	{ id: '222', label: 'Порізка Кутник', serviceReference: '22' },
	{ id: '333', label: 'Порізка Смуга', serviceReference: '22' },
	{ id: '444', label: 'Порізка Труба Кругла', serviceReference: '22' },
	{ id: '555', label: 'Порізка Труба профільна (квадратна)', serviceReference: '22' },
	{ id: '666', label: 'Порізка Швеллер', serviceReference: '22' },
];
export const metalCuttingServiceWorkTypeData: IServiceWorkType[] = [
	{ id: uuid(), label: 'Арматура металева ф=6', price: 80, serviceTypeReference: '000' },
	{ id: uuid(), label: 'Арматура металева ф=8', price: 90, serviceTypeReference: '000' },
	{ id: uuid(), label: 'Арматура металева ф=10', price: 150, serviceTypeReference: '000' },
	{ id: uuid(), label: 'Арматура металева ф=12', price: 170, serviceTypeReference: '000' },
	{ id: uuid(), label: 'Арматура металева ф=14', price: 200, serviceTypeReference: '000' },

	{ id: uuid(), label: 'Лист металевий 1mm', price: 100, serviceTypeReference: '111' },
	{ id: uuid(), label: 'Лист металевий 3mm', price: 150, serviceTypeReference: '111' },
	{ id: uuid(), label: 'Лист металевий 5mm', price: 250, serviceTypeReference: '111' },
	{ id: uuid(), label: 'Лист металевий 8mm', price: 450, serviceTypeReference: '111' },

	{ id: uuid(), label: 'Смуга 2mm', price: 30, serviceTypeReference: '333' },
	{ id: uuid(), label: 'Смуга 3mm', price: 40, serviceTypeReference: '333' },
	{ id: uuid(), label: 'Смуга 4mm', price: 50, serviceTypeReference: '333' },
	{ id: uuid(), label: 'Смуга 5mm', price: 60, serviceTypeReference: '333' },

	{ id: uuid(), label: 'Труба металева Ø 1см', price: 50, serviceTypeReference: '444' },
	{ id: uuid(), label: 'Труба металева Ø 2см', price: 100, serviceTypeReference: '444' },
	{ id: uuid(), label: 'Труба металева Ø 4см', price: 150, serviceTypeReference: '444' },
	{ id: uuid(), label: 'Труба металева Ø 6см', price: 200, serviceTypeReference: '444' },

	{ id: uuid(), label: 'Швеллер металевий 100x100', price: 1000, serviceTypeReference: '666' },
	{ id: uuid(), label: 'Швеллер металевий 150x150', price: 2000, serviceTypeReference: '666' },
	{ id: uuid(), label: 'Швеллер металевий 200x200', price: 3000, serviceTypeReference: '666' },
	{ id: uuid(), label: 'Швеллер металевий 250x250', price: 4000, serviceTypeReference: '666' },

	{ id: uuid(), label: 'Профіль металевий 20x20x2', price: 200, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 30x30x2', price: 240, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 40x40x2', price: 280, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 20x20x3', price: 240, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 30x30x3', price: 300, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 40x40x3', price: 320, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 20x20x4', price: 280, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 30x30x4', price: 320, serviceTypeReference: '555' },
	{ id: uuid(), label: 'Профіль металевий 40x40x4', price: 360, serviceTypeReference: '555' },

	{ id: uuid(), label: 'Кутник металевий 30x30', price: 120, serviceTypeReference: '222' },
	{ id: uuid(), label: 'Кутник металевий 40x40', price: 130, serviceTypeReference: '222' },
	{ id: uuid(), label: 'Кутник металевий 50x50', price: 150, serviceTypeReference: '222' },
];
export const paintToningCollectionsService: IServiceType[] = [
	{ id: uuid(), label: 'NSC', serviceReference: '33' },
	{ id: uuid(), label: 'ABC', serviceReference: '33' },
	{ id: uuid(), label: 'Nimbus-2000', serviceReference: '33' },
];
export const paintToningBaseTonesService: IServiceType[] = [
	{ id: uuid(), label: 'A', serviceReference: '33' },
	{ id: uuid(), label: 'B', serviceReference: '33' },
	{ id: uuid(), label: 'C', serviceReference: '33' },
];

export const colorantData = [
	{
		parentIds: [
			'a26b699d-ee79-11ec-b88e-95373851a7ce',
			'f229d75c-efb4-11ec-b88e-95373851a7ce',
			'04ebd678-efb5-11ec-b88e-95373851a7ce',
			'51e1dd18-07fb-11ed-b895-b8bea5b9a50f',
		],
		id: '7aaea253-4d34-11ec-8135-ef535d1e94b0',
		parentId: '51bf0c5f-4d22-11ec-8135-ef535d1e94b0',
		brand: 'CAPAROL',
		title: 'Пігментна паста EX Amphitint ATP 01 Oxidgelb, 1 мл',
		price: '1,0387',
		quantity: '2 309,93',
		inReserve: '',
		weight: '0,018',
		volume: '0,001764',
		coefficient: '1',
		pickedAmount: 1,
		measureUnit: {
			label: 'мл',
			value: 'мл',
		},
		isColorant: true,
	},
	{
		parentIds: [
			'a26b699d-ee79-11ec-b88e-95373851a7ce',
			'f229d75c-efb4-11ec-b88e-95373851a7ce',
			'04ebd678-efb5-11ec-b88e-95373851a7ce',
			'51e1dd19-07fb-11ed-b895-b8bea5b9a50f',
		],
		id: 'b675ac6e-4d34-11ec-8135-ef535d1e94b0',
		parentId: '51bf0c5f-4d22-11ec-8135-ef535d1e94b0',
		brand: 'CAPAROL',
		title: 'Пігментна паста EX Amphitint ATP 02 Oxidschwarz, 1 мл',
		price: '1,77378',
		quantity: '4 687,27',
		inReserve: '',
		weight: '0,022',
		volume: '0,001764',
		coefficient: '1',
		pickedAmount: 1,
		measureUnit: {
			label: 'мл',
			value: 'мл',
		},
		isColorant: true,
	},
];
