import { PriceTypeItem } from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes/types';
import { IColumns } from 'models/IColumns';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export type OrderContext = {
	isFullScreenMode: boolean;
	isAdjustmentOpen: boolean;
	toggleFullScreen(): void;
	toggleIsAdjustmentOpen(): void;
	closeAdjustment(): void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedItems: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	productColumns: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	toggleSelectedItems(items: any[]): void;
	adjustColumns(columns: IColumns[]): void;
	togglePriceColumns(index: number, item: PriceTypeItem, isActive?: boolean): void;
	pinColumns?: string[];
	isChangePriceMode: boolean;
	toggleChangePriceMode(): void;
	exitChangePriceMode(): void;
};

const OrderContext = createContext<OrderContext>(null);

export const OrderProvider = ({ children, value }: PropsWithChildren & { value: OrderContext }) => {
	return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export const useOrderContext = () => {
	const ctx = useContext(OrderContext);

	if (!ctx) {
		throw new Error('useOrderContext must be used inside OrderProvider');
	}

	return ctx;
};
