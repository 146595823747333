/* eslint-disable react/prop-types */
import Table from 'components/Table';
import { HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { IColumns } from 'models/IColumns';
import React from 'react';
import { ReactComponent as Delete } from 'static/images/trash.svg';

import { IProps } from './types';

const PriceTable: React.FC<IProps> = ({ products }) => {
	const initialColumns: IColumns[] = [
		{
			Header: () => (
				<HeaderCell>
					<HeaderCellContent value="№" />
				</HeaderCell>
			),
			accessor: 'id',
			width: 56,
			Cell: ({ row }) => (
				<RegularCell>
					<RegularCellContent justify="center">{Number(row.id) + 1}.</RegularCellContent>
				</RegularCell>
			),
			mobile: false,
			skip: true,
		},
		{
			Header: ({ isAdjustable, column }) => {
				const title = 'Код';
				return isAdjustable ? (
					<>{title}</>
				) : (
					<HeaderCell>
						<HeaderCellContent sortable value="Код" justify="center" column={column} />
					</HeaderCell>
				);
			},
			accessor: 'code',
			sortType: 'alphanumeric',
			Cell: ({ row }) => (
				<RegularCell justify={'start'}>
					<RegularCellContent value={row.values.code} />
				</RegularCell>
			),
			mobile: false,
			isAdjustable: true,
		},
		{
			Header: ({ isAdjustable, column }) => {
				const title = 'Товари цінової групи';
				return isAdjustable ? (
					<>{title}</>
				) : (
					<HeaderCell>
						<HeaderCellContent sortable value="Товари цінової групи" justify="center" column={column} />
					</HeaderCell>
				);
			},
			accessor: 'name',
			sortType: 'alphanumeric',
			Cell: ({ row }) => (
				<RegularCell justify={'start'}>
					<RegularCellContent value={row.values.name} />
				</RegularCell>
			),
			mobile: false,
			isAdjustable: true,
		},
		{
			Header: ({ isAdjustable, column }) => {
				const title = 'Од-ці';
				return isAdjustable ? (
					<>{title}</>
				) : (
					<HeaderCell>
						<HeaderCellContent sortable value="Од-ці" justify="center" column={column} />
					</HeaderCell>
				);
			},
			accessor: 'units',
			sortType: 'alphanumeric',
			Cell: ({ row }) => (
				<RegularCell justify={'start'}>
					<RegularCellContent value={row.values.units} />
				</RegularCell>
			),
			mobile: false,
			isAdjustable: true,
		},
		{
			Header: ({ isAdjustable, column }) => {
				const title = 'Ціна товару';
				return isAdjustable ? (
					<>{title}</>
				) : (
					<HeaderCell>
						<HeaderCellContent sortable value="Ціна товару" justify="center" column={column} />
					</HeaderCell>
				);
			},
			accessor: 'price',
			sortType: 'alphanumeric',
			Cell: ({ row }) => (
				<RegularCell justify={'start'}>
					<RegularCellContent value={row.values.price} />
				</RegularCell>
			),
			mobile: false,
			isAdjustable: true,
		},
		{
			Header: ({ isAdjustable, column }) => {
				const title = 'Фото';
				return isAdjustable ? (
					<>{title}</>
				) : (
					<HeaderCell>
						<HeaderCellContent sortable value="Фото" justify="center" column={column} />
					</HeaderCell>
				);
			},
			accessor: 'img',
			sortType: 'alphanumeric',
			Cell: ({ row }) => (
				<RegularCell justify={'start'}>
					<img src={row.values.img} />
				</RegularCell>
			),
			mobile: false,
			isAdjustable: true,
		},
		{
			Header: () => (
				<HeaderCell>
					<HeaderCellContent value="Дії" justify="center" />
				</HeaderCell>
			),
			accessor: 'actions',
			width: 100,
			Cell: () => {
				return (
					<RegularCell>
						<RegularCellContent justify="center">
							<Delete />
						</RegularCellContent>
					</RegularCell>
				);
			},
			mobile: false,
			isAdjustable: false,
		},
		{
			Header: () => (
				<HeaderCell>
					<HeaderCellContent value="Обрати клієнта:" />
				</HeaderCell>
			),
			accessor: 'Choose',
			width: 100,
			mobile: true,
			hidden: false,
		},
		{
			Header: ({ column }) => (
				<HeaderCell>
					<HeaderCellContent sortable withIndeterminate column={column} value="Сортувати А - Я" />
				</HeaderCell>
			),
			sortType: 'alphanumeric',
			accessor: 'sort',
			width: 100,
			mobile: true,
			hidden: false,
		},
	];
	return <Table allData={products} columns={initialColumns} selectable={false} />;
};

export default PriceTable;
