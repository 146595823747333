import 'static/styles/style.css';

import clsx from 'clsx';
import Cell from 'components/Table/TableComponents/BasicElements/Cell';
import ExpandedColumn from 'components/Table/TableComponents/BasicElements/ExpandedColumn';
import ExpandedHeader from 'components/Table/TableComponents/BasicElements/ExpandedHeader';
import Row from 'components/Table/TableComponents/BasicElements/Row';
import SelectTableFooter from 'components/Table/TableComponents/Footers/SelectTableFooter';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import TableCheckbox from '../../TableComponents/CheckboxColumn/TableCheckbox';
import styles from './style.module.css';
import type { IProps } from './types';

const removeSortingButton = (ref: MutableRefObject<HTMLTableRowElement>) => {
	if (ref.current) {
		const btn = ref.current.querySelector('.order-mobile-header-cell [data-sorting-button]');
		btn?.remove();
	}
};

const SelectProductMobileTable: React.FC<IProps> = ({ row, onChangeQuantityClick, onAddProductClick }) => {
	const [open, setOpen] = useState(false);
	const columnRef = useRef<HTMLTableRowElement>(null);

	useEffect(() => {
		if (columnRef.current && open) {
			removeSortingButton(columnRef);
			columnRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [open]);

	useEffect(() => {
		removeSortingButton(columnRef);
	}, []);

	const toggleExpand = () => setOpen(!open);

	const titleCell = useMemo(() => row.cells?.find(({ column }) => column.id === 'title'), [row.cells]);

	if (!open) {
		return (
			<Row gridCustomLayout="44px auto" bottomBorder="var(--gray-200)" onClick={toggleExpand}>
				<td className={clsx(styles.textCenter, 'text-sx-regular color-grey-600')}>{Number(row.id) + 1}.</td>
				<td className={clsx('text-sx-medium color-grey-700', styles.rowTitle)}>{titleCell?.render('Cell')}</td>
			</Row>
		);
	}

	return (
		<ExpandedColumn ref={columnRef} className={styles.table}>
			<ExpandedHeader onClick={toggleExpand} onCollapse={toggleExpand}>
				<span className={styles.headerContent}>
					<TableCheckbox {...row.getToggleRowSelectedProps()} className={styles.checkbox} />
					<span className={styles.title}>{titleCell?.render('Cell')}</span>
				</span>
			</ExpandedHeader>

			{row.cells
				.filter((cell) => !cell.column.skip)
				.map((cell, index) => {
					if (index < 2) return null;

					return (
						<Row
							as="td"
							gridCustomLayout="100px 1fr"
							key={`${cell.id}${index}`}
							bottomBorder="var(--gray-200)"
							gridTemplate="regular"
							vl="var(--gray-200)"
							highlight="even"
						>
							<Cell as="span" className={clsx('line-clamp', 'order-mobile-header-cell')}>
								{cell.render('Header')}
							</Cell>
							<Cell as="span">{cell.render('Cell')}</Cell>
						</Row>
					);
				})}

			<SelectTableFooter
				className={styles.footer}
				onAddItem={() => onAddProductClick(row.original)}
				onQuantityChange={() => onChangeQuantityClick(row.original)}
			/>
		</ExpandedColumn>
	);
};

export default SelectProductMobileTable;
