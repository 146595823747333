import 'static/styles/style.css';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Textarea from 'components/Textarea';
import { ROUTES_URLS } from 'const';
import { useOrderComment } from 'hooks/orders';
import React, { lazy, MouseEvent, Suspense, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Email } from 'static/images/email2.svg';
// import { ReactComponent as File } from 'static/images/file.svg';
import { ReactComponent as Telegram } from 'static/images/telegram.svg';
import Trash from 'static/images/trash-bold.svg';
import { ReactComponent as Truck } from 'static/images/truck.svg';
import { ReactComponent as Viber } from 'static/images/viber.svg';
import { ReactComponent as WhatsApp } from 'static/images/whatsapp.svg';
import { useDeleteOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { prepareUrl } from 'utils/shared';

import Print from '../StructureDrawerComponent/Print';
import styles from './style.module.css';

const ConfirmDialogue = lazy(() => import('components/ConfirmDialogue'));

const OrderAdditionalDrawer = () => {
	const { id } = useParams<{ id: string }>();
	const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
	const comment = useOrderComment({ onSuccess: closeDrawer, global: true });

	const [deleteOrder, { isLoading }] = useDeleteOrderMutation();
	const navigate = useNavigate();

	function closeDrawer() {
		setIsOpenDrawer(false);
	}

	const handleDeleteButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsOpenDrawer(true);
	};

	const handleRecordDelete = async () => {
		await deleteOrder(id);

		toast.success('Заявку успішно видалено');

		const url = prepareUrl(ROUTES_URLS.ORDERS);
		navigate(url);
	};

	return (
		<div>
			<Textarea
				label={'Додати коментар'}
				className={styles.textArea}
				value={comment.value}
				setValue={comment.setComment}
				placeholder="A little about the company and the team that you’ll be working with."
			/>

			<div className={styles.typicalWrapper}>
				<p className="text-md-medium">Роздрукувати</p>
				<Print title="Документ на проїзд" icon={<Truck />} checked={false} className={styles.printWrapper} />

				{/* !!! TEMP disable till there is no decision to this functionality from customer */}
				{/* <Print title="Лист видачі" icon={<File />} checked={false} className={styles.printWrapper} /> */}
			</div>

			<div className={styles.typicalWrapper}>
				<p className="text-md-medium">Надіслати</p>
				<Print title="Viber" icon={<Viber />} checked={false} className={styles.printWrapper} />
				<Print title="WhatsApp" icon={<WhatsApp />} checked={false} className={styles.printWrapper} />
				<Print title="Telegram" icon={<Telegram />} checked={false} className={styles.printWrapper} />
				<Print title="E-mail" icon={<Email />} checked={false} className={styles.printWrapper} />
			</div>
			<Button variant="default" onClick={handleDeleteButtonClick} text="Видалити заявку" icon={Trash} className={styles.redBtn} />

			<Suspense fallback={<Spinner />}>
				{isOpenDrawer && (
					<ConfirmDialogue
						headerTitle="Видалення заявки"
						title="Дію не можливо відмінити."
						subtitle="Впевнені, що хочете видалити заявку?"
						open={isOpenDrawer}
						accentActionButton="cancel"
						onClose={closeDrawer}
						onCancel={closeDrawer}
						onSubmit={handleRecordDelete}
					/>
				)}
			</Suspense>

			{isLoading && <Spinner />}
		</div>
	);
};

export default OrderAdditionalDrawer;
