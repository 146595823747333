import Spinner from 'components/Spinner';
import React, { createContext, lazy, ReactNode, Suspense, useContext, useMemo, useState } from 'react';

const DeleteProductAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteProductAlertDialogue'));
const DeleteSuborderAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteSuborderAlertDialogue'));
const DeleteServicesAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteServicesAlertDialogue'));
const ChangeOrderTypeAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/ChangeOrderTypeAlertDialogue'));
const SuborderReservationAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/SuborderReservationAlertDialogue'));
const SaveMainOrderAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/SaveMainOrderAlertDialogue'));
const MainOrderReservationAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/MainOrderReservationAlertDialogue'));
const DeleteMainOrderProductAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteMainOrderProductAlertDialogue'));
const CancelSpittingAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/CancelSpittingAlertDialogue'));
const SaveSplittingAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/SaveSplittingAlertDialogue'));
const DeleteMainOrderServicesAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/DeleteMainOrderServicesAlertDialogue'),
);
const InvalidPaintToningProductsAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/InvalidProductsTransferAlertDialogue'),
);

export type StackedModal = ReactNode;
export type Config<TKey> = { key: TKey };
export type StackedModalObject<TKeys> = Config<TKeys> & {
	modal: StackedModal;
};

export type OrderAlertDialoguesContext = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	open: (key: ModalKey, payload?: any) => void;
	close: () => void;
};
export type ModalProviderProps = {
	children: ReactNode;
};

export const Context = createContext<OrderAlertDialoguesContext>(null);

export const useOrderAlertDialogue = () => {
	const ctx = useContext(Context);

	if (!ctx) {
		throw new Error('The hook useOrderAlertDialogue must be used inside OrderAlertDialoguesContext provider');
	}

	return ctx;
};

const ContextProvider = Context.Provider;

const MODAL_KEYS = {
	deleteProducts: 'deleteProducts',
	deleteInMemorySuborder: 'deleteInMemorySuborder',
	deleteServices: 'deleteServices',
	deleteMainOrderServices: 'deleteMainOrderServices',
	deleteMainOrderProducts: 'deleteMainOrderProducts',
	changeOrderType: 'changeOrderType',
	invalidPaintToningProducts: 'invalidPaintToningProducts',
	invalidRegularProducts: 'invalidRegularProducts',
	suborderReservation: 'suborderReservation',
	mainOrderReservation: 'mainOrderReservation',
	saveSplitting: 'saveSplitting',
	saveMainOrder: 'saveMainOrder',
	cancelSplitting: 'cancelSplitting',
} as const;

type ModalKey = keyof typeof MODAL_KEYS;

const OrderAlertDialoguesProvider: React.FC<ModalProviderProps> = ({ children }) => {
	const [modalKey, setModalKey] = useState<ModalKey | null>(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [payload, setPayload] = useState<any>(null);

	const ctx = useMemo<OrderAlertDialoguesContext>(
		() => ({
			open: (key: ModalKey, payloadData) => {
				if (payloadData) {
					setPayload(payloadData);
				}
				setModalKey(key);
			},
			close: () => {
				setModalKey(null);
				setPayload(null);
			},
		}),
		[],
	);

	return (
		<ContextProvider value={ctx}>
			{children}

			<Suspense fallback={<Spinner />}>
				{modalKey === MODAL_KEYS.deleteProducts && <DeleteProductAlertDialogue />}
				{modalKey === MODAL_KEYS.deleteInMemorySuborder && <DeleteSuborderAlertDialogue />}
				{modalKey === MODAL_KEYS.deleteServices && <DeleteServicesAlertDialogue />}
				{modalKey === MODAL_KEYS.changeOrderType && <ChangeOrderTypeAlertDialogue />}
				{modalKey === MODAL_KEYS.invalidPaintToningProducts && <InvalidPaintToningProductsAlertDialogue whoFailed="paintToning" />}
				{modalKey === MODAL_KEYS.invalidRegularProducts && <InvalidPaintToningProductsAlertDialogue whoFailed="regular" />}
				{modalKey === MODAL_KEYS.suborderReservation && <SuborderReservationAlertDialogue />}
				{modalKey === MODAL_KEYS.saveSplitting && <SaveSplittingAlertDialogue />}
				{modalKey === MODAL_KEYS.deleteMainOrderProducts && <DeleteMainOrderProductAlertDialogue items={payload} />}
				{modalKey === MODAL_KEYS.deleteMainOrderServices && <DeleteMainOrderServicesAlertDialogue items={payload} />}
				{modalKey === MODAL_KEYS.saveMainOrder && <SaveMainOrderAlertDialogue />}
				{modalKey === MODAL_KEYS.mainOrderReservation && <MainOrderReservationAlertDialogue />}
				{modalKey === MODAL_KEYS.cancelSplitting && <CancelSpittingAlertDialogue />}
			</Suspense>
		</ContextProvider>
	);
};

export default OrderAlertDialoguesProvider;
