import TransferProductDetailsModal from 'components/OrderPageComponents/(TransferModals)/TransferProductDetailsModal';
import TransferProductsModal from 'components/OrderPageComponents/(TransferModals)/TransferProductsModal';
import TransferServicesModal from 'components/OrderPageComponents/(TransferModals)/TransferServiceModal';
import { useOrderContext } from 'contexts/OrderProvider';
import { useModal } from 'hooks/useModal';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';

const MODAL_KEYS = {
	'split:transfer-list': 'split:transfer-list',
	'split:transfer-details': 'split:transfer-details',
} as const;

export const useTransferModals = () => {
	const {
		suborders: { handlers },
	} = useOrderViewer();
	const { selectedItems, toggleSelectedItems } = useOrderContext();

	const productDetailsModal = useModal({
		modal: ({ closeModal }) => <TransferProductDetailsModal onClose={closeModal} />,
		key: MODAL_KEYS['split:transfer-details'],
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleProductClick = (product: any) => {
		handlers.setTransferItemToSeeDetails(product);
		productDetailsModal.openModal();
	};

	const handleTransferProductsModalClose = (closeCb: () => void) => {
		closeCb();
		handlers.clearTransferItemToSeeDetails();
	};

	let targetSuborderIndex = 0;

	const transferProductsModal = useModal({
		modal: ({ closeModal }) => (
			<TransferProductsModal
				onProductClick={handleProductClick}
				targetSuborderIndex={targetSuborderIndex}
				onClose={handleTransferProductsModalClose.bind(null, closeModal)}
			/>
		),
		key: MODAL_KEYS['split:transfer-list'],
	});

	const transferServiceModal = useModal({
		modal: ({ closeModal }) => {
			const handleClose = () => {
				toggleSelectedItems([]);
				closeModal();
			};

			return <TransferServicesModal selectedItems={selectedItems} targetSuborderIndex={targetSuborderIndex} onClose={handleClose} />;
		},
		key: 'split:transfer-services',
	});

	const open = (idx: number) => {
		targetSuborderIndex = idx;
		transferProductsModal.openModal();
	};
	const openService = (idx: number) => {
		targetSuborderIndex = idx;
		transferServiceModal.openModal();
	};

	return {
		open,
		openService,
	};
};
