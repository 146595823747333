import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';

import EmptySuborder from '../EmptySuborder';

const EmptyProductsGate: React.FC<React.PropsWithChildren> = ({ children }) => {
	const {
		activeSuborder: { products },
	} = useOrderViewer();
	const hasProducts = products.length > 0;

	return hasProducts ? children : <EmptySuborder />;
};

export default EmptyProductsGate;
