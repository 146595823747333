/* eslint-disable react/prop-types */
import 'static/styles/style.css';

import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import { CheckboxField } from 'components/FormComponents/Inputs/inputs';
import AutocompleteFilters from 'components/OrderCreatePageComponents/AutocompleteFilters';
import TopBarContainer from 'components/OrderCreatePageComponents/TopBarContainer';
import ToggleOrderTypeGate from 'components/OrderPageComponents/(VisibilityGates)/ToggleOrderTypeGate';
import Payable from 'components/OrderPageComponents/Payable';
import ToggleOrderType from 'components/OrderPageComponents/ToggleOrderType';
import OrderTopBar from 'components/OrderTopBar';
import Spinner from 'components/Spinner';
import { TablistRouter } from 'components/Tablist';
import { breakPoints, ROUTES_URLS } from 'const';
import { OrderProvider } from 'contexts/OrderProvider';
import { useOrder } from 'hooks/orders';
import { useOrderViewer } from 'hooks/useOrderViewer';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, Suspense, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MediaQuery from 'react-responsive';
import { ScrollRestoration } from 'react-router-dom';
import SaveIcon from 'static/images/save.svg';
import BidirectionalArrowsIcon from 'static/images/switch-horizontal-02.svg';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import ControlPanel from './ControlPanel';
import OutletPanel from './OutletPanel';
import styles from './styles.module.css';
import { useOrderContextValue } from './useOrderContextValue';

const RefreshActions = lazy(() => import('components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions'));
const ActionButton = lazy(() => import('layouts/PageLayout/ActionButton'));
const BottomPanel = lazy(() => import('layouts/PageLayout/BottomPanel'));
const Chip = lazy(() => import('components/Chip'));

const ProductsPanel = OutletPanel;
const ServicesPanel = OutletPanel;

const Order: React.FC = () => {
	const { id, order, client } = useOrder();
	const { stats, draftOrder, api } = useOrderViewer();
	const form = useForm({ defaultValues: { ...client, noPayment: false } });
	const ctxValue = useOrderContextValue();

	useEffect(() => {
		if (order) {
			// @ts-ignore
			form.setValue('noPayment', order?.noPayment ?? false);
		}
	}, [order]);

	const crumbs: Breadcrumb[] = [{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) }, bakeOrderBreadcrumbs(order)];

	const hasProductsInOrder = !!draftOrder.products.length;

	const { totalPrice, totalVolume, totalWeight } = stats;

	const productsCount = draftOrder.productsCount;
	const servicesCount = draftOrder.allServicesCount;

	const requestLogistics = () => {
		alert('Заявка на логістику надіслана');
	};

	const handleTabChange = () => {
		ctxValue.exitChangePriceMode();
	};

	const rootPath = prepareUrl(ROUTES_URLS.ORDER, { id });
	const shouldRenderTopElements = !ctxValue.isFullScreenMode;

	return (
		<Suspense fallback={<PageContentSkeleton />}>
			<FormProvider {...form}>
				<OrderProvider value={ctxValue}>
					<div className="main-wrapper">
						<div className={clsx('container', styles.container)}>
							<BreadCrumbs crumbs={crumbs} />

							{shouldRenderTopElements && (
								<>
									{hasProductsInOrder && (
										<>
											<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
												<div className={styles.refreshActionsWrapper}>
													<CheckboxField label="Без оплати" name="noPayment" className={styles.noPaymentCheckbox} />
													<RefreshActions />
												</div>
											</MediaQuery>

											<MediaQuery minWidth={breakPoints.MOBILE}>
												<OrderTopBar onRequestLogistics={requestLogistics} />
											</MediaQuery>

											<div className={styles.totalPriceWrapper}>
												<Payable price={totalPrice} />

												<div className={styles.chipsWrapper}>
													<Chip title={totalWeight} />
													<Chip title={totalVolume} />
												</div>

												<MediaQuery minWidth={breakPoints.MOBILE}>
													<CheckboxField label="Без оплати" name="noPayment" className={styles.checkboxField} />

													<ToggleOrderTypeGate>
														<ToggleOrderType className={styles.checkboxField} />
													</ToggleOrderTypeGate>
												</MediaQuery>
											</div>
										</>
									)}

									<TopBarContainer className={clsx(styles.topBarContainer, { [styles.emptyOrder]: !hasProductsInOrder })}>
										<AutocompleteFilters />
									</TopBarContainer>

									{hasProductsInOrder && (
										<div className={styles.bottomPanelWrapper}>
											<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
												<BottomPanel className={styles.bottomPanel}>
													<div>
														<ActionButton
															text="Оновити залишки"
															variant="rounded"
															background="var(--primary-500)"
															icon={BidirectionalArrowsIcon}
														/>
														<ActionButton
															text="Зберегти заявку"
															variant="rounded"
															background="var(--pink)"
															icon={SaveIcon}
														/>
													</div>
												</BottomPanel>
											</MediaQuery>
										</div>
									)}
								</>
							)}

							<div className={styles.pageContent}>
								<TablistRouter rootPath={rootPath} slot={<ControlPanel />} onTabChange={handleTabChange}>
									<ProductsPanel href={rootPath} itemsCount={productsCount} title="Додані товари" />
									<ServicesPanel href={ROUTES_URLS.ORDER_SERVICES} itemsCount={servicesCount} title="Послуги" />
								</TablistRouter>
							</div>

							{api.isProcessing && <Spinner />}
						</div>

						<ScrollRestoration
							getKey={(location) => {
								const paths = [rootPath, `${rootPath}/services`];
								return paths.includes(location.pathname) ? location.pathname : location.key;
							}}
						/>
					</div>
				</OrderProvider>
			</FormProvider>
		</Suspense>
	);
};

export default Order;
