import { ROUTES_URLS } from 'const';
import { LoaderFunction, redirect } from 'react-router-dom';
import { store } from 'store';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';

import { fetchClients } from './clients';
import { fetchProducts } from './products';

export const fetchOrderById: LoaderFunction = async ({ params }) => {
	const id = params?.id;

	if (!id) {
		return redirect(ROUTES_URLS.HOME);
	}

	const promise = store.dispatch(ordersSliceApi.endpoints.getOrderById.initiate(id)).unwrap();

	return promise;
};

export const fetchProductFilters = async () => {
	const promise = store.dispatch(ordersSliceApi.endpoints.getProductFilters.initiate(undefined)).unwrap();

	return promise;
};

/** ROUTE HANDLERS */

export const selectProductsHandler: LoaderFunction = async (route) => {
	const promise = Promise.all([fetchProducts(route), fetchOrderById(route), fetchProductFilters(), fetchClients(route)]);

	return promise;
};

export const selectClientStepHandler: LoaderFunction = async (route) => {
	const promise = Promise.all([fetchOrderById(route), fetchClients(route)]);

	return promise;
};

export const createOrderHandler = async () => {
	const orderNumberPivot = Number(localStorage.getItem('prevOrderNumber') ?? 38383585);
	const orderNumber = orderNumberPivot + 1;

	localStorage.setItem('prevOrderNumber', String(orderNumber));

	const order = {
		number: orderNumber,
		createdAt: new Date().toString(),
		products: [],
		status: '',
		client: {
			meta: {
				manager: {
					id: '51bd0e99-4f57-11e6-80d1-3497f6001881',
					name: 'Андрущенко Анастасія',
				},
				supervisor: {
					id: '88eb5240-b5bc-11e4-9dd6-93ce85b7aa42',
					name: 'Кокриш Вікторія',
				},
				department: {
					id: '036ff180-bb60-11e4-9dd6-93ce85b7aa42',
					label: 'Софіївська Борщагівка',
					value: 'Софіївська Борщагівка',
				},
			},
		},
	};
	const promise = store
		.dispatch(
			ordersSliceApi.endpoints.createOrder.initiate(order, {
				fixedCacheKey: 'new-order',
			}),
		)
		.unwrap();
	store.dispatch(ordersSliceApi.util.prefetch('getOrderStatusList', undefined, { force: true }));

	return promise;
};

export const singleOrderHandler: LoaderFunction = (route) => {
	const promise = Promise.all([fetchOrderById(route), fetchClients(route)]);

	return promise;
};
