import clsx from 'clsx';
import Spinner from 'components/Spinner';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import ProductReservationCard from '../ProductReservationCard';
import InStockTable from '../Tables/InStockTable';
import WithdrawReservationModal from '../WithdrawReservationModal';
import styles from './styles.module.css';
import type { IProps } from './types';

const columns = [
	{ Header: 'Склад', accessor: 'warehouse', width: 56 },
	{ Header: 'Наявність', accessor: 'inStock', width: 56 },
	{ Header: 'Резерв', accessor: 'reservation', width: 56 },
];

const data = [
	{ id: 1, warehouse: 'Соф. Борщагівка', inStock: 0, reservation: 0, isCurrent: true },
	{ id: 2, warehouse: 'Гатне', inStock: 20, reservation: 2 },
	{ id: 3, warehouse: 'Бровари', inStock: 30, reservation: 0 },
	{ id: 4, warehouse: 'Ірпінь', inStock: 40, reservation: 4 },
	{ id: 5, warehouse: 'Боярка', inStock: 50, reservation: 5 },
	{ id: 6, warehouse: 'Бориспіль', inStock: 60, reservation: 0 },
];

// ! TEMP makes fake delay as if we were sending real api request
const sleep = (delay: number) =>
	new Promise((res) =>
		setTimeout(() => {
			res({ status: 201 });
		}, delay),
	);

const AvailabilityChecker: React.FC<IProps> = ({ className }) => {
	const [selectedRow, setSelectedRow] = useState(null);
	const [withdrawReservationItem, setWithdrawReservationItem] = useState(null);

	// ! TEMP for imitate api request
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleRowClick = (row: any) => {
		// TODO add type
		setSelectedRow(row);
	};

	const resetSelection = () => {
		setSelectedRow(null);
	};

	const handleReservationWithdraw = useCallback((item) => {
		setWithdrawReservationItem(item);
	}, []);

	const closeModal = () => {
		setWithdrawReservationItem(null);
	};

	const handleReservationWithdrawal = async () => {
		setIsProcessing(true);
		await sleep(3000);
		setIsProcessing(false);
		toast.success('Товари успішно знято з резерву');
		closeModal();
	};

	const showModal = !!withdrawReservationItem && !!selectedRow;

	return (
		<div data-availability-checker className={clsx(styles.wrapper, className)}>
			<div className={styles.tableWrapper}>
				<InStockTable
					title="Наявність на складах"
					data={data}
					columns={columns}
					onRowClick={handleRowClick}
					getActiveRow={(row) => !!row?.isCurrent}
					// {...(selectedRow && { getActiveRow: (row) => row.id === selectedRow.id })}
				/>
			</div>

			{!!selectedRow && (
				<ProductReservationCard
					key={selectedRow?.id}
					orders={[]}
					warehouse={selectedRow.warehouse}
					onClose={resetSelection}
					onWithdrawReservation={handleReservationWithdraw}
				/>
			)}

			{showModal && (
				<WithdrawReservationModal
					key={withdrawReservationItem.id}
					onSubmit={handleReservationWithdrawal}
					onClose={closeModal}
					inReservation={withdrawReservationItem.quantity}
					warehouse={selectedRow.warehouse}
					disable={isProcessing}
				/>
			)}

			{isProcessing && <Spinner />}
		</div>
	);
};
export default AvailabilityChecker;
