import clsx from 'clsx';
import { breakPoints } from 'const';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Cell from '../BasicElements/Cell';
import type { CellProps } from '../BasicElements/Cell/types';
import styles from './styles.module.css';

type HeaderCellProps = Omit<CellProps, 'as'> & { hidden?: boolean; accessor?: string };
type RegularCellProps = Omit<CellProps, 'as'>;

export const HeaderCell = ({ children, className, hidden = false, accessor, ...restProps }: HeaderCellProps) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const as = isMobile ? 'span' : 'th';

	return (
		<Cell data-id as={as} data-hidden={hidden} data-accessor-id={accessor} className={clsx(styles.spacingHeaderCell, className)} {...restProps}>
			{children}
		</Cell>
	);
};

export const RegularCell = ({ children, className, onClick, ...restProps }: RegularCellProps) => {
	const as = 'span';

	return (
		<Cell as={as} className={clsx(styles.spacingRegularCell, className)} onClick={onClick} {...restProps}>
			{children}
		</Cell>
	);
};
