import clsx from 'clsx';
import Accordion from 'components/Accordion';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import CreatePriceTopBar from 'components/CreatePriceTopBar';
import { breakPoints, ROUTES_URLS } from 'const';
import React from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate } from 'react-router';
import { prepareUrl } from 'utils/shared';

import styles from '../styles.module.css';
import { mockDataPrice } from './mockPrice';
import { IProps } from './types';

const SavePricePage: React.FC<IProps> = ({ breadcrumbs, searchOnClick }) => {
	const navigate = useNavigate();

	const crumbs: Breadcrumb[] = breadcrumbs ?? [
		{ label: 'Прайси', href: prepareUrl(ROUTES_URLS.PRICES) },
		{ label: 'Прайс-лист №', href: '' },
	];

	const handleBackToCatalog = () => {
		navigate(prepareUrl(ROUTES_URLS.CREATE_PRICE));
	};

	const handleClickCreate = () => {
		navigate(prepareUrl(ROUTES_URLS.PRICES));
	};

	return (
		<div className={clsx('main-wrapper', styles.mainWrapper)}>
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} backLink={prepareUrl(ROUTES_URLS.CREATE_PRICE)} />
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<CreatePriceTopBar
						searchOnClick={searchOnClick}
						tableMode={true}
						handleBack={handleBackToCatalog}
						nameOfMainBtn={'Повернутись до каталогу'}
					/>
				</MediaQuery>
				<Accordion items={mockDataPrice} level={0} />
				<Button text="Створити прайс" variant="rounded" background="var(--primary-600)" className={styles.btn} onClick={handleClickCreate} />
			</div>
		</div>
	);
};

export default SavePricePage;
