import React, { Children, ComponentProps, MouseEvent, useEffect, useRef, useState } from 'react';

interface IProps extends ComponentProps<'tr'> {
	isExpanded: boolean;
}

const MobileReservedProductTableTr: React.FC<IProps> = ({ children, ...restProps }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [firstChild] = Children.toArray(children);
	const trRef = useRef<HTMLTableRowElement>(null);

	const toggleIsExpanded = (event: MouseEvent<HTMLTableRowElement>) => {
		event.stopPropagation();

		if (event.target !== event.currentTarget) {
		}

		setIsExpanded((prevState) => !prevState);
	};

	useEffect(() => {
		if (trRef.current) {
			trRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [isExpanded]);

	return (
		<tr ref={trRef} onClick={toggleIsExpanded} data-tr-expanded={isExpanded} {...restProps}>
			{isExpanded ? children : firstChild}
		</tr>
	);
};

export default MobileReservedProductTableTr;
