import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { IAuthResponse } from 'models/IAuthResponse';

import { loginRequest } from './actions';
import { IState } from './typed';

const initialState = {
	isAuth: false,
	userEmail: '',
	loading: false,
	errorLogin: '',
} as IState;

export const authSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		isAuthUpdate: (state, action: PayloadAction<boolean>) => {
			state.isAuth = action.payload;
		},
	},
	extraReducers: {
		[loginRequest.fulfilled.type]: (state, action: PayloadAction<IAuthResponse>) => {
			// @ts-ignore
			localForage.setItem('token', action.payload.res.access_token);

			state.isAuth = true;
			// state.userEmail = action.payload.email;
		},
		[loginRequest.pending.type]: (state) => {
			state.loading = true;
			state.errorLogin = '';
		},
		[loginRequest.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.errorLogin = action.payload;
		},
	},
});

export default authSlice.reducer;
export const { isAuthUpdate } = authSlice.actions;
