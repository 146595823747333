/* eslint-disable react/prop-types */
import React, { ChangeEvent } from 'react';
import { isNumber } from 'utils/type-guards';

import CheckboxColumn from './TableComponents/CheckboxColumn';
import TableCheckbox from './TableComponents/CheckboxColumn/TableCheckbox';
import { HeaderCellContent } from './TableComponents/ContentOrientedCells';
import { HeaderCell } from './TableComponents/SpacingOrientedCells';
import type { IProps } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const insertSelectableColumn = (
	tableColumns: any,
	{
		selectable,
		insertSelectableAt,
		pageType,
		useSyncSelectionTracker,
		overrideToggleAllRowSelectedProps,
	}: {
		selectable?: boolean;
		insertSelectableAt?: number;
		pageType?: string;
		useSyncSelectionTracker?: IProps<any>['useSyncSelectionTracker'];
		overrideToggleAllRowSelectedProps: IProps<any>['overrideToggleAllRowSelectedProps'];
		pinColumns?: string[];
	},
) => {
	if (!selectable) {
		return tableColumns;
	}

	const selectableColumn = {
		id: 'selection',
		Header: ({ getToggleAllRowsSelectedProps, data, selectedFlatRows, rows }) => {
			const { activeSelectionsCount, selectAll, unselectAll } = useSyncSelectionTracker?.({ allData: data, selectedFlatRows }) ?? {};
			const shouldRender = !activeSelectionsCount;

			const originalProps = getToggleAllRowsSelectedProps();
			const { onChange, ...checkboxProps } = overrideToggleAllRowSelectedProps?.({ ...originalProps, rows }) || originalProps;

			const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
				const { checked } = event.target;

				onChange(event);

				if (checked) {
					selectAll?.();
				} else {
					unselectAll?.();
				}
			};

			return (
				<HeaderCell display="flex" {...(!shouldRender && { width: '68px' })}>
					<HeaderCellContent>
						{shouldRender && (
							<TableCheckbox
								{...checkboxProps}
								id="selection"
								onChange={handleCheckboxChange}
								variant={pageType === 'order' ? 'square' : 'rounded'}
							/>
						)}
					</HeaderCellContent>
				</HeaderCell>
			);
		},
		accessor: 'selection',
		width: 30,
		// eslint-disable-next-line react/prop-types
		Cell: ({ row, data }) => {
			const { toggle } = useSyncSelectionTracker?.({ allData: data }) ?? {};

			return <CheckboxColumn row={row} onToggle={toggle} variant={pageType === 'order' ? 'square' : 'rounded'} />;
		},
		mobile: false,
	};

	const shouldInsertAtStartPosition = !isNumber(insertSelectableAt) || (isNumber(insertSelectableAt) && insertSelectableAt === 0);

	if (shouldInsertAtStartPosition) {
		return [selectableColumn, ...tableColumns];
	}

	const columnsBeforeInsertIndex = tableColumns.slice(0, insertSelectableAt);
	const columnsAfterInsertIndex = tableColumns.slice(insertSelectableAt);
	const patchedTableColumns = [...columnsBeforeInsertIndex, selectableColumn, ...columnsAfterInsertIndex];

	return patchedTableColumns;
};
