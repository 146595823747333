import clsx from 'clsx';
import Counter from 'components/Counter';
import React, { MouseEvent } from 'react';
import { formatNumberToUAH, numberify } from 'utils/shared';

import styles from './styles.module.css';
import { ItemsProps } from './types';

export const Item: React.FC<ItemsProps> = ({
	onClick,
	onDelete,
	onQuantityChange,
	initialValue: counter = 1,
	brand,
	title,
	price,
	measureUnit,
	withDeleteButton = false,
}) => {
	const totalPrice = formatNumberToUAH(numberify(price) * numberify(counter));

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		onClick?.();
	};
	const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		onDelete?.();
	};

	return (
		<div className={styles.productItem}>
			<div className={styles.textContentWrapper} onClick={handleClick}>
				<p className={clsx(styles.brand, 'line-clamp text-md-medium')}>{brand}</p>
				<p className={clsx(styles.product, 'line-clamp')}>{title}</p>
				<strong className={styles.price}>{totalPrice}</strong>

				<button type="button" className={clsx(styles.unitButton, styles.active)}>
					{measureUnit?.label}
				</button>
			</div>

			<Counter withDeleteButton={withDeleteButton} onDelete={handleDelete} onQuantityChange={onQuantityChange} value={counter} />
		</div>
	);
};

export default Item;
