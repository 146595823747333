import { createContext, useContext } from 'react';
import { useTable } from 'react-table';

export const TableContext = createContext<ReturnType<typeof useTable>>(null);

export const useTableContext = () => {
	const ctx = useContext(TableContext);

	if (!ctx) {
		throw new Error('useTableContext must be used inside table context provider');
	}

	return ctx;
};
