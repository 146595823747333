import clsx from 'clsx';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';

const ToggleOrderType: React.FC<{ className?: string }> = ({ className }) => {
	const { utils, draftOrder } = useOrderViewer();

	const isRegularOrder = utils.isDraftOrderOfRegularType();
	const isChecked = !isRegularOrder;

	return (
		<Checkbox
			checked={isChecked}
			onClick={(e) => e.stopPropagation()}
			onChange={draftOrder.toggleOrderTypeSafe}
			label="Заявка на тонування"
			className={clsx(className)}
		/>
	);
};

export default ToggleOrderType;
