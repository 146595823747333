import { useOrder } from 'hooks/orders';
import { useAppDispatch } from 'hooks/redux';
import { IOrder } from 'models/IOrder';
import { useOrderContextValue } from 'pages/Order/useOrderContextValue';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { orderPreviewActions } from 'store/reducers/orderViewer';

import { OrderProvider } from './OrderProvider';

const OrderViewerProvider: React.FC = () => {
	const dispatch = useAppDispatch();
	const { order } = useOrder();
	const ctx = useOrderContextValue();

	useEffect(() => {
		if (order) {
			dispatch(orderPreviewActions.syncWithUseGetOrderQuery(order as IOrder));
		}

		return () => {
			dispatch(orderPreviewActions.clearOrderViewer());
		};
	}, [order]);

	return (
		<OrderProvider value={ctx}>
			<Outlet />
		</OrderProvider>
	);
};

export default OrderViewerProvider;
