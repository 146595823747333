import { SelectOption } from 'components/Select/types';
import { useAppSelector } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { selectSplitOrderMetadata } from 'store/reducers/orderViewer/selectors';

export const useOrderMetaDataForm = () => {
	const metaData = useAppSelector(selectSplitOrderMetadata);

	const form = useForm<Record<string, SelectOption | SelectOption[]>>({ defaultValues: metaData });

	return form;
};
