import Spinner from 'components/Spinner';
import { ROUTES_URLS } from 'const';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { lazy, Suspense, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Code from 'static/images/code.svg';
import Dataflow from 'static/images/dataflow.svg';
import { prepareUrl } from 'utils/shared';

import CustomButton from './CustomButton';
import styles from './style.module.css';

const Drawer = lazy(() => import('components/Drawer'));
const StructureDrawerComponent = lazy(() => import('../StructureDrawerComponent/Index'));

const OrderActionButtons = () => {
	const { id } = useParams();
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	const { draftOrder, utils } = useOrderViewer();
	const isSplittingAvailable = utils.isSplittingAvailable();

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const goToOrderSplitPage = () => {
		draftOrder.syncDraftOrderWithRootSuborder();

		const url = prepareUrl(ROUTES_URLS.ORDER_SPLIT, { id });

		navigate(url);
	};

	return (
		<div data-order-action-buttons className={styles.buttonsWrapper}>
			{isSplittingAvailable && (
				<CustomButton
					title="Розбити"
					onClick={goToOrderSplitPage}
					icon={Code}
					bgColor="var(--primary-50)"
					color="var(--primary-700)"
					hoverBg="var(--primary-100)"
				/>
			)}
			<CustomButton
				title="Структура"
				icon={Dataflow}
				bgColor="var(--primary-50)"
				color="var(--primary-700)"
				hoverBg="var(--primary-100)"
				onClick={handleOpen}
			/>

			<Suspense fallback={<Spinner />}>
				{isOpen && (
					<Drawer open={isOpen} title={'Структура'} onClose={handleClose}>
						<StructureDrawerComponent />
					</Drawer>
				)}
			</Suspense>
		</div>
	);
};

export default OrderActionButtons;
