import AutocompleteFilters from 'components/OrderCreatePageComponents/AutocompleteFilters';
import TopBarContainer from 'components/OrderCreatePageComponents/TopBarContainer';
import React from 'react';

const MetaData: React.FC = () => {
	return (
		<TopBarContainer>
			<AutocompleteFilters />
		</TopBarContainer>
	);
};

export default MetaData;
