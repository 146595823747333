import type { SelectOption } from 'components/Select/types';
import { ORDER_NEWLY_CREATED_STATUS, ORDER_RESERVATION_STATUS } from 'const';
import type { IOrder } from 'models/IOrder';
import type { IPaintToningService, IRegularService, IService } from 'models/IServices';
import { calculateTotalPrice, calculateTotalVolume, calculateTotalWeight, numberify, toArray, toPrecise, uuid } from 'utils/shared';
import { isObject, isString } from 'utils/type-guards';

import type { Product } from '../orders/types';
import type { Locker, ServiceOption, Services, Suborder } from './types';

type CreateBlankSuborderParams = {
	orderId: string;
	number: number;
	warehouse: SelectOption;
	type?: Suborder['type'];
	index: number;
};

export const createBlankSuborder = ({ orderId, number, warehouse, type = 'regular', index }: CreateBlankSuborderParams): Suborder => {
	return {
		tempId: uuid(),
		products: [],
		services: {},
		orderId,
		selectedProducts: [],
		selectedServices: [],
		number,
		createdAt: new Date(),
		warehouse,
		type,
		status: ORDER_NEWLY_CREATED_STATUS,
		meta: {
			tabTitle: createTabTitleByType(type, index),
			isSaved: false,
			index,
		},
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patchProduct = (arr: Record<string, any>[], id: string, properties: Record<string, any>) => {
	const patchedProducts = arr.map((product) => {
		if (product.id !== id) {
			return product;
		}

		const entries = Object.entries(properties ?? {});

		const newProduct = entries.reduce(
			(targetProduct, [key, value]) => {
				let totalPrice: number | string = numberify(product.amount);

				if (key === 'amount') {
					const pickedAmount = numberify(product['pickedAmount']);
					const price = numberify(product['price']);

					totalPrice = toPrecise(pickedAmount + price, 2);

					targetProduct[key] = numberify(totalPrice);

					return targetProduct;
				}

				targetProduct[key] = numberify(value as string);

				return targetProduct;
			},
			{ ...product },
		);

		return newProduct;
	});

	return patchedProducts;
};

export const calculateServicesFee = (services?: Services) => {
	const servicesArr = Object.values(services ?? {});

	if (servicesArr.length === 0) return 0;

	const servicesTotalSum = servicesArr.reduce((total, service) => {
		const price = isString(service.price) ? parseFloat(service.price || '0') : service.price;
		const servicePrice = price * service.quantity;

		return (total += servicePrice);
	}, 0);

	return servicesTotalSum;
};

export const recalculateOrderStats = (products: Product[], services?: Services) => {
	if (!products || (products && products.length === 0)) {
		return { totalPrice: 0, totalWeight: 0, totalVolume: 0 };
	}
	const totalServicesCost = calculateServicesFee(services);

	let totalPrice = 0;
	let totalWeight = 0;
	let totalVolume = 0;

	products?.forEach((product) => {
		totalPrice += calculateTotalPrice(product);
		totalWeight += calculateTotalWeight(product);
		totalVolume += calculateTotalVolume(product);
	});

	const totalPriceWithServices = totalPrice + totalServicesCost;

	return {
		totalPrice: totalPriceWithServices,
		totalWeight,
		totalVolume,
	};
};

export const prepareBlankService = (rawService: ServiceOption): IService => {
	if (rawService.type === 'regular') {
		const service: IRegularService = {
			id: uuid(),
			serviceId: rawService.value,
			service: rawService.label,
			price: '100.00',
			quantity: 1,
			type: '',
			workType: '',
			serviceType: rawService.type,
		};

		return service;
	}

	const service: IPaintToningService = {
		id: uuid(),
		serviceId: rawService.value,
		service: rawService.label,
		price: '2500.00',
		quantity: 1,
		serviceType: rawService.type,
		colorCode: '',
		collection: '',
		baseTone: '',
		tare: '10л',
	};

	return service;
};

// ---- hydration helpers ---- //
export const createRootSuborder = (order: IOrder) => {
	const rootSuborder = {
		orderId: null,
		number: order?.number,
		products: order?.products ?? [],
		services:
			order.services?.reduce((acc, service) => {
				// !TEMP id assignment
				const id = service.id || uuid();

				return { ...acc, [id]: { ...service, id } };
			}, {}) ?? {},
		selectedProducts: [],
		tempId: uuid(),
	} as Suborder;

	return rootSuborder;
};

export const checkIfHasSubordersSavedOnServer = (order: IOrder) => {
	const hasSuborders = !!order?.suborders && order.suborders.length > 0 && order.suborders[0].orderId !== null;

	return hasSuborders;
};
export const checkIfHasServicesSavedOnServer = (order: IOrder) => {
	const hasServices = !!order?.services && Array.isArray(order.services) && order?.services.length > 0;

	return hasServices;
};
export const checkIfHasInMemoryServices = (services: Services) => {
	const hasServices = Object.values(services ?? {}).filter((service) => service.isSaved === false).length > 0;

	return hasServices;
};

export const getRootOrderId = (order: IOrder) => {
	return String(order?.id ?? order?.number);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHydrateOrderStateData = (order: IOrder, orderInState: any, stateServices: Services) => {
	const orderId = getRootOrderId(order);

	const hasServerSavedSuborders = checkIfHasSubordersSavedOnServer(order);
	const rootSuborder = Object.keys(orderInState ?? {})?.length > 0 ? orderInState : createRootSuborder(order);

	let suborders: Suborder[];
	let subordersCount = 0;
	let hasPaintToningSuborder = false;
	let paintToningSubordersCount = 0;

	const locker: Locker = {};

	if (hasServerSavedSuborders) {
		suborders = [
			rootSuborder,
			...order.suborders.map((suborder, idx) => {
				if (checkIfSuborderHasPaintToningType(suborder) && !hasPaintToningSuborder) {
					hasPaintToningSuborder = true;
					paintToningSubordersCount++;
				}
				// hydrate locker
				locker[idx + 1] = [...(suborder?.products ?? []), ...(suborder?.services ?? [])].map((item) => String(item.id));

				return {
					...suborder,
					services:
						suborder.services?.reduce((acc, service) => {
							// !TEMP id assignment
							const id = service.id || uuid();

							return { ...acc, [id]: { ...service, id }, isSaved: true };
						}, {}) ?? {},
					meta: {
						tabTitle: createTabTitleByType(suborder.type, idx + 1),
						isSaved: true,
					},
				};
			}),
		];
		subordersCount = suborders.length;
	} else {
		const rootSuborderCopy = { ...rootSuborder };

		if ('suborders' in rootSuborderCopy) {
			delete rootSuborderCopy.suborders;
		}

		suborders = [rootSuborderCopy];
	}

	const hasSavedServices = checkIfHasServicesSavedOnServer(order);
	const hasInMemoryAddedServices = checkIfHasInMemoryServices(stateServices);
	const hasServices = hasInMemoryAddedServices || hasSavedServices;

	const stateServicesCopy = { ...stateServices };

	let services: Services;

	if (hasServices) {
		const serializedServices = order.services?.reduce(
			(newServices, service) => ({ ...newServices, [service.id]: { ...service, isSaved: true } }),
			{} as Services,
		);

		if (hasInMemoryAddedServices) {
			const inMemoryServices = Object.entries(stateServicesCopy ?? {});

			inMemoryServices?.forEach(([serviceId, service]) => {
				if (service?.isSaved === undefined || service.isSaved === true) {
					delete stateServicesCopy[serviceId];
				} else {
					stateServicesCopy[serviceId].isSaved = false;
				}
			});
		}

		services = hasInMemoryAddedServices ? { ...stateServicesCopy, ...serializedServices } : serializedServices;
	} else {
		services = {};
	}

	const { totalPrice, totalWeight, totalVolume } = recalculateOrderStats(rootSuborder?.products ?? [], services);

	const draftOrder = {
		...order,
		services,
		suborders,
	};

	return {
		draftOrder,
		orderId,
		services,
		suborders,
		subordersCount,
		totalPrice,
		totalWeight,
		totalVolume,
		hasPaintToningSuborder,
		locker,
		paintToningSubordersCount,
	};
};

function createTabTitleByType(type: string, index: number) {
	return type === 'paint_toning' ? `Заявка на тонув. ${index}` : `Заявка ${index}`;
}

export const syncRootSuborderWithRecentUpdate = ({ rootSuborder, modifiedService }: { rootSuborder: Suborder; modifiedService: IService }) => {
	const rootSuborderServices = rootSuborder.services;

	if (!rootSuborderServices) {
		rootSuborder.services = {};
	}

	if (modifiedService?.id) {
		rootSuborder.services[modifiedService.id] = modifiedService;
	}
};
export const syncRootSuborderWithRecentDelete = ({ rootSuborder, serviceId }: { rootSuborder: Suborder; serviceId: string }) => {
	const rootSuborderServices = rootSuborder.services;
	const service = rootSuborderServices[serviceId];

	if (service) {
		delete rootSuborderServices[serviceId];
	}
};

function checkIfSuborderHasPaintToningType(suborder: Suborder) {
	return suborder?.type === 'paint_toning';
}

type AssignLockerFnParams = { locker: Locker; items: Product[] | IService[] | Services; holder: string };

export function assignLockedItems({ locker, items, holder }: AssignLockerFnParams) {
	if (Array.isArray(items)) {
		locker[holder] = items.map((item) => String(item.id));
	} else {
		locker[holder] = Object.values(items ?? {}).map((item) => item.id);
	}
}

type UnassignLockerFnParams =
	| { locker: Locker; holder: string; cascade: true }
	| { locker: Locker; holder: string; cascade?: false; items: Product[] | IService[] };

export function unassignLockedItems({ locker, holder, cascade, ...restParams }: UnassignLockerFnParams) {
	if (cascade && locker[holder]) {
		delete locker[holder];
	} else {
		const vault = locker[holder];

		if (!vault) return;

		const { items } = restParams as { items: Product[] | IService[] };

		const ids = new Set(items.map((item) => String(item.id)));
		locker[holder] = vault.filter((id) => !ids.has(id));
	}
}

export const groupServicesByType = (services: Services | IService[]): { regularServices: IService[]; paintToningServices: IService[] } => {
	const serviceList = Array.isArray(services) ? services : toArray(services);

	return serviceList.reduce(
		(acc, service) => {
			const key = service.serviceType === 'regular' ? 'regularServices' : 'paintToningServices';
			return { ...acc, [key]: [...acc[key], service] };
		},
		{ regularServices: [], paintToningServices: [] },
	);
};

export function groupProductsByType(products: Product[]): { paintToning: Product[]; regular: Product[] } {
	return products.reduce(
		(acc, product) => {
			const key = product.title.toLowerCase().includes('фарба') ? 'paintToning' : 'regular';
			return { ...acc, [key]: [...acc[key], product] };
		},
		{ paintToning: [], regular: [] },
	);
}

export function areValidPaintToningProducts(products: Product[]) {
	const groups = groupProductsByType(products);

	if (groups.regular.length === 0) {
		return {
			success: true,
		};
	} else {
		return {
			success: false,
			failed: groups.regular,
		};
	}
}
export function areValidRegularProducts(products: Product[]) {
	const groups = groupProductsByType(products);

	if (groups.paintToning.length === 0) {
		return {
			success: true,
		};
	} else {
		return {
			success: false,
			failed: groups.paintToning,
		};
	}
}

const selectValidSuborder = (suborder: Suborder) => {
	return (
		(Array.isArray(suborder.products) && suborder.products.length > 0) ||
		(isObject<Services>(suborder.services) && toArray(suborder.services).length > 0)
	);
};
const computeSuborderNumber = (rootOrderNumber: number, index: number) => {
	return rootOrderNumber + index + 1;
};

export const prepareSubordersSaveDTO = (suborders: Suborder[], etalonOrder: IOrder) => {
	const [rootOrder, ...subordersWithoutRoot] = suborders;
	const pivotNumber = rootOrder.number;

	const serializedSuborders = subordersWithoutRoot.filter(selectValidSuborder).map((suborder, index) => {
		const { products, services: servicesObj, warehouse, createdAt = new Date(), orderId: rootOrderId, type, status } = suborder;

		const number = computeSuborderNumber(pivotNumber, index);
		const services = toArray(servicesObj);

		return {
			products,
			createdAt,
			services,
			number,
			rootOrderId,
			type,
			warehouse,
			status,
			id: uuid(),
		};
	});

	const { products, services } = rootOrder;
	const dataToBeSent = { products, suborders: serializedSuborders, services: toArray(services) };

	const dto = { ...etalonOrder, ...dataToBeSent };

	return dto;
};

export const assignReservationStatus = <TData>(obj: TData) => {
	const assigned = { ...obj, status: ORDER_RESERVATION_STATUS };

	return assigned;
};

export const unassignReservationStatus = <TData>(obj: TData) => {
	const assigned = { ...obj, status: ORDER_NEWLY_CREATED_STATUS };

	return assigned;
};

export const isSuborderEmpty = (suborder?: Suborder) => {
	if (!suborder) return true;

	const isEmpty = !suborder.products?.length && !toArray(suborder.services).length;

	return isEmpty;
};
