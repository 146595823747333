import { ProductFilterNode } from './types';

export const findFilterItem = (
	filters: ProductFilterNode[] | undefined,
	filtersInSearchParams: [string, string][],
	level = 0,
	prevResult: { level: number; result: ProductFilterNode[] } = { level: 0, result: [] },
): { level: number; result: ProductFilterNode[] } => {
	if (!filters) {
		return prevResult;
	}

	const matchingFilter = filters.find(({ id }) => filtersInSearchParams.some(([, filter]) => filter === id));

	const updatedResult = matchingFilter ? { level, result: [...prevResult.result, matchingFilter] } : prevResult;

	return findFilterItem(matchingFilter?.children, filtersInSearchParams, level + 1, updatedResult);
};

export function findRootNodeById(tree: ProductFilterNode, nodeId: string): ProductFilterNode | null {
	function traverse(node: ProductFilterNode | undefined): ProductFilterNode | null {
		if (!node) {
			return null;
		}

		if (node.id === nodeId) {
			return node;
		}

		if (node.children && node.children.length > 0) {
			for (const child of node.children) {
				const result = traverse(child);
				if (result) {
					return result;
				}
			}
		}

		return null;
	}

	return traverse(tree);
}

export function serializeProductFilters(data: ProductFilterNode) {
	function traverse(node: ProductFilterNode | undefined, rootId: string | null): ProductFilterNode | undefined {
		if (!node) {
			return undefined;
		}

		const updatedNode: ProductFilterNode = { ...node, root: rootId };

		if (node.children && node.children.length > 0) {
			const updatedChildren = node.children.map((child) => traverse(child, node.id));
			updatedNode.children = updatedChildren;
		}

		return updatedNode;
	}

	return traverse(data, null);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractBrandsAndParentIds = (data: any): Record<string, { brand: string; categoryFilters: string[][] }> => {
	const brandsMap: Record<string, { brand: string; categoryFilters: string[][] }> = {};

	data.forEach((item) => {
		const { brand, parentIds } = item;

		if (!brandsMap[brand]) {
			brandsMap[brand] = { brand: brand, categoryFilters: [] };
		}

		brandsMap[brand].categoryFilters.push(parentIds);
	});

	return brandsMap;
};

export const addBrandsToRootNodes = (nodes: ProductFilterNode[], brandsData: ProductFilterNode[], d: ProductFilterNode[]): ProductFilterNode[] => {
	const brandsMap = extractBrandsAndParentIds(d);

	const addBrandsRecursively = (node: ProductFilterNode): ProductFilterNode => {
		const brands: string[] = [];

		Object.values(brandsMap).forEach(({ categoryFilters, brand }) => {
			if (categoryFilters.some((f) => f?.includes(node.id))) {
				brands.push(brand);
			}
		});

		const updatedNode = { ...node, brands };

		if (node.children) {
			updatedNode.children = node.children.map(addBrandsRecursively);
		}

		return updatedNode;
	};

	return nodes.map(addBrandsRecursively);
};
