import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';

const ToggleOrderTypeGate: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { booleans } = useOrderViewer();

	if (!booleans.canToggleDraftOrderType) return null;

	return children;
};

export default ToggleOrderTypeGate;
