/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { Fragment } from 'react';

import styles from './styles.module.css';

export interface TableHeaderProps {
	headerGroups: any[];
}

const TableHeader: React.FC<TableHeaderProps> = ({ headerGroups }) => {
	return (
		<thead>
			{headerGroups.map((headerGroup, index) => (
				<tr
					key={index}
					{...headerGroup.getHeaderGroupProps()}
					data-thead-table-row
					className={clsx(styles.tableHeaderCell, 'text-sx-medium')}
				>
					{headerGroup.headers.map((column) => {
						if (column.hidden || column.mobile) return null;

						return <Fragment key={column.id}>{column.render('Header')}</Fragment>;
					})}
				</tr>
			))}
		</thead>
	);
};

export default TableHeader;
