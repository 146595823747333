import clsx from 'clsx';
import Button from 'components/Button';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import PriceTypes from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes';
import OrderActionButtons from 'components/OrderPageComponents/OrderActionButtons';
import SettingButton from 'components/Table/Filters/SettingsButton';
import { breakPoints } from 'const';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';
import MediaQuery from 'react-responsive';
import DollarIcon from 'static/images/dollar.svg';
import FullScreen from 'static/images/expand.svg';
import Trash from 'static/images/trashOrders.svg';

import styles from './styles.module.css';

const ControlPanel: React.FC = () => {
	const { draftOrder, utils } = useOrderViewer();
	const isOnServicePage = utils.isOnServicesSubPage();

	const {
		toggleFullScreen,
		toggleIsAdjustmentOpen,
		closeAdjustment,
		togglePriceColumns,
		toggleChangePriceMode,
		adjustColumns,
		productColumns,
		isAdjustmentOpen,
		selectedItems,
		isChangePriceMode,
	} = useOrderContext();

	const toggleAdjustmentModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		toggleIsAdjustmentOpen();
	};

	const handleItemsDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (selectedItems.length === 0) return;

		draftOrder.deleteItemsSafe(selectedItems);
	};

	const hasItemsToDelete = selectedItems.length > 0;
	const isDeleteButtonDisabled = isChangePriceMode || !hasItemsToDelete;
	const isPriceModeButtonDisabled = !hasItemsToDelete;
	const shouldDisablePriceTypes = isOnServicePage;

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<OrderActionButtons />

				<div className={styles.configButtonsWrapper}>
					<Button
						onClick={handleItemsDelete}
						icon={Trash}
						variant="bordered"
						className={clsx({ [styles.disabled]: isDeleteButtonDisabled })}
						disabled={isDeleteButtonDisabled}
					/>
					<Button
						icon={DollarIcon}
						variant="bordered"
						onClick={toggleChangePriceMode}
						className={clsx({ [styles.disabled]: isPriceModeButtonDisabled })}
						disabled={isPriceModeButtonDisabled}
					/>

					<SettingButton onClick={toggleAdjustmentModal}>
						<ColumnsSettingsMenu open={isAdjustmentOpen} onClose={closeAdjustment} columns={productColumns} setColumns={adjustColumns} />
					</SettingButton>

					<Button onClick={toggleFullScreen} icon={FullScreen} variant="bordered" />
				</div>

				<PriceTypes
					justify="flex-end"
					onClick={togglePriceColumns}
					disableAll={shouldDisablePriceTypes}
					className={styles.priceTypesWrapper}
				/>
			</MediaQuery>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<PriceTypes onClick={togglePriceColumns} justify="flex-start" />
			</MediaQuery>
		</>
	);
};

export default ControlPanel;
