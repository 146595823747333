import clsx from 'clsx';
import { usePaintToningServicesColumns, useRegularServicesColumns } from 'columns/services';
import CalculateColorant from 'components/OrderPageComponents/CalculateColorant';
import ServiceEmptyState from 'components/OrderServicesPageComponents/ServiceEmptyState';
import ServiceSelectors from 'components/OrderServicesPageComponents/ServiceSelectors';
import Table from 'components/Table';
import ServicesMobileTable from 'components/Table/MobileViews/ServicesMobileTable';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { breakPoints } from 'const';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrderViewer } from 'hooks/useOrderViewer';
import ActionButton from 'layouts/PageLayout/ActionButton';
import React, { MouseEvent, useState, useTransition } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import PlusIcon from 'static/images/plus.svg';

import styles from './styles.module.css';

const Services = () => {
	const regularServicesColumns = useRegularServicesColumns();
	const paintToningServicesColumns = usePaintToningServicesColumns();
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const [isServiceListOpened, setIsServiceListOpened] = useState(false);
	const [, startTransition] = useTransition();
	const { toggleSelectedItems } = useOrderContext();

	const {
		utils,
		booleans,
		suborders: { handlers },
		activeSuborder: { regularServices, paintToningServices },
	} = useOrderViewer();

	const isPaintToningSuborder = booleans.isActiveSuborderOfPaintToningType;

	const hasRegularServices = regularServices.length > 0;
	const hasPaintToningServices = paintToningServices.length > 0;
	const hasAddedServices = hasRegularServices || hasPaintToningServices;

	const handleServiceListClose = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		startTransition(() => setIsServiceListOpened(false));
	};
	const handleServiceListOpen = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsServiceListOpened(true);
	};

	const shouldOpenServiceSelectors = isMobile && isServiceListOpened;

	return (
		<div className={styles.wrapper}>
			{shouldOpenServiceSelectors && (
				<ServiceSelectors
					onAddOrder={handlers.addService}
					appearance="popup"
					onClose={handleServiceListClose}
					onClick={handleServiceListClose}
				/>
			)}
			{!isMobile && <ServiceSelectors onAddOrder={handlers.addService} appearance="button-list" />}

			{!hasAddedServices && <ServiceEmptyState />}

			{hasAddedServices && hasRegularServices && (
				<Table
					selectable
					pageType="order"
					columns={regularServicesColumns}
					allData={regularServices}
					mobileViewComponent={ServicesMobileTable}
					defineRowDisabledForSelection={utils.assignIsDisabledLabel}
					overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
					onChangeSelection={toggleSelectedItems}
					className={clsx(styles.servicesTable, styles.regularServices)}
					// preselectedRowIds={preselectedIds}
					mobileHeader={({ headerGroups }) => (
						<MobileHeader
							gridCustomLayout="44px auto"
							headerGroups={headerGroups}
							renderHeaders={['selection', 'service']}
							justify="flex-start"
						/>
					)}
				/>
			)}
			{hasAddedServices && hasPaintToningServices && (
				<>
					<Table
						selectable={false}
						pageType="order"
						columns={paintToningServicesColumns}
						allData={paintToningServices}
						mobileViewComponent={ServicesMobileTable}
						onChangeSelection={toggleSelectedItems}
						className={clsx(styles.servicesTable, styles.paintToningServices)}
						defineRowDisabledForSelection={utils.assignIsDisabledLabel}
						overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
						mobileHeader={({ headerGroups }) => (
							<MobileHeader
								gridCustomLayout="44px auto"
								headerGroups={headerGroups}
								renderHeaders={['selection', 'service']}
								justify="flex-start"
							/>
						)}
					/>

					{isPaintToningSuborder && <CalculateColorant />}
				</>
			)}

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<ActionButton
					variant="default"
					text="Додати послугу"
					onClick={handleServiceListOpen}
					icon={PlusIcon}
					className={styles.addServiceButton}
				/>
				<div className="safe-area-bottom" />
			</MediaQuery>
		</div>
	);
};

export default Services;
