import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import type { SelectOption } from 'components/Select/types';
import { SUBORDERS_LIMIT } from 'const';
import { IOrder } from 'models/IOrder';
import { IService, IServiceType, IServiceWorkType } from 'models/IServices';
import { constructNewIdSet, numberify, toArray, toPrecise, uuid } from 'utils/shared';

import { ordersSliceApi } from '../orders/ordersSliceApi';
import { Product } from '../orders/types';
import type {
	IOrderPreviewSliceInitialState,
	ServiceOption,
	Suborder,
	SuborderTransferConfig,
	SuborderTransferConfigWithData,
	UpdateProductProperty,
} from './types';
import {
	assignLockedItems,
	createBlankSuborder,
	getHydrateOrderStateData,
	isSuborderEmpty,
	patchProduct,
	prepareBlankService,
	recalculateOrderStats,
	syncRootSuborderWithRecentDelete,
	syncRootSuborderWithRecentUpdate,
	unassignLockedItems,
} from './utils';

const initialState: IOrderPreviewSliceInitialState = {
	suborders: [],
	subordersLimit: SUBORDERS_LIMIT,
	rootOrderId: null,
	subordersCount: 0,
	activeSuborderIndex: 0,
	activeCategoryTabIndex: 0,
	totalPrice: 0,
	totalVolume: 0,
	totalWeight: 0,
	type: 'regular',
	transferProductPreview: null,
	lastForcedRerenderTimestamp: null,
	suborderDeleteCandidate: null,
	locker: {},
	hasAtLeastOnePaintToningSuborderOnServer: false,
	itemsDeleteCandidates: [],
	paintToningSubordersLimit: 3,
	paintToningSubordersCount: 0,

	// ==== refactor
	etalonOrder: null,
	draftOrder: null,
	displayDetailsOfTransferringItem: null,
};

export const orderPreviewSlice = createSlice({
	name: 'order-viewer',
	initialState,
	reducers: {
		updateProductProperty: (state, action: PayloadAction<UpdateProductProperty & { target?: 'draft' | 'suborder'; forceRerender?: boolean }>) => {
			const { id, target, forceRerender, ...properties } = action.payload;
			const suborder = state.suborders[state.activeSuborderIndex];

			if (target && target === 'suborder') {
				const rootSuborder = state.suborders[0];
				suborder.products = patchProduct(suborder.products, id, properties) as Product[];

				if (state.activeSuborderIndex !== 0) {
					rootSuborder.products = patchProduct(rootSuborder.products, id, properties) as Product[];
				}
			} else {
				const draftOrderProducts = state.draftOrder?.products ?? [];

				if (!draftOrderProducts) {
					state.draftOrder.products = [];
				}

				state.draftOrder.products = patchProduct(state.draftOrder.products, id, properties);
			}

			if (forceRerender) {
				state.lastForcedRerenderTimestamp = Date.now();
			}
		},
		createBlankSuborder: (state, action: PayloadAction<Partial<{ type: 'paint_toning' | 'regular' }>>) => {
			const { type } = action.payload ?? {};

			const indexOfPaintToningSuborder = state.suborders.findIndex((suborder) => suborder?.type === 'paint_toning');
			const lastSuborderIndex = state.suborders.length - 1;
			const lastSuborder = state.suborders[lastSuborderIndex];
			const lastOrderNumber = lastSuborder.number;
			const rootWarehouse = state.draftOrder?.client?.meta?.department;
			const subordersCount = state.suborders?.length ?? 1;
			const newSuborderIndex = indexOfPaintToningSuborder < 0 ? subordersCount : indexOfPaintToningSuborder;

			const blankSuborder = createBlankSuborder({
				orderId: state.rootOrderId,
				number: lastOrderNumber + 1,
				index: newSuborderIndex,
				warehouse: rootWarehouse,
				type,
			});

			if (type === 'paint_toning') {
				state.paintToningSubordersCount++;
			}

			if (indexOfPaintToningSuborder >= 0) {
				state.suborders.splice(newSuborderIndex, 0, blankSuborder);
			} else {
				state.suborders.push(blankSuborder);
			}

			state.suborders[state.activeSuborderIndex].selectedProducts = [];
			state.subordersCount++;
		},
		createSuborderWithProducts: (state, action: PayloadAction<{ from?: number; type?: 'regular' | 'paint_toning' }>) => {
			const { from, type } = action.payload;

			const indexOfPaintToningSuborder = state.suborders.findIndex((suborder) => suborder?.type === 'paint_toning');
			const lastSuborder = state.suborders.at(-1);
			const lastOrderNumber = lastSuborder.number;
			const products = state.suborders[from].selectedProducts;
			const rootWarehouse = state.draftOrder?.client?.meta?.department;
			const subordersCount = state.suborders?.length ?? 1;
			const newSuborderIndex = indexOfPaintToningSuborder < 0 ? subordersCount : indexOfPaintToningSuborder;

			const blankSuborder = createBlankSuborder({
				orderId: state.rootOrderId,
				number: lastOrderNumber + 1,
				index: newSuborderIndex,
				warehouse: rootWarehouse,
				type,
			});
			const suborderProducts = Array.isArray(products) ? products : [products];

			const suborderWithProducts: Suborder = {
				...blankSuborder,
				products: suborderProducts,
			};

			if (from > 0) {
				const candidates = constructNewIdSet(products);
				state.suborders[from].products = state.suborders[from].products.filter((product) => !candidates.has(String(product.id)));
			}

			if (type === 'paint_toning') {
				state.paintToningSubordersCount++;
			}

			if (indexOfPaintToningSuborder >= 0) {
				state.suborders.splice(newSuborderIndex, 0, suborderWithProducts);
			} else {
				state.suborders.push(suborderWithProducts);
			}

			assignLockedItems({ locker: state.locker, holder: suborderWithProducts.tempId, items: suborderProducts });

			state.suborders[from].selectedProducts = [];
			state.subordersCount++;
		},
		createSuborderWithServices: (state, action: PayloadAction<{ from?: number; data: IService[] }>) => {
			const { data: selectedServices } = action.payload;

			const lastSuborder = state.suborders.at(-1);
			const lastOrderNumber = lastSuborder.number;
			const rootWarehouse = state.draftOrder?.client?.meta?.department;
			const newSuborderIndex = state.suborders.length ?? 1;

			const blankSuborder = createBlankSuborder({
				orderId: state.rootOrderId,
				number: lastOrderNumber + 1,
				index: newSuborderIndex,
				warehouse: rootWarehouse,
			});

			const suborderWithServices: Suborder = {
				...blankSuborder,
				services: selectedServices.reduce((acc, service) => {
					// ! TEMP
					const id = service?.id ?? uuid();
					return { ...acc, [id]: { ...service, id } };
				}, {}),
			};

			assignLockedItems({ locker: state.locker, holder: suborderWithServices.tempId, items: suborderWithServices.services });
			state.suborders.push(suborderWithServices);
			state.subordersCount++;
		},
		removeSuborder: (state, action: PayloadAction<{ id: string; index: number }>) => {
			const { id, index } = action.payload;
			const doesActiveIndexMatchTargetIndex = index === state.activeSuborderIndex;
			const target = state.suborders[index];

			state.suborders.splice(index, 1);

			const isEmpty = isSuborderEmpty(target);

			if (!isEmpty) {
				if (target.products?.length) {
					const rootSuborder = state.suborders[0];
					const etalonOrder = state.etalonOrder;
					const etalonOrderProductsMap = new Map(etalonOrder.products.map((product) => [product.id, product]));
					const targetProductsMap = new Map(target.products.map((product) => [product.id, product]));

					rootSuborder.products = rootSuborder.products.map((product) => {
						if (targetProductsMap.has(product.id)) {
							const etalonProduct = etalonOrderProductsMap.get(product.id);

							return etalonProduct as Product;
						}

						return product;
					});
				}

				if (toArray(target.services ?? {}).length) {
					const rootSuborder = state.suborders[0];
					const etalonOrder = state.etalonOrder;
					const etalonOrderServicesMap = new Map(etalonOrder.services.map((service) => [service.id, service]));
					const targetServices = target.services;

					toArray(rootSuborder.services).forEach((service) => {
						const targetService = targetServices[service.id];

						if (targetService) {
							const etalonService = etalonOrderServicesMap.get(service.id) as IService;

							rootSuborder.services[service.id] = etalonService;
						}
					});
				}

				state.lastForcedRerenderTimestamp = Date.now();
			}

			unassignLockedItems({ locker: state.locker, holder: id, cascade: true });

			/*
			 * pushing user to prev suborder tab if target tab matches active one user is currently on
			 */
			if (doesActiveIndexMatchTargetIndex) {
				const newActiveIndex = index - 1;
				state.activeSuborderIndex = newActiveIndex < 0 ? 0 : newActiveIndex;
			}
			if (target.type === 'paint_toning') {
				state.paintToningSubordersCount += 1;
			}
		},
		setIndexOfSuborderToDelete: (state, action: PayloadAction<{ index: number }>) => {
			state.suborderDeleteCandidate = action.payload.index;
		},
		clearIndexOfSuborderToDelete: (state) => {
			state.suborderDeleteCandidate = null;
		},
		changeWarehouse: (state, action: PayloadAction<SelectOption>) => {
			const activeSuborder = state.suborders[state.activeSuborderIndex];

			activeSuborder.warehouse = action.payload;
		},
		transfer: (state, action: PayloadAction<SuborderTransferConfig | SuborderTransferConfigWithData>) => {
			const { from, to } = action.payload;

			const limit = state.subordersLimit;
			const data = new Set(state.suborders[from].selectedProducts.map((product) => product.id));

			// Case 1: Transfer from root order (0) to a suborder
			if (from === 0 && to >= 1 && to <= limit) {
				const targetSuborder = state.suborders[to];

				const existingProductIds = new Set(targetSuborder.products.map((product) => product.id));
				const productsToAdd = state.suborders[from].selectedProducts.filter((product) => !existingProductIds.has(product.id));
				const merged = [...targetSuborder.products, ...productsToAdd];
				state.suborders[to].products = merged;

				assignLockedItems({ locker: state.locker, holder: targetSuborder.tempId, items: merged });
				return;
			}

			// Case 2: Transfer from a suborder to the root order (0)
			if (from >= 1 && from <= limit && to === 0) {
				state.suborders[from].products = state.suborders[from].products.filter((product) => !data.has(product.id));
				return;
			}

			// Case 3: Transfer within suborders
			if (from >= 1 && from <= limit && to >= 1 && to <= limit) {
				const sourceSuborder = state.suborders[from];
				const targetSuborder = state.suborders[to];
				const existingProductsMap = new Map(targetSuborder?.products.map((product) => [product.id, { ...product }]));

				if ('data' in action.payload) {
					const transferredProducts = action.payload.data;
					const sourceProductsMap = new Map(sourceSuborder.products.map((product) => [product.id, product]));

					transferredProducts.forEach((transferredProduct) => {
						const etalonProduct = sourceProductsMap.get(transferredProduct.id);

						const delta = numberify(etalonProduct.pickedAmount) - numberify(transferredProduct.pickedAmount);

						/**
						 * delta > 0 means we transfer to target suborder only some PART of item
						 */
						if (delta > 0) {
							etalonProduct.pickedAmount = delta;
							targetSuborder.products.push(transferredProduct);
							state.lastForcedRerenderTimestamp = Date.now();
						} else {
							/**
							 * delta <=0 means we transfer to target suborder WHOLE or MORE
							 */
							sourceProductsMap.delete(transferredProduct.id);

							if (!existingProductsMap.has(transferredProduct.id)) {
								targetSuborder.products.push(transferredProduct);
							} else {
								const targetProduct = existingProductsMap.get(transferredProduct.id);
								targetProduct.pickedAmount = transferredProduct.pickedAmount;
							}

							/**
							 * when delta > 0 we need to update root suborder with higher amount items picked
							 */
							if (delta < 0) {
								const rootSuborder = state.suborders[0];
								const found = rootSuborder.products?.find((product) => product.id === transferredProduct.id);

								if (!found) return;

								found.pickedAmount = transferredProduct.pickedAmount;
							}
						}
					});

					sourceSuborder.products = Array.from(sourceProductsMap.values()).map((product) => product);
					sourceSuborder.selectedProducts = [];
				} else {
					const productsToAdd = sourceSuborder.selectedProducts.filter((product) => !existingProductsMap.has(product.id));
					state.suborders[to].products.push(...productsToAdd);

					state.suborders[from].products = sourceSuborder.products.filter(
						(product) => !sourceSuborder.selectedProducts.some((p) => p.id === product.id),
					);
				}
			}
		},
		transferServices: (state, action: PayloadAction<SuborderTransferConfigWithData>) => {
			const { from, to, data: selectedServices } = action.payload;

			const limit = state.subordersLimit;

			// Case 1: Transfer from root order (0) to a suborder
			if (from === 0 && to >= 1 && to <= limit) {
				const targetSuborder = state.suborders[to];

				if (!targetSuborder.services) {
					targetSuborder.services = {};
				}

				selectedServices.forEach((service) => {
					targetSuborder.services[service.id] = service;
				});

				assignLockedItems({ locker: state.locker, holder: targetSuborder.tempId, items: targetSuborder.services });
				return;
			}

			// Case 3: Transfer within suborders
			if (from >= 1 && from <= limit && to >= 1 && to <= limit) {
				const sourceSuborder = state.suborders[from];
				const targetSuborder = state.suborders[to];

				selectedServices.forEach((service) => {
					const sourceService = sourceSuborder.services[service.id];

					const delta = numberify(sourceService.quantity) - numberify(service.quantity);

					/**
					 * delta > 0 means we transfer to target suborder only some PART of item
					 */
					if (delta > 0) {
						sourceService.quantity = delta;
						targetSuborder.services[service.id] = service;
						state.lastForcedRerenderTimestamp = Date.now();
					} else {
						/**
						 * delta <=0 means we transfer to target suborder only WHOLE or MORE
						 */
						delete sourceSuborder.services[service.id];

						if (!targetSuborder.services[service.id]) {
							targetSuborder.services[service.id] = service;
						} else {
							const targetService = targetSuborder.services[service.id];
							targetService.quantity = service.quantity;
						}

						/**
						 * when delta > 0 we need to update root suborder with higher amount items picked
						 */
						if (delta < 0) {
							const rootSuborder = state.suborders[0];
							const found = rootSuborder.services[service.id];

							if (!found) return;

							found.quantity = service.quantity;
						}
					}
				});
			}
		},
		selectProductToTransfer: (state, action: PayloadAction<{ products: Product[] }>) => {
			const suborder = state.suborders[state.activeSuborderIndex];

			if (suborder) {
				suborder.selectedProducts = action.payload.products;
			}
		},
		setActiveSuborderIndex: (state, action: PayloadAction<{ index: number }>) => {
			const prevActiveIndex = state.activeSuborderIndex;
			const newActiveSuborderIndex = action.payload.index;

			state.suborders[prevActiveIndex].selectedProducts = [];
			state.suborders[prevActiveIndex].selectedServices = [];

			state.activeCategoryTabIndex = 0;
			state.activeSuborderIndex = newActiveSuborderIndex;
		},
		setActiveCategoryTabIndex: (state, action: PayloadAction<{ index: number }>) => {
			const { index } = action.payload;
			state.activeCategoryTabIndex = index;
		},
		toggleRootOrderType: (state) => {
			const isRegularOrder = state.type === 'regular';

			state.type = isRegularOrder ? 'paint_toning' : 'regular';
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setTransferPreviewProduct: (state, action: PayloadAction<{ product: any }>) => {
			const { product } = action.payload;

			state.transferProductPreview = product;
		},
		clearTransferPreviewProduct: (state) => {
			state.transferProductPreview = null;
		},
		clearOrderViewer: (state) => {
			state.draftOrder = null;
			state.suborders = [];
			state.subordersLimit = SUBORDERS_LIMIT;
			state.rootOrderId = null;
			state.subordersCount = 0;
			state.totalPrice = 0;
			state.totalVolume = 0;
			state.totalWeight = 0;
		},
		addService: (state, action: PayloadAction<{ service: ServiceOption; target?: 'root' | 'suborder' }>) => {
			const { service: rawService, target } = action.payload;

			const service = { ...prepareBlankService(rawService), isSaved: false };

			if (target && target === 'suborder') {
				const services = state.suborders[state.activeSuborderIndex].services;
				if (!services) {
					state.suborders[state.activeSuborderIndex].services = {};
				}

				state.suborders[state.activeSuborderIndex].services[service.id] = service;
				syncRootSuborderWithRecentUpdate({ rootSuborder: state.suborders[0], modifiedService: service });
			} else {
				if (!state.draftOrder.services) {
					state.draftOrder.services = {};
				}
				state.draftOrder.services[service.id] = service;
			}
		},
		removeService: (state, action: PayloadAction<{ id: IService['id']; target?: 'root' | 'suborder' }>) => {
			const { id, target } = action.payload;

			if (target && target === 'suborder') {
				delete state.suborders[state.activeSuborderIndex].services[id];
				syncRootSuborderWithRecentDelete({ rootSuborder: state.suborders[0], serviceId: id });
			} else {
				delete state.draftOrder.services[id];
			}
		},
		removeManyServices: (state, action: PayloadAction<{ services: IService[]; target?: 'root' | 'suborder' }>) => {
			const { services } = action.payload;
			const activeSuborder = state.suborders[state.activeSuborderIndex];
			const rootSuborder = state.suborders[0];

			services.forEach(({ id }) => {
				delete activeSuborder.services[id];
				syncRootSuborderWithRecentDelete({ rootSuborder, serviceId: id });
			});
		},
		updateServiceQuantity: (
			state,
			action: PayloadAction<{ id: IService['id']; quantity: number; target?: 'root' | 'suborder'; forceRerender?: boolean }>,
		) => {
			const { id, quantity, target, forceRerender } = action.payload;
			const rootSuborder = state.suborders[0];

			if (target && target === 'suborder') {
				const service = state.suborders[state.activeSuborderIndex].services[id];
				service.quantity = numberify(quantity, 1);
				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			} else {
				const service = state.draftOrder.services[id];
				state.draftOrder.services[id] = service;
				service.quantity = numberify(quantity, 1);
				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			}

			if (forceRerender) {
				state.lastForcedRerenderTimestamp = Date.now();
			}
		},
		updateServicePrice: (
			state,
			action: PayloadAction<{ id: IService['id']; price: number | string; target?: 'root' | 'suborder'; forceRerender?: boolean }>,
		) => {
			const { id, price, target, forceRerender } = action.payload;
			const rootSuborder = state.suborders[0];

			if (target && target === 'suborder') {
				const service = state.suborders[state.activeSuborderIndex].services[id];
				service.price = numberify(price);
				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			} else {
				const service = state.draftOrder.services[id];
				service.price = numberify(price);

				state.draftOrder.services[id] = service;
				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			}

			if (forceRerender) {
				state.lastForcedRerenderTimestamp = Date.now();
			}
		},
		setServiceType: (state, action: PayloadAction<{ id: string; option: IServiceType; target?: 'root' | 'suborder' }>) => {
			const { id, option, target } = action.payload;

			if (target && target === 'suborder') {
				const rootSuborder = state.suborders[0];
				const service = state.suborders[state.activeSuborderIndex].services[id];

				if (service && 'type' in service) {
					service.type = option;
					syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
				}
			} else {
				const service = state.draftOrder.services[id];
				if (service && 'type' in service) service.type = option;
			}
		},
		setServiceWorkType: (state, action: PayloadAction<{ id: string; option: IServiceWorkType; target?: 'root' | 'suborder' }>) => {
			const { id, option, target } = action.payload;
			if (target && target === 'suborder') {
				const rootSuborder = state.suborders[0];
				const service = state.suborders[state.activeSuborderIndex].services[id];

				if (service && 'workType' in service) {
					service.workType = option;
					service.price = toPrecise(option.price);

					syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
				}
			} else {
				const service = state.draftOrder.services[id];

				if (service && 'workType' in service) {
					service.workType = option;
					service.price = toPrecise(option.price);
				}
			}
		},
		updatePaintToningService: (state, action: PayloadAction<{ id: string } & Record<string, unknown>>) => {
			const { id, ...restProps } = action.payload;
			const rootSuborder = state.suborders[0];

			Object.entries(restProps).forEach(([key, value]) => {
				const service = state.suborders[state.activeSuborderIndex].services[id];

				if (service) {
					service[key] = value;
				}

				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			});
		},
		setItemsDeleteCandidates: (state, action: PayloadAction<{ items: Product[] | IService[] }>) => {
			state.itemsDeleteCandidates = action.payload.items;
		},
		clearItemsDeleteCandidates: (state) => {
			state.itemsDeleteCandidates = [];
		},
		clearAllExceptOrder: (state) => {
			state.subordersLimit = SUBORDERS_LIMIT;
			state.rootOrderId = null;
			state.activeSuborderIndex = 0;
		},
		syncDraftOrderWithRootSuborder: (state) => {
			if (!state.suborders) {
				state.suborders = [];
			}

			state.activeSuborderIndex = 0;
			state.suborders[0] = { ...state.draftOrder };
		},
		syncWithUseGetOrderQuery: (state, action: PayloadAction<IOrder>) => {
			const order = action.payload;
			const {
				orderId,
				totalPrice,
				totalVolume,
				totalWeight,
				subordersCount,
				suborders,
				hasPaintToningSuborder,
				locker,
				draftOrder,
				paintToningSubordersCount,
			} = getHydrateOrderStateData(order, state?.draftOrder, state?.draftOrder?.services ?? {});

			state.etalonOrder = order;
			state.draftOrder = draftOrder;
			state.subordersCount = subordersCount;
			state.rootOrderId = orderId;
			state.suborders = suborders;

			state.totalPrice = totalPrice;
			state.totalWeight = totalWeight;
			state.totalVolume = totalVolume;
			state.hasAtLeastOnePaintToningSuborderOnServer = hasPaintToningSuborder;
			state.locker = locker;
			state.activeSuborderIndex = state?.activeSuborderIndex || 0;
			state.paintToningSubordersCount = paintToningSubordersCount;
		},
		/*
		 * paint toning flow
		 */
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(ordersSliceApi.endpoints.getOrderById.matchFulfilled, ordersSliceApi.endpoints.updateOrder.matchFulfilled),
			(state, action) => {
				const order = action.payload;

				const {
					orderId,
					totalPrice,
					totalVolume,
					totalWeight,
					subordersCount,
					suborders,
					hasPaintToningSuborder,
					locker,
					draftOrder,
					paintToningSubordersCount,
				} = getHydrateOrderStateData(order, state.suborders[0], state?.draftOrder?.services ?? {});

				state.etalonOrder = order;
				state.draftOrder = draftOrder;
				state.subordersCount = subordersCount;
				state.suborders = suborders;
				state.rootOrderId = orderId;
				state.totalPrice = totalPrice;
				state.totalWeight = totalWeight;
				state.totalVolume = totalVolume;
				state.hasAtLeastOnePaintToningSuborderOnServer = hasPaintToningSuborder;
				state.locker = locker;
				state.activeSuborderIndex = state?.activeSuborderIndex || 0;
				state.paintToningSubordersCount = paintToningSubordersCount;
			},
		);
		builder.addMatcher(isAnyOf(ordersSliceApi.endpoints.calculateColorant.matchFulfilled), (state, action) => {
			const colorants = action.payload;

			const paintToningSuborder = state.suborders.find((suborder) => suborder.type === 'paint_toning');

			if (paintToningSuborder && colorants && colorants.length > 0) {
				const rootSuborder = state.suborders[0];

				paintToningSuborder.products.push(...colorants);
				rootSuborder.products.push(...colorants);
			}
		});
		builder.addMatcher(isAnyOfRecalculateOrderStatsTriggers(), (state) => {
			const rootSuborder = state.suborders[0] ?? ({} as Suborder);
			const products = rootSuborder.products ?? [];
			const services = rootSuborder.services ?? {};

			const { totalPrice, totalWeight, totalVolume } = recalculateOrderStats(products, services);

			state.totalPrice = totalPrice;
			state.totalWeight = totalWeight;
			state.totalVolume = totalVolume;
		});
		builder.addMatcher(isAnyOfRefreshRerenderKeyTriggerActions(), (state) => {
			state.lastForcedRerenderTimestamp = Date.now();
		});
	},
});

const recalculateTriggerActions = [
	orderPreviewSlice.actions.updateProductProperty,
	orderPreviewSlice.actions.updateServiceQuantity,
	orderPreviewSlice.actions.updateServicePrice,
	orderPreviewSlice.actions.addService,
	orderPreviewSlice.actions.removeManyServices,
	orderPreviewSlice.actions.removeService,
	orderPreviewSlice.actions.removeSuborder,
];

function isAnyOfRecalculateOrderStatsTriggers() {
	return isAnyOf(...recalculateTriggerActions);
}

const refreshRerenderKeyTriggerActions = [orderPreviewSlice.actions.createBlankSuborder, orderPreviewSlice.actions.createSuborderWithProducts];

function isAnyOfRefreshRerenderKeyTriggerActions() {
	return isAnyOf(...refreshRerenderKeyTriggerActions);
}

export const orderPreviewActions = orderPreviewSlice.actions;
export default orderPreviewSlice.reducer;
