import ColorfulSelect from 'components/ColorfulSelect';
import Select from 'components/Select';
import SpinnerV2 from 'components/Spinner-v2';
import { breakPoints } from 'const';
import { useAppSelector } from 'hooks/redux';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useGetServicesOptionListQuery } from 'store/reducers/orders/ordersSliceApi';
import { selectDependantOption, selectMajorOption } from 'store/reducers/orderViewer/selectors';

import RootButton from './RootButton';
import styles from './styles.module.css';
import type { ServiceOptionSelectDependantFieldProps } from './types';
import { useBackdrop } from './useBackdrop';

const ServiceOptionSelectDependantField = ({ id, skip = false, placeholder }: ServiceOptionSelectDependantFieldProps) => {
	const { activeSuborder, draftOrder, utils } = useOrderViewer();
	const isOnSplitPage = utils.isOnSuborderSplittingPage();
	const target = isOnSplitPage ? 'suborder' : 'order';
	const source = isOnSplitPage ? activeSuborder : draftOrder;

	const value = useAppSelector(selectDependantOption(id, target));
	const majorOption = useAppSelector(selectMajorOption(id, target));
	const { ref, hide, toggleVisibility } = useBackdrop();

	// @ts-ignore
	const { data: options = [], isLoading } = useGetServicesOptionListQuery(majorOption?.id, {
		// @ts-ignore
		skip: skip || !majorOption?.id,
	});
	const handler = source.onSelectChangeServiceWorkType;

	useEffect(() => {
		if (options?.length > 0) {
			// @ts-ignore
			handler(id, options[0]);
		}
	}, [handler, options, isOnSplitPage]);

	if (isLoading) return <SpinnerV2 />;

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<ColorfulSelect
					closeOnSelect
					popupHeaderTitle="Виберіть за параметрами"
					// @ts-ignore
					onSelect={(v) => handler(id, v)}
					// @ts-ignore
					popupHeaderClassName={styles.popupHeader}
					// @ts-ignore
					value={value}
					rootButton={(props) => <RootButton {...props} disabled={!value} title={placeholder} />}
					// @ts-ignore
					options={options}
				/>
			</MediaQuery>
			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Select
					// @ts-ignore
					disabled={!majorOption?.id && !options?.length}
					// @ts-ignore
					wrapperClassName={styles.selectField}
					// @ts-ignore
					value={value}
					// @ts-ignore
					setValue={(v) => handler(id, v)}
					onClick={toggleVisibility}
					onClose={hide}
					placeholder={placeholder}
					valuesList={options ?? []}
					dropdownHeader="Виберіть за параметрами"
				/>
			</MediaQuery>

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectDependantField;
