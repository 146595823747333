import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useEffect, useMemo, useRef } from 'react';
import { ReactComponent as InfoIcon } from 'static/images/info-circle.svg';

import styles from './styles.module.css';
import type { LockedProductQuantityGateProps } from './types';

export const LockedProductQuantityGate = ({ children, rowIndex, item }: LockedProductQuantityGateProps) => {
	const wrapperRef = useRef<HTMLSpanElement>(null);
	const {
		utils,
		booleans,
		suborders: { pureSuborders },
	} = useOrderViewer();

	const infoContent = useMemo(() => {
		return pureSuborders
			.map((suborder) => {
				const productFound = suborder.products.find((product) => String(product.id) === String(item.id));

				if (!productFound) return null;

				return `${suborder.meta.tabTitle} - ${item.pickedAmount ?? 0} ${item.measureUnit?.label ?? 'шт'}`;
			})
			.filter((info) => info !== null);
	}, [item, pureSuborders]);

	const shouldRenderGate = utils.isOnMainOrderPage() ? infoContent.length > 0 : infoContent.length > 0 && booleans.isOnRootOrderTab;

	useEffect(() => {
		if (wrapperRef.current) {
			const input = wrapperRef.current.querySelector('input');

			if (input) {
				input.disabled = true;
				input.readOnly = true;
			}
		}
	}, []);

	if (!shouldRenderGate) return children;

	const text = (
		<>
			<p className={styles.textLeft}>Розбито на замовлення:</p>
			{infoContent.map((info, index) => (
				<p key={index} className={clsx(styles.semibold, styles.textLeft)}>
					{info}
				</p>
			))}
		</>
	);

	const isFirstRow = rowIndex === 0;
	const tooltipPosition = isFirstRow ? 'bottom' : 'top';

	return (
		<span ref={wrapperRef} className={styles.gate}>
			{children}
			<Tooltip withArrowPointer text={text} className={styles.tooltip} position={tooltipPosition}>
				<InfoIcon className={styles.infoIcon} />
			</Tooltip>
		</span>
	);
};
