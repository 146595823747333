import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { numberify } from 'utils/shared';
import { isString } from 'utils/type-guards';

import { MeasureUnit, Product } from '../orders/types';
import type { IOrderSliceInitialState } from './types';

const initialState = {
	previewProduct: null,
	products: {},
	mode: 'create',
	view: 'popup',
	isModalOpen: false,
} as IOrderSliceInitialState;

export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		maybeSelectOne: (
			state,
			action: PayloadAction<{
				product: IOrderSliceInitialState['previewProduct'];
				mode: IOrderSliceInitialState['mode'];
				view?: IOrderSliceInitialState['view'];
			}>,
		) => {
			state.previewProduct = action.payload.product;
			state.mode = action.payload.mode;
			state.view = action.payload.view ?? 'popup';
		},
		clearMaybeSelectOne: (state) => {
			state.previewProduct = null;
			state.mode = 'create';
			state.view = 'popup';
		},
		toggleProductInOrderCart: (state, action: PayloadAction<{ product: Product; isSelected: boolean }>) => {
			const { product, isSelected } = action.payload;
			const key = String(product.id);

			if (isSelected) {
				state.products[key] = product;
			} else {
				delete state.products[key];
			}
		},
		deleteProductFromOrderCart: (state, action: PayloadAction<{ id: string }>) => {
			const { id } = action.payload;
			const key = String(id);

			delete state.products[key];
		},
		clearMaybeSelectedAll: (state) => {
			state.previewProduct = null;
			state.products = {};
		},
		updateProductQuantityInCart: (state, action: PayloadAction<{ quantity: number | string }>) => {
			const { quantity } = action.payload;

			const newProduct = {
				...state.previewProduct,
				pickedAmount: isString(quantity) ? numberify(quantity) : quantity,
			};

			state.previewProduct = newProduct;
		},
		updateProductQuantityByIdInCart: (state, action: PayloadAction<{ id: string; quantity: number | string }>) => {
			const { id, quantity } = action.payload;
			const key = String(id);
			const products = state.products;
			const existingProduct = products[key] ?? {};

			const newProduct = {
				...existingProduct,
				pickedAmount: Number(quantity),
			};

			products[key] = newProduct;
		},
		updateProductMeasureUnitInCart: (state, action: PayloadAction<{ unit: MeasureUnit }>) => {
			const { unit } = action.payload;

			const newProduct = {
				...state.previewProduct,
				unit,
			};

			state.previewProduct = newProduct;
		},
		maybeSelectMany: (state, action: PayloadAction<{ products: Product[] }>) => {
			const { products } = action.payload;

			products.forEach((product) => {
				const { id } = product;
				const key = String(id);

				state.products[key] = { ...product, pickedAmount: 1 };
			});
		},
		updateSelectedManyWithPreviewProduct: (state) => {
			const key = String(state.previewProduct.id);
			const existingProduct = state.products[key];

			const newProducts = {
				...state.products,
				[key]: { ...existingProduct, ...state.previewProduct },
			};

			state.products = newProducts;
		},
		openModal: (state) => {
			state.isModalOpen = true;
		},
		closeModal: (state) => {
			state.isModalOpen = false;
		},
	},
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
