import ColorfulSelect from 'components/ColorfulSelect';
import Select from 'components/Select';
import SpinnerV2 from 'components/Spinner-v2';
import { breakPoints } from 'const';
import { useAppSelector } from 'hooks/redux';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useGetServicesOptionListQuery } from 'store/reducers/orders/ordersSliceApi';
import { selectMajorOption } from 'store/reducers/orderViewer/selectors';

import RootButton from './RootButton';
import styles from './styles.module.css';
import type { ServiceOptionSelectFieldProps } from './types';
import { useBackdrop } from './useBackdrop';

const ServiceOptionSelectField = ({ id, skip = false, placeholder }: ServiceOptionSelectFieldProps) => {
	const { data: options = [], isLoading } = useGetServicesOptionListQuery('', { skip });
	const { activeSuborder, draftOrder, utils } = useOrderViewer();

	const isOnSplitPage = utils.isOnSuborderSplittingPage();
	const target = isOnSplitPage ? 'suborder' : 'order';
	const source = isOnSplitPage ? activeSuborder : draftOrder;

	const value = useAppSelector(selectMajorOption(id, target));
	const { ref, hide, toggleVisibility } = useBackdrop();

	const handler = source.onSelectChangeServiceType;

	useEffect(() => {
		if (options?.length > 0) {
			// @ts-ignore
			handler(id, options[0]);
		}
	}, [handler, options, isOnSplitPage]);

	if (isLoading) return <SpinnerV2 />;

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<ColorfulSelect
					closeOnSelect
					popupHeaderTitle="Виберіть тип послуги"
					popupHeaderClassName={styles.popupHeader}
					// @ts-ignore
					value={value}
					rootButton={(props) => <RootButton {...props} disabled={!value} title={placeholder} />}
					// @ts-ignore
					options={options}
					// @ts-ignore
					onSelect={(v) => handler(id, v)}
				/>
			</MediaQuery>
			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Select
					disabled={!options?.length}
					// @ts-ignore
					wrapperClassName={styles.selectField}
					// @ts-ignore
					value={value}
					// @ts-ignore
					setValue={(v) => handler(id, v)}
					onClick={toggleVisibility}
					onClose={hide}
					placeholder={placeholder}
					valuesList={options}
					dropdownHeader="Виберіть тип послуги"
				/>
			</MediaQuery>

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectField;
