/* eslint-disable @typescript-eslint/no-explicit-any */
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { selectAllOrganizations } from '../users/selectors';
import { ordersSliceApi } from './ordersSliceApi';

const rootState = (state: RootState) => state;
const createOrderSliceStateSelector = createDraftSafeSelector(rootState, (state) => state.createOrder);

const emptyArray = [];
const emptyObject = {};

// **** ROOT or QUERY SELECTORS **** //
const orderByIdQuerySelector = ordersSliceApi.endpoints.getOrderById.select;
const orderStatusListQuerySelector = ordersSliceApi.endpoints.getOrderStatusList.select(undefined);
const newlyCreatedOrderQuerySelector = ordersSliceApi.endpoints.createOrder.select('new-order');

// === PARTICULAR SELECTORS ==== //

// ---------> selects current order by id in url <--------- //
export const selectOrder = (id: string) => {
	return createDraftSafeSelector(orderByIdQuerySelector(id), (request) => request?.data ?? emptyObject);
};

// ---------> selects products of current order <--------- //
export const selectProductsInOrder = (id: string) =>
	// @ts-ignore
	createDraftSafeSelector(selectOrder(id), (order) => order?.products ?? emptyArray);

// ---------> selects product user has picked to add to the current order <--------- //
export const selectProductBeingPicked = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state?.productBeingPicked);
export const selectConfigurationToolView = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state?.view);
export const selectCart = createDraftSafeSelector(createOrderSliceStateSelector, (state) => Object.values(state?.cart ?? {}));
export const selectGlobalComment = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state.comment);

// ---------> selects if mode of product configuration is 'create' <--------- //
export const selectIsCreateConfigurationMode = createDraftSafeSelector(createOrderSliceStateSelector, (state) => state?.mode === 'create');

// ---------> selects blank created order <--------- //
export const selectCreatedOrder = createDraftSafeSelector(
	newlyCreatedOrderQuerySelector,
	(response) => response?.data ?? (emptyObject as Record<string, any>),
);

// ---------> selects comment of current order <--------- //
export const selectOrderComment = (id: string) => {
	// @ts-ignore
	return createDraftSafeSelector(selectOrder(id), (order) => order?.comment ?? '');
};

export const selectClientInfo = (id: string) => {
	return createDraftSafeSelector(
		(state: RootState) => state,
		(state) => {
			const order = selectOrder(id)(state);
			const organizations = selectAllOrganizations(state);

			// @ts-ignore
			if (!order?.client) return null;

			// @ts-ignore
			const { meta, contractType, client: name, id: clientId } = order.client ?? {};
			const { manager, department: warehouse, supervisor } = meta ?? {};

			const clientInfo = {
				client: { label: name, value: clientId },
				manager: { label: manager?.name, value: manager?.name },
				contractType: { label: contractType?.label, value: contractType?.value },
				warehouse,
				organization: organizations[0],
				supervisor: { label: supervisor?.name, value: supervisor?.name },
			};

			return clientInfo;
		},
	);
};

export const selectOrderStatusList = createDraftSafeSelector(orderStatusListQuerySelector, (state) => {
	return state.data ?? emptyArray;
});
