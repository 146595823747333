import { orderActions } from 'store/reducers/order';
import { selectIsModalOpen, selectPreviewedProduct, selectProductsInOrderCart } from 'store/reducers/order/selectors';
import { IOrderSliceInitialState } from 'store/reducers/order/types';
import { MeasureUnit, Product } from 'store/reducers/orders/types';
import { isNumber } from 'utils/type-guards';

import { usePatchOrder } from './orders';
import { useAppDispatch, useAppSelector } from './redux';

export const useOrderCart = () => {
	const dispatch = useAppDispatch();
	const [mutate, { isLoading, isError }] = usePatchOrder();

	const previewProduct = useAppSelector(selectPreviewedProduct);
	const maybeSelectedProducts = useAppSelector(selectProductsInOrderCart);
	const isModalOpened = useAppSelector(selectIsModalOpen);

	const maybeSelectOne = (params: {
		product: IOrderSliceInitialState['previewProduct'];
		mode: IOrderSliceInitialState['mode'];
		view?: IOrderSliceInitialState['view'];
	}) => {
		dispatch(orderActions.maybeSelectOne(params));
	};

	const clearMaybeSelectOne = () => {
		dispatch(orderActions.clearMaybeSelectOne());
	};
	const clearMaybeSelectedAll = () => {
		dispatch(orderActions.clearMaybeSelectedAll());
	};
	const maybeSelectMany = (products: Product[]) => {
		dispatch(orderActions.maybeSelectMany({ products }));
	};
	const updateProductQuantityInCart = (quantity: number | string) => {
		dispatch(orderActions.updateProductQuantityInCart({ quantity: Number(quantity) }));
	};
	const updateProductQuantityByIdInCart = (id: string, quantity: number | string) => {
		dispatch(orderActions.updateProductQuantityByIdInCart({ id, quantity: Number(quantity) }));
	};
	const updateProductMeasureUnitInCart = (unit: MeasureUnit) => {
		dispatch(orderActions.updateProductMeasureUnitInCart({ unit }));
	};
	const deleteProductFromOrderCart = (id: string) => {
		dispatch(orderActions.deleteProductFromOrderCart({ id }));
	};
	const toggleProductInOrderCart = (product: Product, isSelected: boolean) => {
		dispatch(orderActions.toggleProductInOrderCart({ product, isSelected }));
	};
	const updateProductsWithPreviewProduct = () => {
		dispatch(orderActions.updateSelectedManyWithPreviewProduct());
	};

	const saveInOneClick = (quantity: number | string) => {
		const patcher = (order) => {
			const products = Array.from(order?.products ?? []) as Product[];

			let foundProductIndex = -1;

			const foundProduct = products.find((product, index) => {
				if (String(product.id) === String(previewProduct.id)) {
					foundProductIndex = index;
					return true;
				}

				return false;
			});

			const hasSameProduct = !!foundProduct;

			let newProduct = previewProduct;

			if (hasSameProduct) {
				const pickedAmount = Number(foundProduct.pickedAmount) + Number(quantity);
				newProduct = { ...foundProduct, ...previewProduct, pickedAmount };
				products[foundProductIndex] = newProduct;
			} else {
				products.push({ ...previewProduct, pickedAmount: Number(quantity) });
			}

			return { ...order, products };
		};

		return mutate(patcher);
	};
	const save = () => {
		const patcher = (order) => {
			const products = Array.from(order?.products ?? []) as Product[];

			let foundProductIndex = -1;

			const foundProduct = products.find((product, index) => {
				if (String(product.id) === String(previewProduct.id)) {
					foundProductIndex = index;
					return true;
				}

				return false;
			});

			const hasSameProduct = !!foundProduct;

			let newProduct = previewProduct;

			if (hasSameProduct) {
				const pickedAmount = Number(foundProduct.pickedAmount) + Number(previewProduct.pickedAmount);
				newProduct = { ...foundProduct, ...previewProduct, pickedAmount };
				products[foundProductIndex] = newProduct;
			} else {
				products.push(previewProduct);
			}

			return { ...order, products };
		};

		return mutate(patcher);
	};

	const saveMany = () => {
		const patcher = (order) => {
			const rawProducts = order?.products ?? [];
			const serializedOriginalProducts = Object.fromEntries(rawProducts.map((rawProduct) => [rawProduct.id, rawProduct]));

			const newProducts = maybeSelectedProducts.map((product) => {
				const duplicateProduct = serializedOriginalProducts[product.id];

				let newProduct: Product;

				if (duplicateProduct) {
					const quantity = isNumber(product.pickedAmount) ? Number(product.pickedAmount) : 1;
					const pickedAmount = Number(duplicateProduct.pickedAmount) + quantity;

					newProduct = { ...duplicateProduct, pickedAmount };
					delete serializedOriginalProducts[product.id];
				} else {
					newProduct = { ...product, pickedAmount: isNumber(product.pickedAmount) ? product.pickedAmount : 1 };
				}

				return newProduct;
			});

			const mergedProducts = [...newProducts, ...Object.values(serializedOriginalProducts)];

			return { ...order, products: mergedProducts };
		};

		return mutate(patcher);
	};

	const getPreviewProduct = () => previewProduct;

	const openModal = () => {
		dispatch(orderActions.openModal());
	};
	const closeModal = () => {
		dispatch(orderActions.closeModal());
	};

	return {
		updateProductsWithPreviewProduct,
		updateProductQuantityByIdInCart,
		updateProductMeasureUnitInCart,
		updateProductQuantityInCart,
		deleteProductFromOrderCart,
		toggleProductInOrderCart,
		clearMaybeSelectedAll,
		clearMaybeSelectOne,
		getPreviewProduct,
		maybeSelectMany,
		maybeSelectOne,
		saveInOneClick,
		closeModal,
		openModal,
		saveMany,
		save,
		previewProduct,
		isLoading,
		isError,
		isModalOpened,
		products: maybeSelectedProducts,
	};
};
