/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBaseClient, IIndividualClient, ILegalClient } from 'models/IClient';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	useGetClientByIdQuery,
	useGetClientOrdersQuery,
	useGetClientsOptionsQuery,
	useGetClientsQuery,
	useGetContractsOptionsQuery,
	useGetContractsQuery,
	useGetSegmentsQuery,
} from 'store/reducers/clients/clientsSliceApi';
import { selectAllClients, selectAllContracts } from 'store/reducers/clients/selectors';
import { useGetOrdersQuery, useGetOrderStatusListQuery } from 'store/reducers/orders/ordersSliceApi';
import { selectAllManagers, selectAllOrganizations, selectAllWarehouses } from 'store/reducers/users/selectors';
import { useGetManagersOptionsQuery, useGetManagersQuery, useGetOrganizationsQuery, useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';

export const useClients = () => {
	const [searchParams] = useSearchParams();

	const clients = useSelector(selectAllClients(searchParams.toString()));
	const managers = useSelector(selectAllManagers);
	const contracts = useSelector(selectAllContracts);
	const warehouses = useSelector(selectAllWarehouses);
	const organizations = useSelector(selectAllOrganizations);

	return {
		clients: useMemo(() => {
			return serializeClients(clients, managers, contracts as Record<string, string>[], organizations as Record<string, string>[]);
		}, [clients, managers, contracts, organizations]),
		managers,
		contracts,
		warehouses,
		organizations,
	};
};

export const useClient = () => {
	const { id } = useParams<{ id: string }>();

	const { data: segmentsList } = useGetSegmentsQuery(undefined);
	const { data: rawClient } = useGetClientByIdQuery(id);
	const managers = useSelector(selectAllManagers);
	const contracts = useSelector(selectAllContracts);
	const organizations = useSelector(selectAllOrganizations);

	const [mappedClient] = useMemo(() => {
		const serializedClient = serializeClients(
			// @ts-ignore
			rawClient,
			managers,
			contracts as Record<string, string>[],
			organizations as Record<string, string>[],
		);

		const { type } =
			segmentsList?.find((segment) => {
				return segment?.label === rawClient[0]['Сегмент'];
			}) ?? {};

		const unSerializeClient = rawClient[0];

		return serializedClient.map((client) => ({
			id: client?.id,
			edrpouCode: unSerializeClient['ЕДРПОУ'],
			ipnCode: unSerializeClient['ІПН'],
			organizationName: unSerializeClient['Наименование'],
			passport: 'ЕК 12345678',
			name: client?.name,
			responsible: client?.meta?.manager?.name,
			segment: client?.segment?.label,
			limit: Math.ceil(Math.random() * 40000),
			delay: 2,
			date: new Date(),
			manager: client.meta.manager?.name,
			contractType: client.contractType,
			type,
			address: 'м. Київ, вулиця Жилянськая, 25-а',
			phoneNumber: [{ number: '380503456789', type: 'Особистий' }],
		}));
	}, [rawClient, managers, contracts, organizations, segmentsList]);

	return mappedClient;
};

export const useClientOrders = () => {
	const { id } = useParams<{ id: string }>();
	const [searchParams] = useSearchParams();

	const { data: clients } = useGetClientsQuery('');
	const { data: warehouses } = useGetWarehousesQuery(undefined);
	const { data: managers } = useGetManagersQuery(undefined);
	const { data: organizations } = useGetOrganizationsQuery(undefined);
	const { data: contracts } = useGetContractsQuery(undefined);

	const { data: statusList } = useGetOrderStatusListQuery(undefined);
	const { data: orders } = useGetClientOrdersQuery({ id, queryParams: searchParams.toString() });

	if (!clients || !orders || !statusList || !warehouses || !managers || !organizations || !contracts) return null;

	const client = serializeClients(
		clients.filter((rawClient) => rawClient['Id'] === id),
		managers,
		// @ts-ignore
		contracts,
		// @ts-ignore
		organizations,
	)[0];

	const data = {
		id,
		client,
		orders,
		statusList,
	};

	return data;
};

export const useClientMetaInfo = () => {
	const data = useClientOrders();
	const { client } = data ?? {};

	if (!client) {
		return {
			department: '',
			manager: '',
			facilities: [],
		};
	}

	return {
		department: client?.meta?.department,
		manager: { label: client?.meta?.manager?.name, value: client?.meta?.manager?.name },
		facilities: [],
	};
};

export const useClientsTableData = () => {
	const { clients } = useClients();
	const { data: orders } = useGetOrdersQuery('');

	return useMemo(() => {
		if (!clients || !orders) return null;

		return clients.map((client) => ({
			id: client?.id,
			client: client?.name,
			responsible: client?.meta?.manager?.name,
			segment: client?.segment?.label,
			limit: Math.ceil(Math.random() * 40000),
			delay: 2,
			date: new Date(),
			manager: client.meta.manager?.name,
			contractType: client.contractType,
			clientHasOrder: orders?.some((order) => order?.client?.id === client?.id),
		}));
	}, [clients, orders]);
};

export const useAutocompleteOptions = () => {
	const { organizations, warehouses } = useClients();
	const { data: contracts } = useGetContractsOptionsQuery(undefined);
	const { data: clients } = useGetClientsOptionsQuery(undefined);
	const { data: managers } = useGetManagersOptionsQuery(undefined);

	return useMemo(() => {
		if (!clients || !contracts || !managers || !organizations || !warehouses) return {};

		return {
			clients,
			organizations,
			contracts,
			warehouses,
			managers,
		};
	}, [clients, organizations, contracts, clients, warehouses]);
};

export const useClientSegments = () => {
	const { data: segments } = useGetSegmentsQuery(undefined);

	return segments ?? [];
};

type Client = ReturnType<typeof useClientsTableData>[number];
type BlackListedKeys = 'clientHasOrder' | 'id' | 'contractType';
type ClientKey = Exclude<keyof Client, BlackListedKeys>;
const blackListedProperties = ['id', 'clientHasOrder', 'contractType'];

export const useClientsFilterOptions = () => {
	const { data: clientsData = [] } = useGetClientsQuery(undefined);
	const managers = useSelector(selectAllManagers);
	const contracts = useSelector(selectAllContracts);
	const organizations = useSelector(selectAllOrganizations);

	const { clients: serializedClients } = {
		clients: useMemo(() => {
			return serializeClients(clientsData, managers, contracts as Record<string, string>[], organizations as Record<string, string>[]);
		}, [clientsData, managers, contracts, organizations]),
	};

	const { data: orders } = useGetOrdersQuery('');

	const clients = useMemo(() => {
		if (!serializedClients || !orders) return null;

		return serializedClients.map((client) => ({
			id: client?.id,
			client: client?.name,
			responsible: client?.meta?.manager?.name,
			segment: client?.segment?.label,
			limit: Math.ceil(Math.random() * 40000),
			delay: 2,
			date: new Date(),
			manager: client.meta.manager?.name,
			contractType: client.contractType,
			clientHasOrder: orders?.some((order) => order?.client?.id === client?.id),
		}));
	}, [serializedClients, orders]);

	const data = useMemo(() => {
		if (!clients)
			return {
				segment: [],
				responsible: [],
				client: [],
				date: [],
				manager: [],
				limit: [],
				delay: [],
			};

		const keysToGroupBy = (Object.keys(clients[0] ?? {}) as ClientKey[]).filter((key) => !blackListedProperties.includes(key));

		const group = keysToGroupBy.reduce(
			(acc, key) => ({
				...acc,
				[key]: Array.from(new Set(clients.map((client) => client[key]).filter(Boolean))),
			}),
			{} as Record<ClientKey, string[]>,
		);

		return group;
	}, [clients]);

	return data;
};

export function serializeClients(
	rawClients: Record<string, string>[],
	managers: Record<string, string>[],
	contracts: Record<string, string>[],
	organization: Record<string, string>[],
) {
	return rawClients.map((client) => {
		const isLegalClient = client['ЮрОсоба'] === 'Так';
		const clientManager = managers?.find((manager) => client['Менеджер'] === manager.id);
		const clientContract = contracts?.find((contract) => client['Id'] === contract?.clientId);
		const supervisor = managers?.find((manager) => clientManager?.supervisorId === manager.id);

		const serialized: IBaseClient = {
			id: client['Id'],
			segment: { label: client['Сегмент'], value: client['Сегмент'] },
			name: client['ФІО'] || client['Наименование'],
			phone: '0951246788',
			registryDate: new Date().toLocaleString(),
			contractType: clientContract,
			meta: {
				supervisor,
				department: {
					label: '',
					value: '',
				},
				manager: clientManager,
			},
			organization: organization[0],
		} as any;

		if (isLegalClient) {
			return {
				...serialized,
				edrpouCode: client['ЕДРПОУ'],
				type: 'legal',
			} as ILegalClient;
		} else {
			return {
				...serialized,
				type: 'individual',
				passportNumber: '',
				attachments: [],
			} as IIndividualClient;
		}
	});
}
