import { breakPoints } from 'const';
import React, { lazy, Suspense, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { Outlet } from 'react-router-dom';

import PageContentSkeleton from './PageContentSkeleton';
import styles from './styles.module.css';

const SideNavBar = lazy(() => import('components/SideNavBar'));
const NavBarMain = lazy(() => import('components/NavBarMain'));

const PageLayout: React.FC = () => {
	useEffect(() => {
		document.documentElement.classList.add('page-layout');

		return () => {
			document.documentElement.classList.remove('page-layout');
		};
	}, []);

	return (
		<div className={styles.mainContainer}>
			<Suspense>
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<SideNavBar className={styles.sidebar} />
				</MediaQuery>
			</Suspense>
			<Suspense>
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<NavBarMain />
				</MediaQuery>
			</Suspense>

			<div className={styles.pageContent}>
				<Suspense fallback={<PageContentSkeleton />}>
					<Outlet />
				</Suspense>
			</div>
		</div>
	);
};

export default PageLayout;
