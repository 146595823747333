import { ORDER_IS_SAVED_STATUS, ROUTES_URLS } from 'const';
import type { IOrder } from 'models/IOrder';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { unstable_useBlocker as useBlocker, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { localStorageService } from 'services/localStorageService';
import { createOrderActions } from 'store/reducers/orders';
import {
	useDeleteOrderMutation,
	useGetOrderByIdQuery,
	useGetOrdersQuery,
	useGetOrderStatusListQuery,
	useUpdateOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';
import { selectClientInfo, selectGlobalComment, selectOrder, selectOrderComment, selectOrderStatusList } from 'store/reducers/orders/selectors';
import { orderPreviewActions } from 'store/reducers/orderViewer';
import { checkIfOrderIsReserved } from 'utils/shared';

import { useClientOrders } from './clients';
import { useAppDispatch, useAppSelector } from './redux';

export const useOrder = () => {
	const dispatch = useAppDispatch();
	const { id } = useParams<{ id: string }>();
	const { data: order } = useGetOrderByIdQuery(id, { skip: !id });
	const client = useSelector(selectClientInfo(id));
	const statusList = useSelector(selectOrderStatusList);

	useEffect(() => {
		if (order) {
			dispatch(orderPreviewActions.syncWithUseGetOrderQuery(order as IOrder));
		}
	}, [order]);

	if (!order) {
		return {
			id,
			order: {},
			client: {},
			statusList: [],
		};
	}

	return {
		id,
		order,
		client,
		statusList,
	};
};

export const useOrderStatus = () => {
	const { id } = useParams<{ id: string }>();
	const order = useSelector(selectOrder(id));

	// @ts-ignore
	return order?.status ?? '0';
};

export const useOrdersTableData = () => {
	const [searchParams] = useSearchParams();
	const { data: orders } = useGetOrdersQuery(searchParams.toString());
	const { data: statusList } = useGetOrderStatusListQuery(undefined);
	const tableOrdersData = orders;

	if (!tableOrdersData || !statusList) return [];

	const tableData = tableOrdersData.map((order) => {
		return {
			id: order.id,
			shipmentStatus: statusList?.filter(({ type }) => type === 'shipment')?.find((status) => status.value === order?.status),
			paymentStatus: statusList?.filter(({ type }) => type === 'payment')?.find((status) => status.value === order?.status) ?? {
				label: 'Без оплати',
				value: '5',
				type: 'payment',
			},
			client: order?.client?.client,
			manager: order?.client?.meta?.manager?.name,
			createdAt: new Date(order?.createdAt),
			price: 1222,
			warehouse: order?.client?.meta?.department?.label,
			contractType: order?.client?.contractType?.label,
			logisticsStatus: 'Очікує загрузку',
			comment: order?.comment,
			number: order?.number,
		};
	});

	return tableData;
};

export const useClientOrdersTableData = () => {
	const clientOrders = useClientOrders();
	const { data: statusList } = useGetOrderStatusListQuery(undefined);

	const tableOrdersData = clientOrders?.orders ?? [];

	if (!tableOrdersData || !statusList) return [];

	return tableOrdersData.map((order) => {
		return {
			id: order.id,
			status: statusList.find((status) => status.value === order?.status),
			number: order?.number,
			createdAt: order?.createdAt,
			price: 1222,
			warehouse: order?.client?.meta?.department?.label,
			contractType: order?.client?.contractType?.label,
			comment: order?.comment,
			logisticsStatus: 'Очікує загрузку',
		};
	});
};

type OrderStatusOption = { label: string; value: string; type: string };
type OrderStatusList = { shipment: OrderStatusOption[]; payment: OrderStatusOption[] };

export const useOrderStatusList = (): OrderStatusList => {
	const { data: statusList } = useGetOrderStatusListQuery(undefined);

	return useMemo(() => {
		if (!statusList) return { shipment: [], payment: [] };

		return statusList.reduce(
			(list: OrderStatusList, status: OrderStatusOption) => {
				if (status.type === 'shipment') {
					list.shipment.push(status);
				} else {
					list.payment.push(status);
				}

				return list;
			},
			{ shipment: [], payment: [] },
		);
	}, [statusList]);
};

// -- CRUD OPERATION HOOKS --//
export const useSaveOrder = () => {
	const { id } = useParams<{ id: string }>();
	const order = useSelector(selectOrder(id));
	const [mutate, state] = useUpdateOrderMutation();

	// @ts-ignore
	const isReserved = checkIfOrderIsReserved(order.status);

	const save = useCallback(
		(pathFn?: PatchFn) => {
			// @ts-ignore
			let orderToBeSaved = { ...order, status: isReserved ? order.status : ORDER_IS_SAVED_STATUS };

			if (pathFn) {
				orderToBeSaved = pathFn(orderToBeSaved);
			}

			return mutate(orderToBeSaved);
		},
		[order, id],
	);

	return [save, state] as const;
};

export const useDeleteOrder = () => {
	const { id } = useParams<{ id: string }>();
	const [mutate, state] = useDeleteOrderMutation();

	const remove = useCallback(() => {
		// @ts-ignore
		return mutate(id);
	}, [id]);

	return [remove, state] as const;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PatchFn = (order: any) => any;

export const usePatchOrder = () => {
	const { id } = useParams<{ id: string }>();
	const order = useSelector(selectOrder(id));
	const [mutate, state] = useUpdateOrderMutation();

	const patch = useCallback(
		(patchFn: PatchFn) => {
			const patchedOrder = patchFn(order);

			return mutate(patchedOrder);
		},
		[order, id],
	);

	return [patch, state] as const;
};
// --- END CRUD OPERATION HOOKS --- //

// -- OPERATIONS OVER COMMENT -- //
const notifySuccess = (msg: string) => toast.success(msg, { autoClose: 1000, style: { zIndex: 999999999, position: 'fixed' } });

type UseOrderCommentProps = {
	onSuccess?(): void;
	onError?(): void;
	successAlert?: boolean;
	errorAlert?: boolean;
	global?: boolean;
};

export const useOrderComment = (props?: UseOrderCommentProps) => {
	const { id } = useOrder();
	const [patch, { isLoading, isError }] = usePatchOrder();
	const commentFromOrder = useSelector(selectOrderComment(id));
	const dispatch = useAppDispatch();
	const globalComment = useAppSelector(selectGlobalComment);

	const [comment, setComment] = useState<string>(commentFromOrder); // temp
	const { onSuccess, onError, successAlert = true, errorAlert = true, global = false } = props ?? {};

	useEffect(() => {
		if (global && globalComment) {
			setComment(globalComment);
		} else {
			setComment(commentFromOrder);
		}
	}, [commentFromOrder, global]);

	useEffect(() => {
		if (global) {
			dispatch(createOrderActions.setComment(comment));
		}
	}, [global, comment]);

	useEffect(() => {
		if (isError) {
			if (errorAlert) toast.error('Помилка при збереженні коментаря', { autoClose: 1000, style: { zIndex: 999999999, position: 'fixed' } });

			onError?.();
		}
	}, [isError, errorAlert]);

	const save = async () => {
		if (!comment) return toast.error('Вкажіть коментар', { autoClose: 1000, style: { zIndex: 999999999, position: 'fixed' } });
		if (comment === commentFromOrder) return onSuccess?.();
		// @ts-ignore
		await patch((prevOrder) => ({ ...prevOrder, comment }));

		if (successAlert) notifySuccess('Коментар збережено');

		onSuccess?.();
	};
	const remove = async () => {
		// @ts-ignore
		await patch((prevOrder) => ({ ...prevOrder, comment: '' }));

		if (successAlert) notifySuccess('Коментар видалено');

		onSuccess?.();
	};

	return {
		orderId: id,
		value: comment,
		setComment,
		save,
		delete: remove,
		isLoading,
		isError,
	};
};
// --- END OVER COMMENT OPERATION --- //

// --- ORDER PROMPT BEFORE LEAVE ON CLIENT SELECT STEP OF CREATING ORDER FLOW ---//
export const useBeforeIncompleteOrderLeave = () => {
	const { order } = useOrder();
	const [deleteOrder] = useDeleteOrder();
	// @ts-ignore
	const hasSelectedClient = !!order?.client?.client;

	const shouldBlockNavigationToTargetRoute = useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) => {
			const isDifferentRoute = currentLocation.pathname !== nextLocation.pathname;

			return !hasSelectedClient && isDifferentRoute;
		},
		[hasSelectedClient],
	);

	const blocker = useBlocker(shouldBlockNavigationToTargetRoute);
	const isBlocked = blocker.state === 'blocked';

	const proceed = async () => {
		const targetRoute = blocker.location.pathname;
		const isSameRoute = targetRoute.endsWith(ROUTES_URLS.ORDER_CREATE);

		if (isSameRoute) {
			return blocker.reset();
		}

		localStorageService.remove('incomplete-order');

		await deleteOrder();
		blocker.proceed();
	};

	return { isBlocked, reset: blocker.reset, proceed };
};

type UseLongestStringWidthConfig = { limit: number };

export const useLongestStringWidth = <TData>(
	data: TData | TData[],
	extractor: (data: TData) => string,
	config?: UseLongestStringWidthConfig,
): number => {
	const { limit = 100_000 } = config ?? {};

	const longestStringWidth = useMemo(() => {
		if (!data && data) return 0;

		if (!Array.isArray(data)) {
			const str = extractor(data);

			return Math.min(str.length, limit);
		}

		if (data.length === 0) return 0;

		const longestStringLength = data.reduce<number>((max, str) => {
			const currentLength = extractor(str).length;
			return currentLength > max ? currentLength : max;
		}, 0);

		return Math.min(longestStringLength, limit);
	}, [data, limit]);

	return longestStringWidth;
};

export const useOrdersFilterOptions = () => {
	const { data: orders } = useGetOrdersQuery('');
	const { data: statusList } = useGetOrderStatusListQuery(undefined);
	const tableOrdersData = orders;

	if (!tableOrdersData || !statusList) return {};

	const tableData = tableOrdersData.map((order) => {
		return {
			id: order.id,
			shipmentStatus: statusList?.filter(({ type }) => type === 'shipment')?.find((status) => status.value === order?.status),
			paymentStatus: statusList?.filter(({ type }) => type === 'payment')?.find((status) => status.value === order?.status) ?? {
				label: 'Без оплати',
				value: '5',
				type: 'payment',
			},
			client: order?.client?.client,
			manager: order?.client?.meta?.manager?.name,
			createdAt: new Date(order?.createdAt),
			price: 1222,
			warehouse: order?.client?.meta?.department?.label,
			contractType: order?.client?.contractType?.label,
			logisticsStatus: 'Очікує загрузку',
			comment: order?.comment,
			number: order?.number,
			responsible: order?.client?.responsible,
		};
	});

	const clients: string[] = Array.from(new Set(tableData.map((order) => order.client).filter((client) => client !== undefined)));
	const managers: string[] = Array.from(new Set(tableData.map((order) => order.manager)));
	const responsible: string[] = Array.from(new Set(tableData.map((order) => order.responsible))).filter(Boolean) as string[];
	const shipmentStatuses: string[] = Array.from(
		new Set(tableData.map((order) => order.shipmentStatus).filter((shipment) => shipment !== undefined)),
	);
	const contractTypes: string[] = Array.from(new Set(tableData.map((order) => order.contractType).filter((contract) => contract !== undefined)));
	const warehouses: string[] = Array.from(new Set(tableData.map((order) => order.warehouse)));
	const paymentStatus: string[] = ['Без оплати'];

	return {
		clients: clients || [],
		managers: managers || [],
		shipmentStatuses: shipmentStatuses || [],
		contractTypes: contractTypes || [],
		warehouses: warehouses || [],
		paymentStatus: paymentStatus || [],
		responsible: responsible || [],
	};
};
