import { IService } from 'models/IServices';
import { Suborder } from 'store/reducers/orderViewer/types';
import {
	areValidPaintToningProducts,
	areValidRegularProducts,
	assignReservationStatus,
	isSuborderEmpty,
	prepareSubordersSaveDTO,
	unassignReservationStatus,
} from 'store/reducers/orderViewer/utils';
import { checkIfOrderIsReserved, constructNewIdSet, toArray } from 'utils/shared';
import { assignIsDisabledLabel, overrideAllSelection } from 'utils/table';
import { isObject } from 'utils/type-guards';

import { useSource } from './_useSource';

export const useUtils = () => {
	const { lockedItemsIdsList, activeSuborder, draftOrder, etalonOrder, allSuborders, booleans } = useSource();

	const isItemLocked = (id: string) => lockedItemsIdsList.includes(id) && activeSuborder.index === 0;

	const isDraftOrderOfRegularType = () => {
		const isRegularRootOrder = draftOrder.type !== 'paint_toning';
		const areAllSubordersOfRegularType = draftOrder.suborders.slice(1)?.every((suborder) => suborder?.type !== 'paint_toning');

		return isRegularRootOrder && areAllSubordersOfRegularType;
	};

	const wasSuborderSavedOnServer = (suborder?: Suborder) => {
		return !!suborder && !!suborder?.meta?.isSaved;
	};

	const wereServicesSavedOnServer = (candidates: IService[]) => {
		const hasServices = etalonOrder?.services?.length > 0;

		if (!hasServices) return false;

		const idSet = constructNewIdSet(candidates);

		return etalonOrder.services.every((service: IService) => idSet.has(service.id));
	};

	const getSubordersDTO = () => prepareSubordersSaveDTO(allSuborders, etalonOrder.original);
	const getSubordersWithReservationDTO = () => {
		if (booleans.isOnRootOrderTab) return;

		/*
		 * 'cause in dto in suborders we do not have root suborder, so we need to shift index down
		 */
		const validIndex = activeSuborder.index - 1;
		const dto = prepareSubordersSaveDTO(allSuborders, etalonOrder.original);
		const targetSuborder = dto.suborders[validIndex];
		const isReserved = checkIfOrderIsReserved(targetSuborder?.status ?? '');
		const modifiedSuborder = isReserved ? unassignReservationStatus(targetSuborder) : assignReservationStatus(targetSuborder);

		const modifiedSuborders = [...dto.suborders];
		modifiedSuborders[validIndex] = modifiedSuborder;

		const modifiedDTO = { ...dto, suborders: modifiedSuborders };

		return modifiedDTO;
	};
	const getDraftOrderDTO = () => {
		const { products, services, suborders } = draftOrder;
		const etalonSuborderIds = constructNewIdSet(etalonOrder.suborders ?? []);
		const resolvedSuborders = etalonSuborderIds.size > 0 ? suborders.filter((suborder) => !etalonSuborderIds.has(suborder.id)) : [];

		const orderDTO = { ...draftOrder.original, suborders: resolvedSuborders, products, services };

		return orderDTO;
	};

	const isPaintToningSuborder = (index: number) => {
		return allSuborders?.[index]?.type === 'paint_toning';
	};
	const getPaintToningSuborderIndex = () => {
		return allSuborders.findIndex((suborder) => suborder.type === 'paint_toning');
	};
	const hasOnePaintToningSuborder = () => {
		return allSuborders.some((suborder) => suborder?.type === 'paint_toning');
	};
	const hasDraftOrderSavedPaintToningService = () => {
		return draftOrder.paintToningServices.some((service) => service.isSaved);
	};

	const hasTargetSuborder = (index: number) => {
		return !!allSuborders[index];
	};

	return {
		isItemLocked,
		isSuborderEmpty,
		hasTargetSuborder,
		isPaintToningSuborder,
		overrideAllSelection,
		wasSuborderSavedOnServer,
		wereServicesSavedOnServer,
		isDraftOrderOfRegularType,
		getPaintToningSuborderIndex,
		hasOnePaintToningSuborder,
		hasDraftOrderSavedPaintToningService,
		isSplittingAvailable: isDraftOrderOfRegularType,
		assignIsDisabledLabel: assignIsDisabledLabel(sealRow(lockedItemsIdsList, activeSuborder.index)),
		validatePaintToningProducts: areValidPaintToningProducts,
		validateRegularProducts: areValidRegularProducts,
		getSubordersDTO,
		getDraftOrderDTO,
		getSubordersWithReservationDTO,
		isOnMainOrderPage,
		isOnProductsSubPage,
		isOnServicesSubPage,
		isOnSuborderSplittingPage,
		toArray,
		constructNewIdSet,
	};
};

// === static utils
function sealRow(lockedIds: string[], index: number) {
	const sealer = (item: unknown) => {
		if (isObject<{ id: string; isColorant?: boolean }>(item)) {
			const { id, isColorant } = item ?? {};
			const shouldDisable = isOnMainOrderPage() ? lockedIds.includes(id) || isColorant : (lockedIds.includes(id) && index === 0) || isColorant;
			return shouldDisable;
		}
	};

	return sealer;
}

function isOnMainOrderPage() {
	return window.location.pathname.startsWith('/order') && !window.location.pathname.endsWith('split');
}
function isOnProductsSubPage() {
	return (
		window.location.pathname.startsWith('/order') &&
		!window.location.pathname.endsWith('split') &&
		!window.location.pathname.endsWith('/services')
	);
}
function isOnServicesSubPage() {
	return window.location.pathname.startsWith('/order') && window.location.pathname.endsWith('/services');
}

function isOnSuborderSplittingPage() {
	return window.location.pathname.startsWith('/order') && window.location.pathname.endsWith('split');
}
