import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import { isNumber } from 'utils/type-guards';

import type { VirtualizedListProps } from './types';

const VirtualizedList = <TItem,>(
	{ items, width, height, itemSize, initialVisibleItemIndex, scrollOverscan = 10, renderItem }: VirtualizedListProps<TItem>,
	ref: ForwardedRef<List<TItem>>,
) => {
	const itemsCount = items.length;
	const innerRef = useRef<List<TItem>>(null);

	useImperativeHandle(ref, () => innerRef.current);

	useEffect(() => {
		if (innerRef.current && isNumber(initialVisibleItemIndex) && initialVisibleItemIndex >= 0) {
			innerRef.current.scrollToItem(initialVisibleItemIndex + scrollOverscan);
		}
	}, [initialVisibleItemIndex]);

	const renderer = useCallback(({ index, style }) => renderItem({ item: items[index], style, index }), [renderItem]);

	return (
		<List ref={innerRef} itemCount={itemsCount} width={width} height={height} itemSize={itemSize}>
			{renderer}
		</List>
	);
};

export default forwardRef(VirtualizedList) as <TItem>(props: VirtualizedListProps<TItem> & { ref?: ForwardedRef<List<TItem>> }) => JSX.Element;
