import clsx from 'clsx';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import type { IProps as ModalProps } from 'components/Modal/types';
import ProductItem from 'components/OrderCreatePageComponents/BulkProductSelection/Item';
import TipCard from 'components/TipCard';
import { useOrderViewer } from 'hooks/useOrderViewer';
import { IService } from 'models/IServices';
import React, { useState } from 'react';
import PlusIcon from 'static/images/plus.svg';
import { numberify } from 'utils/shared';

import styles from './styles.module.css';

type TransferServicesModalProps = Pick<ModalProps, 'onClose'> & {
	targetSuborderIndex: number;
	selectedItems: IService[];
};

const TransferServicesModal: React.FC<TransferServicesModalProps> = ({ onClose, targetSuborderIndex, selectedItems }) => {
	const {
		suborders: { handlers },
		activeSuborder: { index },
	} = useOrderViewer();

	const [writableServices, setWritableServices] = useState(selectedItems);

	const handleServiceTransfer = () => {
		handlers.transferServicesWithData({ from: index, to: targetSuborderIndex, data: writableServices });
		onClose?.();
	};

	const handleItemQuantityChange = (id: string, newQuantity: number) => {
		setWritableServices(
			writableServices.map((b) => {
				if (String(b.id) !== String(id)) return b;

				return {
					...b,
					quantity: newQuantity,
				};
			}),
		);
	};

	return (
		<Modal noAwayClick open stackable onClose={onClose} className={styles.modal} maxWidth="800px" maxHeight="518px">
			<ModalHeader title={<span className={styles.headerTitle}>Перенесення послуг</span>} onXCloseClick={onClose} className={styles.header} />

			<ul className={clsx(styles.list)}>
				{writableServices.map((service) => (
					<ProductItem
						key={service.id}
						onQuantityChange={handleItemQuantityChange.bind(null, String(service.id))}
						initialValue={numberify(service.quantity)}
						price={numberify(service.price)}
						brand=""
						title={service.service}
						measureUnit={{ label: 'Шт', value: 'pcs' }}
					/>
				))}
			</ul>

			<TipCard message="Послуга не містить додаткової інформації" />

			<div className={styles.controls}>
				<Button variant="default" onClick={onClose} className={styles.cancel} text="Скасувати" />
				<Button
					variant="rounded"
					background="var(--success-500)"
					onClick={handleServiceTransfer}
					text={`Перенести в заявку ${targetSuborderIndex}`}
					icon={PlusIcon}
				/>
			</div>
		</Modal>
	);
};

export default TransferServicesModal;
