import clsx from 'clsx';
import { usePaintToningServicesColumns, useRegularServicesColumns } from 'columns/services';
import CalculateColorant from 'components/OrderPageComponents/CalculateColorant';
import ServiceEmptyState from 'components/OrderServicesPageComponents/ServiceEmptyState';
import ServiceSelectors from 'components/OrderServicesPageComponents/ServiceSelectors';
import Table from 'components/Table';
import ServicesMobileTable from 'components/Table/MobileViews/ServicesMobileTable';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { breakPoints } from 'const';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrderViewer } from 'hooks/useOrderViewer';
import ActionButton from 'layouts/PageLayout/ActionButton';
import React, { MouseEvent, useMemo, useState, useTransition } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import PlusIcon from 'static/images/plus.svg';

import styles from './styles.module.css';

const Services: React.FC = () => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const [, startTransition] = useTransition();

	const regularServicesColumns = useRegularServicesColumns();
	const paintToningServicesColumns = usePaintToningServicesColumns();

	const { draftOrder, utils } = useOrderViewer();

	const { toggleSelectedItems, selectedItems } = useOrderContext();
	const [isServiceListOpened, setIsServiceListOpened] = useState(false);

	const hasRegularServices = draftOrder.regularServicesCount > 0;
	const hasPaintToningServices = draftOrder.paintToningServicesCount > 0;
	const hasAddedServices = draftOrder.allServicesCount > 0;

	const handleServiceListClose = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		startTransition(() => setIsServiceListOpened(false));
	};
	const handleServiceListOpen = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsServiceListOpened(true);
	};

	const shouldOpenServiceSelectors = isMobile && isServiceListOpened;
	const preselectedIds = useMemo(() => selectedItems.map((item) => ({ [item.id]: true } as const)), [selectedItems]);

	return (
		<>
			{shouldOpenServiceSelectors && (
				<ServiceSelectors
					onAddOrder={draftOrder.addService}
					appearance="popup"
					onClose={handleServiceListClose}
					onClick={handleServiceListClose}
				/>
			)}
			{!isMobile && <ServiceSelectors onAddOrder={draftOrder.addService} appearance="button-list" />}

			{!hasAddedServices && <ServiceEmptyState />}

			{hasAddedServices && hasRegularServices && (
				<Table
					selectable
					pageType="order"
					columns={regularServicesColumns}
					allData={draftOrder.regularServices}
					mobileViewComponent={ServicesMobileTable}
					onChangeSelection={toggleSelectedItems}
					overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
					defineRowDisabledForSelection={utils.assignIsDisabledLabel}
					className={clsx(styles.servicesTable, styles.regularServices)}
					preselectedRowIds={preselectedIds}
					mobileHeader={({ headerGroups }) => (
						<MobileHeader
							gridCustomLayout="44px auto"
							headerGroups={headerGroups}
							renderHeaders={['selection', 'service']}
							justify="flex-start"
						/>
					)}
				/>
			)}
			{hasAddedServices && hasPaintToningServices && (
				<>
					<Table
						selectable={false}
						pageType="order"
						columns={paintToningServicesColumns}
						allData={draftOrder.paintToningServices}
						mobileViewComponent={ServicesMobileTable}
						onChangeSelection={toggleSelectedItems}
						overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
						defineRowDisabledForSelection={utils.assignIsDisabledLabel}
						className={clsx(styles.servicesTable, styles.paintToningServices)}
						preselectedRowIds={preselectedIds}
						mobileHeader={({ headerGroups }) => (
							<MobileHeader
								gridCustomLayout="44px auto"
								headerGroups={headerGroups}
								renderHeaders={['selection', 'service']}
								justify="flex-start"
							/>
						)}
					/>

					<CalculateColorant />
				</>
			)}

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<ActionButton
					variant="default"
					text="Додати послугу"
					onClick={handleServiceListOpen}
					icon={PlusIcon}
					className={styles.addServiceButton}
				/>
				<div className="safe-area-bottom" />
			</MediaQuery>
		</>
	);
};

export default Services;
