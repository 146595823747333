/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { breakPoints } from 'const';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTable } from 'react-table';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import { defaultDocumentDateFormatter, defaultReservationDateFormatter } from './config';
import MobileReservedProductsTable from './MobileReservedProductsTable';
import styles from './styles.module.css';
import type { IProps } from './types';

const ReservedProductsTable: React.FC<IProps> = ({
	data,
	onCancelReservation,
	documentDateFormatter = defaultDocumentDateFormatter,
	reservationDateFormatter = defaultReservationDateFormatter,
}) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const columns = useMemo(() => {
		const newColumns = [
			{
				Header: 'Клієнт',
				accessor: 'client',
				Cell: ({ row }) => (
					<>
						<span className={styles.highlighted}>{row.original.client.organization}</span>
						{isMobile ? null : <br />}
						<span>{row.original.client.name}</span>
					</>
				),
			},
			{ Header: 'Менеджер', accessor: 'manager' },
			{
				Header: 'Документ',
				accessor: 'document',
				Cell: ({ row }) => {
					const formattedDate = documentDateFormatter(row.original.document.createdAt);
					const [firstChunk, secondChunk] = formattedDate.split(' ');

					return (
						<>
							Замовлення отримувача <span className={styles.highlightedDark}>{row.original.document.number}</span> від&nbsp;
							<span className={styles.highlighted}>{firstChunk}</span>
							&nbsp;
							<span>{secondChunk}</span>
						</>
					);
				},
			},
			{
				Header: isMobile ? 'Дата до' : 'Зарезервовано до',
				accessor: 'reservedTill',
				Cell: ({ row }) => reservationDateFormatter(row.original.reservedTill),
			},
			{ Header: 'Кількість', accessor: 'quantity' },
			{
				Header: 'Зняти резерв',
				accessor: 'action',
				Cell: ({ row }) => (
					<button
						type="button"
						className={styles.cancelReservationButton}
						onClick={(e) => {
							e.stopPropagation();
							onCancelReservation?.(row?.original);
						}}
					>
						<TrashIcon className={styles.icon} />
						{isMobile ? <span>Перейти до броні</span> : null}
					</button>
				),
			},
		];

		return newColumns;
	}, [isMobile]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

	if (isMobile) {
		return <MobileReservedProductsTable data={data} columns={columns} onCancelReservation={onCancelReservation} />;
	}

	return (
		<table {...getTableProps()} data-reserved-products-table className={styles.reservedProductsTable}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps()} className="text-sx-medium color-grey-600">
								{column.render('Header')}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => (
								<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default ReservedProductsTable;
