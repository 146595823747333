import { paintBase, paintCollections } from 'components/PaintToningPageComponents/lib/mockData';
import { SelectOption } from 'components/Select/types';

import { metalCuttingServiceTypeData, metalCuttingServiceWorkTypeData } from './mockData';

export const loadMetalCuttingServiceTypeOptions = (): Promise<SelectOption[]> => {
	return new Promise((res) => {
		setTimeout(() => {
			res(metalCuttingServiceTypeData as unknown as SelectOption[]);
		}, 100);
	});
};
export const loadMetalCuttingServiceWorkTypeOptions = (referenceId: string) => {
	return new Promise((res) => {
		setTimeout(() => {
			res(metalCuttingServiceWorkTypeData.filter(({ serviceTypeReference }) => serviceTypeReference === referenceId));
		}, 100);
	});
};

export const loadPaintCollectionOptions = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintCollections);
		}, 100);
	});
};

export const loadPaintBaseToneOptions = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintBase);
		}, 100);
	});
};
