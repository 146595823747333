import { Breadcrumb } from 'components/BreadCrumbs/types';
import { getColorByIndex } from 'components/ColorfulSelect/utils';

export const bakeOrderBreadcrumbs = (order): Breadcrumb => {
	if (!order || !order?.number || !order?.createdAt) return { label: '' };

	return {
		label: `Заявка №${order.number} від ${new Date(order.createdAt).toLocaleDateString('uk-UA')}`,
	};
};

export const bindColorToStatus = (status: string | number) => {
	const color = getColorByIndex(Number(status));

	return color;
};
