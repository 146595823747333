import ColorfulSelect from 'components/ColorfulSelect';
import Select from 'components/Select';
import SpinnerV2 from 'components/Spinner-v2';
import { breakPoints } from 'const';
import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';

import RootButton from './RootButton';
import styles from './styles.module.css';
import type { RegularServiceSelectFieldProps } from './types';
import { useBackdrop } from './useBackdrop';

const RegularServiceSelectField = ({ skip = false, placeholder, popupHeaderText, value, useQuery, onSelect }: RegularServiceSelectFieldProps) => {
	const { data: options = [], isLoading } = useQuery('', { skip });
	const { ref, hide, toggleVisibility } = useBackdrop();

	useEffect(() => {
		if (options?.length > 0) {
			// @ts-ignore
			onSelect(options[0]);
		}
	}, [options]);

	if (isLoading) return <SpinnerV2 />;

	const isDisabled = !options?.length;

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<ColorfulSelect
					closeOnSelect
					popupHeaderTitle={popupHeaderText}
					popupHeaderClassName={styles.popupHeader}
					// @ts-ignore
					value={value}
					rootButton={(props) => <RootButton {...props} disabled={isDisabled} title={placeholder} />}
					// @ts-ignore
					options={options}
					// @ts-ignore
					onSelect={onSelect}
				/>
			</MediaQuery>
			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Select
					disabled={isDisabled}
					// @ts-ignore
					wrapperClassName={styles.selectField}
					// @ts-ignore
					value={value}
					// @ts-ignore
					setValue={onSelect}
					onClick={toggleVisibility}
					onClose={hide}
					placeholder={placeholder}
					valuesList={options}
					dropdownHeader={popupHeaderText}
				/>
			</MediaQuery>

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default RegularServiceSelectField;
