import clsx from 'clsx';
import Input from 'components/Input';
import Payable from 'components/OrderPageComponents/Payable';
import { breakPoints } from 'const';
import React, { FocusEvent, MouseEvent, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import DeleteIcon from 'static/images/delete.svg';
import { formatNumberToUAH } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps } from './types';

const QuantitySelector: React.FC<IProps> = ({
	priceFormatter = formatNumberToUAH,
	measureUnits,
	onMeasureUnitChange,
	onQuantityChange,
	totalPrice,
	className,
	quantity,
	unit,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const isTabletOnly = useMediaQuery({ minWidth: `${breakPoints.MOBILE}px`, maxWidth: `${breakPoints.LAPTOP_MD - 1}px` });
	const formattedPrice = priceFormatter(totalPrice);
	const units = measureUnits ?? [unit];

	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		if (isTabletOnly) {
			e.currentTarget.readOnly = isTabletOnly;
		}
	};
	const handleClearInputIconClick = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		const targetElement = e.target as HTMLElement;
		const tagName = targetElement.tagName;

		if (tagName !== 'IMG') return;

		if (quantity === 0) return;

		onQuantityChange?.(0);
	};

	return (
		<div data-quantity-selector className={clsx(styles.wrapper, className)}>
			<strong className={styles.title}>
				Укажіть кількість <span className={styles.selectedMeasureUnit}>{unit.label}</span>
			</strong>

			<div className={styles.subWrapper} onClick={handleClearInputIconClick}>
				<Input
					ref={inputRef}
					type="number"
					value={String(quantity)}
					setValue={(newValue) => onQuantityChange?.(Number(newValue))}
					className={styles.input}
					icon={DeleteIcon}
					iconPosition="trailing"
					onFocus={handleFocus}
				/>

				<ul className={styles.measureUnitsWrapper}>
					{units.map((measureUnit, index) => {
						const isActive = measureUnit.value === unit.value;

						return (
							<li key={index}>
								<button
									className={clsx('text-sm-medium', styles.button, { [styles.active]: isActive, 'text-sm-semibold': isActive })}
									type="button"
									onClick={() => onMeasureUnitChange(measureUnit)}
								>
									{measureUnit.label}
								</button>
							</li>
						);
					})}
				</ul>

				<Payable price={formattedPrice} className={styles.price} />
			</div>
		</div>
	);
};

export default QuantitySelector;
