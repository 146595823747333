import clsx from 'clsx';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalHeader from 'components/Modal/ModalHeader';
import React, { MouseEvent, useCallback, useState } from 'react';
import { isObject } from 'utils/type-guards';

import ColorfulBadge from '../ColorfulBadge';
import Checkbox from './Checkbox';
import styles from './styles.module.css';
import type { ColorfulSelectOption, IProps } from './types';
import { getColorByIndex } from './utils';

const ColorfulSelect = <TValue extends Record<string, unknown>>({
	options,
	value: selectedOption,
	onSelect,
	rootButton: RootButtonComponent,
	className,
	popupHeaderTitle = 'Змінити сегмент',
	popupHeaderClassName,
	closeOnSelect = false,
}: IProps<TValue>) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const resolvedSelectedOption = (
		isObject(selectedOption) ? selectedOption : { label: selectedOption, value: selectedOption }
	) as ColorfulSelectOption & TValue;

	const index = options.findIndex(({ value }) => value === resolvedSelectedOption?.value);
	const color = getColorByIndex(index + 1);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const handleOptionSelect = (option: ColorfulSelectOption & TValue) => {
		onSelect?.(option);

		if (closeOnSelect) {
			closeModal();
		}
	};

	const openModal = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		setIsModalOpen(true);
	};

	const button = RootButtonComponent ? (
		<RootButtonComponent onClick={openModal} className={className} selectedOption={resolvedSelectedOption} />
	) : (
		<button data-colorful-select type="button" onClick={openModal} className={clsx(styles.colorfulSelect, className)}>
			<ColorfulBadge {...color} withChevron text={resolvedSelectedOption?.label} />
		</button>
	);

	return (
		<>
			{button}

			<Modal onClose={closeModal} open={isModalOpen} maxWidth="max-content">
				<ModalHeader title={popupHeaderTitle} onXCloseClick={closeModal} className={clsx(popupHeaderClassName)} />

				<ModalBody className={styles.modalBody}>
					<ul className={styles.list}>
						{options.map((option, idx) => {
							return (
								<li key={String(option?.label + idx)} className={styles.listItem}>
									<Checkbox
										checked={resolvedSelectedOption?.label === option?.label}
										onChange={() => {
											handleOptionSelect(option as ColorfulSelectOption & TValue);
										}}
									/>
									<ColorfulBadge {...getColorByIndex(idx + 1)} text={option.label as string} />
								</li>
							);
						})}
					</ul>
				</ModalBody>
			</Modal>
		</>
	);
};

export default ColorfulSelect;
