const servicesList = [
	{ label: 'Автоперевезення', value: '00', type: 'regular' as const },
	{ label: 'Послуги вантажників', value: '11', type: 'regular' as const },
	{ label: 'Послуги порізки металу', value: '22', type: 'regular' as const },
	{ label: 'Тонування штукатурки та фарби', value: '33', type: 'paint_toning' as const },
];

export const useServicesList = () => {
	return servicesList;
};
