import React, { ChangeEvent, useEffect, useState } from 'react';
import { getProgressBarFillColor } from 'utils/shared';

import TableCheckbox from './TableCheckbox';
import { IProps } from './types';

const CheckboxColumn: React.FC<IProps> = ({ row, variant, onToggle }) => {
	const [progress, setProgress] = useState(0);

	const limit = row.original.limit;

	const beforeStyles: React.CSSProperties = {
		content: '',
		background: row.isSelected ? getProgressBarFillColor(progress) : '',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '8px',
		height: '8px',
		borderRadius: '50%',
	};

	useEffect(() => {
		const calculatePercentage = () => {
			setProgress((limit / 40000) * 100);
		};
		calculatePercentage();
	}, [limit]);

	const { indeterminate, checked, onChange } = row.getToggleRowSelectedProps();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked: isChecked } = e.target;

		onChange(e);

		if (onToggle) {
			onToggle(row.original, isChecked);
		}
	};

	const disabled = !!row?.isDisabled;
	const resolvedChecked = disabled ? false : checked;

	return (
		<div style={{ position: 'relative', display: 'flex', justifyContent: 'center', margin: '12px' }}>
			<TableCheckbox
				id={row.id}
				indeterminate={indeterminate}
				checked={resolvedChecked}
				onChange={handleChange}
				variant={variant}
				disabled={disabled}
				style={{
					borderColor: !Number.isNaN(progress) && row.isSelected && getProgressBarFillColor(progress),
				}}
			/>
			{!Number.isNaN(progress) && <span style={beforeStyles} onClick={(e) => row.getToggleRowSelectedProps().onChange(e)}></span>}
		</div>
	);
};

export default CheckboxColumn;
