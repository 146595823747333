import { AutocompleteFieldForClient, AutocompleteFieldWithOnSelectAction } from 'components/FormComponents/Inputs/inputs';
import { loadContractTypesOptionList } from 'components/FormComponents/optionsLoaders';
import { SelectOption } from 'components/Select/types';
import { ROUTES_URLS } from 'const';
import { useAutocompleteOptions } from 'hooks/clients';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { prepareUrl } from 'utils/shared';
import { isNullish, isObject } from 'utils/type-guards';

import styles from './styles.module.css';
import type { IProps } from './types';

const AutocompleteFilters = ({ items }: IProps) => {
	const { clients, organizations, contracts, warehouses, managers } = useAutocompleteOptions();
	const navigate = useNavigate();

	const formFields = useMemo(
		() => [
			{
				name: 'client',
				label: 'Клієнт',
				valuesList: clients,
				onClick: (option: SelectOption) => {
					if (!option) {
						return;
					}
					if (isObject(option) && isNullish(option.value)) {
						return;
					}
					const clientId = option.value;
					const url = prepareUrl(ROUTES_URLS.CLIENT_PAGE, { id: clientId });

					navigate(url);
				},
				forceFocus: true,
				component: AutocompleteFieldForClient,
			},
			{
				name: 'organization',
				label: 'Організація',
				valuesList: organizations,
				component: AutocompleteFieldWithOnSelectAction,
			},
			{
				name: 'contractType',
				label: 'Договір',
				loadAsyncOptions: loadContractTypesOptionList,
				component: AutocompleteFieldWithOnSelectAction,
			},
			{
				name: 'warehouse',
				label: 'Склад',
				valuesList: warehouses,
				onOptionSelect: (value) => alert(`Тут буде запит за сервер за залишками по складу ${value?.label}`),
				component: AutocompleteFieldWithOnSelectAction,
			},
			{
				name: 'manager',
				label: 'Менеджер',
				valuesList: managers,
				component: AutocompleteFieldWithOnSelectAction,
			},
		],
		[clients, organizations, contracts, warehouses, managers],
	);

	const formFieldsItems = items ?? formFields;
	const fieldsCount = formFieldsItems.length;

	return (
		<form className={styles.form} style={{ '--columns': fieldsCount } as React.CSSProperties}>
			{formFieldsItems.map(({ component: Component, ...field }) => (
				<Component key={field.name} {...field} fieldClassName={styles.formField} />
			))}
		</form>
	);
};

export default AutocompleteFilters;
