import clsx from 'clsx';
import ClickAwayListener from 'components/ClickAwayListener';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { MouseEvent, useTransition } from 'react';
import { ReactComponent as PackageCheckIcon } from 'static/images/package-check.svg';
import { ReactComponent as PaintPourIcon } from 'static/images/paint.svg';
import { ReactComponent as ScissorsIcon } from 'static/images/scissors.svg';
import { ReactComponent as TruckIcon } from 'static/images/truck-white.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';
import { ServiceOption } from 'store/reducers/orderViewer/types';

import { useServicesList } from './config';
import SelectorButton from './SelectorButton';
import styles from './styles.module.css';
import type { PopupAppearanceProps, ServiceSelectorsProps } from './types';

const iconMap = [TruckIcon, PackageCheckIcon, ScissorsIcon, PaintPourIcon];

const ServiceSelectors: React.FC<ServiceSelectorsProps> = ({ appearance, onClick, onAddOrder, ...restProps }) => {
	const servicesList = useServicesList();
	const { utils, booleans } = useOrderViewer();

	const [, startTransition] = useTransition();

	const handleOnClick = (service: ServiceOption) => (event: MouseEvent<HTMLButtonElement>) => {
		onAddOrder(service);

		startTransition(() => {
			onClick?.(event);
		});
	};

	const isPopupAppearance = appearance === 'popup';
	const isRegularOrder = utils.isOnMainOrderPage() ? utils.isDraftOrderOfRegularType() : !booleans.isActiveSuborderOfPaintToningType;

	const services = isRegularOrder ? servicesList.slice(0, -1) : servicesList.filter((_, index) => index !== 2);

	const buttonList = services.map((service, index) => {
		return (
			<li key={service.value}>
				<SelectorButton onClick={handleOnClick(service)} label={service.label} icon={iconMap[index]} />
			</li>
		);
	});

	if (utils.hasDraftOrderSavedPaintToningService() && !utils.isDraftOrderOfRegularType()) {
		return null;
	}

	if (!isPopupAppearance) {
		return <ul className={clsx(styles.tablistControls)}>{buttonList}</ul>;
	}

	const { onClose } = restProps as PopupAppearanceProps;

	return (
		<div className={styles.backdrop}>
			<ClickAwayListener onClickAway={onClose}>
				<ul className={clsx(styles.tablistControls, styles.appearancePopup)}>
					{isPopupAppearance && (
						<li className={styles.header}>
							<p>Оберіть послугу</p>

							<button type="button" className={styles.xCloseButton} onClick={onClose}>
								<XCloseIcon />
							</button>
						</li>
					)}
					{buttonList}
				</ul>
			</ClickAwayListener>
		</div>
	);
};

export default ServiceSelectors;
