import React, { type ComponentProps, type ReactNode } from 'react';

import styles from './styles.module.css';

interface IProps extends Omit<ComponentProps<'td'>, 'content'> {
	cell: string;
	content: ReactNode;
}

const MobileReservedProductTableTd: React.FC<IProps> = ({ cell, content, ...restProps }) => {
	return (
		<td className={styles.td} {...restProps}>
			<span data-cell className={styles.thead}>
				{cell}
			</span>
			<span className={styles.contentWrapper}>{content}</span>
		</td>
	);
};

export default MobileReservedProductTableTd;
