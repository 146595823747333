import 'static/styles/style.css';

import clsx from 'clsx';
import Cell from 'components/Table/TableComponents/BasicElements/Cell';
import ExpandedColumn from 'components/Table/TableComponents/BasicElements/ExpandedColumn';
import ExpandedHeader from 'components/Table/TableComponents/BasicElements/ExpandedHeader';
import Row from 'components/Table/TableComponents/BasicElements/Row';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import TableCheckbox from '../../TableComponents/CheckboxColumn/TableCheckbox';
import styles from './style.module.css';
import { IProps } from './types';

const removeSortingButton = (ref: MutableRefObject<HTMLTableRowElement>) => {
	if (ref.current) {
		const btn = ref.current.querySelector('.order-mobile-header-cell [data-sorting-button]');
		btn?.remove();
	}
};

const ServicesMobileTable: React.FC<IProps> = ({ row }) => {
	const [open, setOpen] = useState(false);
	const columnRef = useRef<HTMLTableRowElement>(null);

	useEffect(() => {
		if (columnRef.current && open) {
			removeSortingButton(columnRef);
			columnRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [open]);

	useEffect(() => {
		removeSortingButton(columnRef);
	}, []);

	const collapse = () => {
		setOpen(false);
	};
	const expand = () => {
		setOpen(true);
	};

	const serviceCell = useMemo(() => row.cells?.find(({ column }) => column.id === 'service'), [row.cells]);
	const selectionCell = useMemo(() => row.cells?.find(({ column }) => column.id === 'selection'), [row.cells]);
	const actionCell = useMemo(() => row.cells?.find(({ column }) => column.id === 'action'), [row.cells]);

	if (!open) {
		return (
			<Row gridCustomLayout="52px 1fr auto" bottomBorder="var(--gray-200)" onClick={expand}>
				<td>{selectionCell?.render('Cell')}</td>
				<td>{serviceCell?.render('Cell')}</td>
				<td className="text-sx-medium color-grey-700">{actionCell?.render('Cell')}</td>
			</Row>
		);
	}

	return (
		<ExpandedColumn ref={columnRef} className={styles.table}>
			<ExpandedHeader onClick={collapse} onCollapse={collapse}>
				<span className={styles.headerContent}>
					<TableCheckbox {...row.getToggleRowSelectedProps()} className={styles.checkbox} />
					<span className={styles.title}>{serviceCell?.render('Cell')}</span>
				</span>
			</ExpandedHeader>

			{row.cells
				.filter((cell) => !cell.column.inHeaderOnly || cell.column.skip)
				.map((cell, index) => {
					if (index < 2) return null;

					return (
						<Row
							as="td"
							gridCustomLayout="100px auto"
							key={`${cell.id}${index}`}
							bottomBorder="var(--gray-200)"
							gridTemplate="regular"
							vl="var(--gray-200)"
							highlight="even"
							className={styles.row}
						>
							<Cell as="span" className={clsx('line-clamp', 'order-mobile-header-cell')}>
								{cell.render('Header')}
							</Cell>
							<Cell as="span" className={styles.cell}>
								{cell.render('Cell')}
							</Cell>
						</Row>
					);
				})}
		</ExpandedColumn>
	);
};

export default ServicesMobileTable;
