/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CLIENTS_FILTER_QUERY_KEYS } from 'const';
import { filterClientsByFilters, filterOrdersByClientAndFilters } from 'pages/CreateOrder/mockDataServer';
import { API_URL } from 'services/api';
import { clientsQueryKeys } from 'services/queryKeys';

export const clientsSliceApi = createApi({
	reducerPath: 'clients',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [clientsQueryKeys.clients()],
	endpoints: (builder) => ({
		getClients: builder.query({
			// !TODO: fix this once BE is ready
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: (queryParams: string) => '/clients',
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), ...result.map((client) => clientsQueryKeys.client(client['Id']))] : []),
			transformResponse: (response, _, queryParams) => {
				const searchParams = new URLSearchParams(decodeURI(queryParams));
				if (searchParams.has('query')) {
					searchParams.delete('query');
				}
				Object.keys(CLIENTS_FILTER_QUERY_KEYS).forEach((key) => {
					if (searchParams.has(key)) {
						searchParams.delete(key);
					}
				});

				const queryParamsEntries = Array.from(searchParams.entries());

				return filterClientsByFilters(response as Record<string, string>[], queryParamsEntries);
			},
		}),
		getClientOrders: builder.query({
			// !TODO: fix this once BE is ready
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: ({ id, queryParams }: { id: string; queryParams: string }) => '/orders',
			providesTags: (result, _, { id }) =>
				result
					? [clientsQueryKeys.clients(), clientsQueryKeys.client(id), ...result.map((orders) => clientsQueryKeys.client(orders.id))]
					: [],
			transformResponse: (response, _, { id, queryParams }) => {
				const searchParams = new URLSearchParams(decodeURI(queryParams));
				const queryParamsEntries = Array.from(searchParams.entries());

				return filterOrdersByClientAndFilters(id, response as Record<string, string>[], queryParamsEntries);
			},
		}),
		getSegments: builder.query({
			query: () => '/contractor-segments',
		}),
		getContractsOptions: builder.query({
			query: () => '/contracts',
			transformResponse: (response) => {
				const unique = [];

				(response as Record<string, string>[]).forEach((contract) => {
					const isDuplicate = unique.includes(contract['Наименование']);

					if (!isDuplicate && !!contract['Наименование']) {
						unique.push(contract['Наименование']);

						return true;
					}

					return false;
				});

				return unique;
			},
		}),
		getClientsOptions: builder.query<unknown, string>({
			query: () => '/clients',
			transformResponse: (response) => {
				return (response as Record<string, string>[]).map((client) => ({
					label: client['ФІО'] || client['Наименование'],
					value: client['Id'],
				}));
			},
		}),
		getClientById: builder.query<unknown, string>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: (id) => '/clients/',
			providesTags: (_, __, id) => [clientsQueryKeys.client(id)],
			transformResponse: (response, _, id) => {
				return (response as Record<string, string>[]).filter((client) => client['Id'] === id);
			},
		}),
		getContracts: builder.query<unknown, string>({
			query: () => '/contracts',
			providesTags: () => [clientsQueryKeys.client('CONTRACTS')],
			transformResponse: (response) =>
				(response as Record<string, string>[]).map((contract) => ({
					label: contract['Наименование'],
					value: contract['Id'],
					clientId: contract['Контрагент'],
				})),
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetContractsQuery,
	useGetSegmentsQuery,
	useGetContractsOptionsQuery,
	useGetClientsOptionsQuery,
	useGetClientOrdersQuery,
} = clientsSliceApi;
