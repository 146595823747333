import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { uuid } from 'utils/shared';
import { isFunction, isObject } from 'utils/type-guards';

import styles from './styles.module.css';
import type { IActionSlot, IProps } from './types';

const emptyObject = {};

const FormField = <TComponentProps, TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	actionSlot = emptyObject,
	shouldUnregister = true,
	fieldClassName,
	defaultValue,
	children,
	type = 'text',
	component,
	control,
	label,
	name,
	hiddenLabel,
	hint,
	transform,
	renderLabel,
	...restProps
}: TComponentProps & IProps & UseControllerProps<TFieldValues, TName>) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const controller = useController({ name, defaultValue, shouldUnregister, control });
	const {
		field,
		fieldState: { error },
	} = controller;
	const { value, onChange, ...restField } = field;
	const resolvedName = !!hiddenLabel ? uuid() : name;

	const componentElement = isFunction(component) ? component({ ...field, ...restProps, name: resolvedName }) : null;
	const Component = component;
	const hasError = !!error?.message;

	const actionSlotComponent =
		isObject<IActionSlot>(actionSlot) && 'content' in actionSlot ? (
			<button type="button" onClick={actionSlot?.onActionPerform} className={styles.actionSlot}>
				{actionSlot.content}
			</button>
		) : Object.keys(actionSlot ?? {}).length === 0 ? null : (
			(actionSlot as React.ReactNode)
		);

	const handleOnChange = (newValue: unknown) => {
		const transformedValue = transform ? transform(newValue) : newValue;

		onChange(transformedValue);
	};

	useEffect(() => {
		if (wrapperRef.current && actionSlotComponent) {
			const actionSlotNodeElement = wrapperRef.current.lastChild as HTMLElement;
			const { width } = actionSlotNodeElement.getBoundingClientRect();
			const paddingOffset = 20;
			const ceiledWidth = Math.ceil(width) + paddingOffset;

			wrapperRef.current.style.setProperty('--input-pr', `${ceiledWidth}px`);
		}
		return () => {
			if (wrapperRef.current) {
				wrapperRef.current?.style.removeProperty('--input-pr');
			}
		};
	}, []);

	const labelClassNames = clsx(styles.label, 'text-sm-medium', { 'visually-hidden': !!hiddenLabel });

	const labelEl = (
		<label htmlFor={resolvedName} className={labelClassNames}>
			{label}
		</label>
	);

	return (
		<div data-form-field-wrapper className={clsx(styles.inputWrapper, fieldClassName)}>
			{!!label && !!!renderLabel && labelEl}
			{!!renderLabel && renderLabel(labelClassNames)}

			<div ref={wrapperRef} className={styles.inputInnerWrapper}>
				{componentElement || (
					<Component
						id={resolvedName}
						value={value}
						setValue={handleOnChange}
						type={type}
						{...restField}
						{...restProps}
						name={resolvedName}
					/>
				)}

				{children}

				{actionSlotComponent}

				{hasError && <strong className={clsx('error-text', styles.errorMessage)}>{error.message}</strong>}
				{!!hint && !error && <strong className={clsx('text-sm-regular', styles.hint)}>{hint}</strong>}
			</div>
		</div>
	);
};

export default FormField;
