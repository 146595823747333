import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ProductFilterNode } from 'components/OrderCreatePageComponents/ProductFilter/types';
import type { SelectOption } from 'components/Select/types';
import { PRICE_CHANGE_OPTIONS } from 'mock/priceChange';
import type { PriceChangeDTO, PriceChangeOption } from 'models/IPriceChange';
import { filterOrdersByFilters, sortTreeAlphabetically } from 'pages/CreateOrder/mockDataServer';
import { colorantData } from 'pages/OrderServices/mockData';
import {
	loadMetalCuttingServiceTypeOptions,
	loadMetalCuttingServiceWorkTypeOptions,
	loadPaintBaseToneOptions,
	loadPaintCollectionOptions,
} from 'pages/OrderServices/optionLoaders';
import { API_URL } from 'services/api';
import { clientsQueryKeys, orderQueryKeys } from 'services/queryKeys';
import { uuid } from 'utils/shared';

import { clientsSliceApi } from '../clients/clientsSliceApi';
import { Product } from './types';

export const ordersSliceApi = createApi({
	reducerPath: 'orders',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [orderQueryKeys.orders()],
	endpoints: (builder) => ({
		getOrders: builder.query({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: (queryParams?: string) => '/orders',
			providesTags: (result = []) => [orderQueryKeys.ordersOnly(), ...result.map(({ id }) => orderQueryKeys.order(id))],
			transformResponse: (response, arg, queryParams) => {
				const searchParams = new URLSearchParams(queryParams);

				// !TEMP demo only ========
				if (searchParams.has('query')) {
					searchParams.delete('query');
				}
				if (searchParams.has('client')) {
					searchParams.delete('client');
				}
				if (searchParams.has('order')) {
					searchParams.delete('order');
				}
				if (searchParams.has('pending')) {
					searchParams.delete('pending');
				}
				if (searchParams.has('unpaid')) {
					searchParams.delete('unpaid');
				}
				if (searchParams.has('late')) {
					searchParams.delete('late');
				}
				// !========= TEMP demo only

				const queryParamsEntries = Array.from(searchParams.entries());
				const data = response as Record<string, string>[];
				const filteredOrders = filterOrdersByFilters(data, queryParamsEntries);

				return [...filteredOrders].reverse();
			},
		}),
		getPriceChangeOptions: builder.query<PriceChangeOption[], void>({
			queryFn: () => {
				return new Promise((res) => {
					setTimeout(() => {
						res({ data: PRICE_CHANGE_OPTIONS });
					}, 500);
				});
			},
		}),
		changeProductsPrice: builder.mutation<void, PriceChangeDTO>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			queryFn: (dto) =>
				new Promise((res) => {
					setTimeout(() => {
						res({ data: null });
					}, 2500);
				}),
			invalidatesTags: (_, __, order) => [orderQueryKeys.ordersOnly(), orderQueryKeys.order(order.orderId)],
		}),
		// !MAYBE TEMP
		getOrdersTotalCount: builder.query({
			query: () => '/orders',
			providesTags: [orderQueryKeys.ordersOnly()],
			transformResponse: (response) => (response as Record<string, string>[])?.length ?? 0,
		}),
		createOrder: builder.mutation({
			query: (body) => ({
				url: '/orders',
				method: 'POST',
				body,
			}),
		}),
		getOrderById: builder.query<unknown, string>({
			query: (id) => `/orders/${id}`,
			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		deleteOrder: builder.mutation({
			query: (id: string) => ({
				url: `/orders/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				const optimisticOrderDelete = dispatch(
					ordersSliceApi.util.updateQueryData('getOrderById', String(id), (draft) => {
						Object.assign(draft ?? {}, {});
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderDelete.undo();
				}
			},
		}),
		updateOrder: builder.mutation({
			query: (order) => ({
				url: `/orders/${order.id}`,
				method: 'PUT',
				body: order,
			}),
			invalidatesTags: (order) => [orderQueryKeys.ordersOnly(), orderQueryKeys.order(order?.id)],
			async onQueryStarted(order, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					ordersSliceApi.util.updateQueryData('getOrderById', String(order.id), (draft) => {
						Object.assign(draft ?? {}, order);
					}),
				);
				try {
					await queryFulfilled;
					dispatch(clientsSliceApi.util.invalidateTags([clientsQueryKeys.client(order?.client?.id)]));
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		getProductFilters: builder.query({
			query: () => '/product-filters',
			transformResponse: (filters) => {
				const brandFiltersCopy = structuredClone({
					...filters[0],
					children: filters[0].children.map((item) => ({ name: item, id: uuid(), value: item })),
				}) as ProductFilterNode;

				const categoryFilters = sortTreeAlphabetically(filters[1]);

				return [brandFiltersCopy, categoryFilters];
			},
		}),
		getOrderStatusList: builder.query({
			query: () => '/order-statuses',
		}),
		getPriceTypeList: builder.query({
			query: () => '/price-types',
			// !TEMP - don't need last price type
			transformResponse: (prices) => {
				if (Array.isArray(prices)) {
					return prices.slice(0, prices.length - 1);
				}

				return prices as [];
			},
		}),
		// ! TEMP
		getServicesOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async (endpoint: string) => {
				try {
					if (endpoint !== '') {
						const options = await loadMetalCuttingServiceWorkTypeOptions(endpoint);

						return { data: options };
					}

					const options = await loadMetalCuttingServiceTypeOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getPaintCollectionOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = await loadPaintCollectionOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getPaintBaseToneOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = await loadPaintBaseToneOptions();

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		calculateColorant: builder.mutation<Product[], Record<string, unknown>>({
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			queryFn: async (dto) => {
				try {
					const fakeRequest = () =>
						new Promise((res) => {
							setTimeout(() => res(colorantData), 1500);
						});

					const response = await fakeRequest();

					return { data: response };
				} catch (error) {
					return { error };
				}
			},
		}),
	}),
});

export const {
	useGetOrderByIdQuery,
	useCreateOrderMutation,
	useGetOrdersQuery,
	useUpdateOrderMutation,
	useGetProductFiltersQuery,
	useDeleteOrderMutation,
	useGetOrderStatusListQuery,
	useGetOrdersTotalCountQuery,
	useGetPriceTypeListQuery,
	useCalculateColorantMutation,
	useGetServicesOptionListQuery,
	useGetPriceChangeOptionsQuery,
	useChangeProductsPriceMutation,
	useGetPaintBaseToneOptionListQuery,
	useGetPaintCollectionOptionListQuery,
} = ordersSliceApi;
