import clsx from 'clsx';
import Spinner from 'components/Spinner';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const ServiceDeleteButton: React.FC<IProps> = ({ item, className }) => {
	const { activeSuborder, draftOrder, api, utils } = useOrderViewer();
	const source = utils.isOnMainOrderPage() ? draftOrder : activeSuborder;

	const handleServiceDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		source.deleteItemsSafe([item]);
	};

	return (
		<>
			<button type="button" onClick={handleServiceDelete} className={clsx(styles.button, className)}>
				<TrashIcon className={styles.icon} />
			</button>

			{api.isProcessing && <Spinner />}
		</>
	);
};

export default ServiceDeleteButton;
