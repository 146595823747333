import clsx from 'clsx';
import React, { useRef } from 'react';
import { ReactComponent as ArrowDownIcon } from 'static/images/arrow-down.svg';
import { ReactComponent as MinusIcon } from 'static/images/minus.svg';

import styles from './styles.module.css';
import type { SortingButtonProps } from './types';

const SortingButton = ({ column, withIndeterminate = false, disabled = false }: SortingButtonProps) => {
	const toggleSortingOrder = () => column.toggleSortBy(!column.isSortedDesc);
	const clickCounts = useRef<number>(0);
	const isIndeterminate = clickCounts.current === 2;

	const handleToggleSortingOrder = () => {
		if (withIndeterminate) {
			if (clickCounts.current === 2) {
				clickCounts.current = 0;

				return column.clearSortBy();
			}

			clickCounts.current = clickCounts.current + 1;
			toggleSortingOrder();
		} else {
			toggleSortingOrder();
		}
	};

	return (
		<button data-sorting-button type="button" disabled={disabled} onClick={handleToggleSortingOrder} className={clsx(styles.sortableBtn)}>
			{isIndeterminate && <MinusIcon />}
			{!isIndeterminate && <ArrowDownIcon className={clsx({ [styles.rotate]: column.isSortedDesc })} />}
		</button>
	);
};

export default SortingButton;
