import clsx from 'clsx';
import Clamp from 'components/Clamp';
import React, { MouseEvent } from 'react';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const QueuedFilterItem: React.FC<IProps> = ({
	className,
	onClick,
	onUnselect,
	isActive = false,
	disabled = false,
	title,
	icon: Icon,
	...restProps
}) => {
	const handleItemClick = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		onClick?.(e);
	};
	const handleUnselectClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		onUnselect?.(e);
	};
	return (
		<div
			className={clsx(styles.queuedItemWrapper, className, { [styles.active]: isActive, [styles.disabled]: disabled })}
			onClick={handleItemClick}
			{...restProps}
		>
			<div className={styles.contentAndIconWrapper}>
				<Icon />

				<Clamp as="p" lines={2} className={clsx('color-grey-700', 'text-md-regular')}>
					{title}
				</Clamp>
			</div>

			{isActive && (
				<button className={styles.button} type="button" onClick={handleUnselectClick}>
					<XCloseIcon />
				</button>
			)}
		</div>
	);
};

export default QueuedFilterItem;
