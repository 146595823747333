import ColorantPanel from 'components/ColorantPanel';
import { validateColorantCalculationStepSearchParams } from 'components/PaintToningPageComponents/lib/validation';
import Spinner from 'components/Spinner';
import { ROUTES_URLS } from 'const';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCalculateColorantMutation } from 'store/reducers/orders/ordersSliceApi';
import { prepareUrl } from 'utils/shared';
import { isObject } from 'utils/type-guards';

const CalculateColorant: React.FC = () => {
	const navigate = useNavigate();
	const [mutate, { isLoading }] = useCalculateColorantMutation();

	const { paintToning, draftOrder, utils } = useOrderViewer();
	const source = utils.isOnMainOrderPage() ? draftOrder.original : paintToning.suborder;

	// !TEMP
	const dto = source;

	const handleManualColorantComputation = () => {
		if (isObject(dto.services)) {
			const [service] = utils.toArray<Record<string, unknown>>(dto.services);

			// !!! TEMP
			const product = source.products?.find((item) => !item.title.toLowerCase().includes('пігмент'));

			const manualCalculationDto = {
				collection: service.collection,
				base: service.baseTone,
				palette: service.colorCode,
				brand: product?.brand,
				code: 'ERT-23',
				product: product.title,
			};
			const result = validateColorantCalculationStepSearchParams(manualCalculationDto);

			if (!result.success) {
				return toast.error('Невідома помилка. Неможливо розрахувати вручну');
			}

			const searchParams = new URLSearchParams(Object.entries(manualCalculationDto));
			searchParams.set('step', 'calculate-colorants');

			const url = prepareUrl(ROUTES_URLS.PAINT_TONING) + '?' + searchParams.toString();

			navigate(url);
		}
	};
	const handleAutoColorantComputation = async () => {
		await mutate(dto).unwrap();
	};

	return (
		<>
			<ColorantPanel disabled={isLoading} onManualCompute={handleManualColorantComputation} onAutoCompute={handleAutoColorantComputation} />

			{isLoading && <Spinner />}
		</>
	);
};

export default CalculateColorant;
