import { ROUTES_URLS } from 'const';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import type { IService } from 'models/IServices';
import { orderNotificationService } from 'services/orderNotificationService';
import { Product } from 'store/reducers/orders/types';
import { SuborderTransferConfigWithData } from 'store/reducers/orderViewer/types';
import { prepareUrl } from 'utils/shared';

import type { TransferSafePayload, UseBusinessLogicDI } from '../types';
import { useDispatcher } from './_useDispatcher';
import { useSource } from './_useSource';
import { useUtils } from './_useUtils';

// === ** GLOSSARY ** == //
// ?? 'safe' suffix means method opens alert dialogue that warns user about the further action, e.g deleteSafe() - alert dialogue first
// ?? 'unsafe' suffix means method performs action without any warnings and alerts, e.g deleteUnsafe() - executes immediate

export const useBusinessLogic = ({ mutate }: UseBusinessLogicDI) => {
	const data = useSource();
	const utils = useUtils();
	const dispatcher = useDispatcher();
	const alertDialogue = useOrderAlertDialogue();
	/*
	 * appends new suborder tab
	 */
	const appendNewTab = () => {
		if (data.booleans.hasReachedAllSubordersLimit) {
			return;
		}

		const hasSelectedProducts = data.activeSuborder.selectedProducts.length > 0;

		if (hasSelectedProducts) {
			dispatcher.suborders.createSuborderWithProducts(data.activeSuborder.index);
			orderNotificationService.transferred(data.allSuborders.length);
		} else {
			dispatcher.suborders.createBlankSuborder();
		}
	};

	/*
	 * deletes in-memory suborder only 'cause there is no logic for delete suborders saved on server !!
	 */
	const deleteSuborderUnsafe = (id: string, index: number) => {
		dispatcher.suborders.deleteInMemorySuborder(id, index);
	};

	/*
	 * deletes in-memory stored suborder with alert dialogue opened
	 */
	const deleteSuborderSafe = (index: number) => {
		const suborder = data.allSuborders[index];

		if (utils.wasSuborderSavedOnServer(suborder)) {
			// we can not remove suborders already saved on server
			// so we skip
			return;
		}

		if (utils.isSuborderEmpty(suborder)) {
			// in case suborder is empty, we do not need to show alert dialogue
			// because nothing has to be safely removed
			return deleteSuborderUnsafe(suborder.tempId, index);
		}

		// in case suborder do has any of products or services added in memory
		// we want ro show alert dialogue and warn user that everything gonna be deleted
		dispatcher.suborders.setIndexOfSuborderToBeDeleted(index);
		alertDialogue.open('deleteInMemorySuborder');
	};

	/*
	 * clears state and closes alert dialogue on delete cancelation
	 */
	const abortSuborderTabDelete = () => {
		dispatcher.suborders.clearIndexOfSuborderToBeDeleted();
		alertDialogue.close();
	};

	/*
	 * performs navigation between suborders in splitting mode
	 */
	const switchTab = (index: number) => {
		dispatcher.suborders.setActiveSuborderIndex(index);
	};

	/*
	 * transfers items safely and accepts payload 'cause not always selected items are internally stored
	 */
	const transferSafe = ({ index, maybeServices, dialogue: transferDialogue }: TransferSafePayload) => {
		const sourceIndex = data.activeSuborder.index;
		const targetIndex = index;

		/*
		 * isOnProductsTabInActiveSuborder - means we currently working with products and they are stored internally
		 */
		if (data.booleans.isOnProductsTabInActiveSuborder) {
			const sourceSuborder = data.activeSuborder.original;
			const targetSuborder = data.allSuborders[targetIndex];

			/*
			 * case 1 - we are on ROOT suborder tab and DO NOT HAVE any created or saved SUBORDERS yet
			 * in this case we want to create a new suborder and transfer to it selected items
			 */
			if (!targetSuborder && data.booleans.isOnRootOrderTab) {
				dispatcher.suborders.createSuborderWithProducts(sourceIndex);
				return orderNotificationService.transferred(data.allSuborders.length);
			}

			/*
			 * case 2 - we are on ROOT suborder tab and HAVE already created or saved SUBORDERS
			 * in this case we want to check type of suborder before transferring
			 * because to regular products (not paint) can be transferred only to paint toning suborder
			 */
			if (targetSuborder && data.booleans.isOnRootOrderTab) {
				const isTargetAPaintToningSuborder = utils.isPaintToningSuborder(targetIndex);
				const paintToningSuborderIndex = utils.getPaintToningSuborderIndex();

				/*
				 * case 2.1 - first created suborder was a paint toning one
				 * and we target regular suborder. So we 'omit' paint toning suborder which has 1 index ( 0 - is root)
				 */
				if (isTargetAPaintToningSuborder) {
					dispatcher.suborders.createSuborderWithProducts(sourceIndex);
					return orderNotificationService.transferred(targetIndex);
				}

				/*
				 * case 2.2 - extra checking to be sure that we have toning suborder as first of suborder (except root) and do not have a regular one
				 */
				if (isTargetAPaintToningSuborder && !data.allSuborders[paintToningSuborderIndex + 1]) {
					dispatcher.suborders.createSuborderWithProducts(sourceIndex);
					return orderNotificationService.transferred(targetIndex);
				}

				/*
				 * case 2.3 perform standard transfer with suborder creation
				 */
				dispatcher.transferring.transferProducts({ from: sourceIndex, to: targetIndex });
				return orderNotificationService.transferred(targetIndex);
			}

			/*
			 * case 3 - we have neither TARGET suborder or we are NOT on ROOT tab
			 * just create a new blank suborder and perform transfer between suborders
			 */
			if (!targetSuborder && !data.booleans.isOnRootOrderTab) {
				return transferDialogue.open(targetIndex);
			}

			/*
			 * case 4 - we have TARGET suborder and we are NOT on ROOT tab
			 * perform transfer between suborders
			 */
			if (targetSuborder && !data.booleans.isOnRootOrderTab) {
				return transferDialogue.open(targetIndex);
			}

			/*
			 * case 5 - we are trying to transfer products to paint toning suborder
			 * we validate items to be sure we can pass the to paint toning suborder
			 */
			const result = utils.validateRegularProducts(sourceSuborder.selectedProducts);

			if (!result.success) {
				return alertDialogue.open('invalidPaintToningProducts');
			}

			dispatcher.transferring.transferProducts({ from: sourceIndex, to: targetIndex });
			orderNotificationService.transferred(targetIndex);
		} else {
			/*
			 * now we are on services tab
			 */
			const targetSuborder = data.allSuborders[targetIndex];

			/**
			 * case 1 - NO TARGET suborder, and we ARE ON the ROOT order tab
			 * create a new suborder with services from the source index
			 * trigger a notification indicating that services have been transferred
			 */
			if (!targetSuborder && data.booleans.isOnRootOrderTab) {
				dispatcher.suborders.createSuborderWithServices(maybeServices, sourceIndex);

				return orderNotificationService.transferredServices(targetIndex);
			}

			/**
			 * case 2 - NO TARGET suborder, and we are NOT on the ROOT order tab
			 * create a blank suborder
			 * open the transfer and perform transfer items between suborder
			 */
			if (!targetSuborder && !data.booleans.isOnRootOrderTab) {
				dispatcher.suborders.createBlankSuborder();

				return transferDialogue.openService(targetIndex);
			}

			/**
			 * case 3 - HAVE TARGET suborder, and we are NOT on the ROOT order tab
			 * open the transfer dialogue and perform transfer items between suborder
			 */
			if (targetSuborder && !data.booleans.isOnRootOrderTab) {
				return transferDialogue.openService(targetIndex);
			}

			/**
			 * case - 4: BOTH target and source suborders exist
			 * just perform transfer
			 */
			dispatcher.transferring.transferServices({
				from: sourceIndex,
				to: targetIndex,
				data: maybeServices,
			});
			return orderNotificationService.transferredServices(targetIndex);
		}
	};

	/*
	 * deletes selected items
	 * if products are being deleted that means we take candidates from internal store
	 * otherwise services are being deleted and they are coming from outside
	 */
	const deleteSuborderItemsSafe = (services?: IService[]) => {
		if (data.booleans.isOnProductsTabInActiveSuborder) {
			dispatcher.activeSuborder.setDeleteCandidates(data.activeSuborder.selectedProducts);
			alertDialogue.open('deleteProducts');
		} else {
			dispatcher.activeSuborder.setDeleteCandidates(services ?? []);
			alertDialogue.open('deleteServices');
		}
	};

	/*
	 * deletes items unsafe including deleting from server
	 */
	const deleteItemsInSuborderUnsafe = async () => {
		const candidates = data.activeSuborder.deleteItemsCandidates;

		if (data.booleans.isOnProductsTabInActiveSuborder) {
			const rootOrderProducts = data.rootSuborder.products;

			const candidatesIds = utils.constructNewIdSet(candidates as Product[]);
			const keepProducts = rootOrderProducts.filter((product) => !candidatesIds.has(String(product.id)));

			return mutate({ ...data.etalonOrder.original, products: keepProducts })
				.unwrap()
				.then(() => orderNotificationService.success('Товари видалено!'))
				.catch(() => orderNotificationService.error('Виникла помилка. Товари не видалено!'));
		} else {
			const rootOrderServices = data.rootSuborder.services;

			if (!utils.wereServicesSavedOnServer(candidates as IService[])) {
				dispatcher.activeSuborder.deleteManyServices(candidates as IService[]);
			} else {
				const candidatesIds = utils.constructNewIdSet(candidates as IService[]);

				const keepServices = utils.toArray<IService>(rootOrderServices).filter((service) => !candidatesIds.has(String(service.id)));
				const keepServicesInSuborder = utils
					.toArray<IService>(data.activeSuborder.services)
					.filter((service) => !candidatesIds.has(String(service.id)));

				const resolvedIndex = data.activeSuborder.index - 1; // we should not include root suborders that is why we reduce index by one

				const writableSuborders = [...data.etalonOrder.suborders];
				const targetSuborder = writableSuborders[resolvedIndex];

				writableSuborders[resolvedIndex] = { ...targetSuborder, services: keepServicesInSuborder };

				const suborders = writableSuborders;

				return mutate({ ...data.etalonOrder.original, services: keepServices, suborders })
					.unwrap()
					.then(() => {
						dispatcher.activeSuborder.deleteManyServices(candidates as IService[]);
						orderNotificationService.success('Послуги видалено!');
					})
					.catch(() => orderNotificationService.error('Виникла помилка. Послуги не видалено!'));
			}
		}
	};

	/*
	 * closes alert dialogue and reset selected candidates fro deletion
	 */
	const abortActiveSuborderItemsDelete = () => {
		alertDialogue.close();
		dispatcher.activeSuborder.clearDeleteCandidates();
	};

	/*
	 * deletes selected items in draft order
	 * in draft order we track selected items internally
	 * so we need to get them from outside and pass to alert dialogue
	 * to be able to delete them on proceed
	 */
	const deleteDraftOrderItemsSafe = (items?: Product[] | IService[]) => {
		if (utils.isOnMainOrderPage()) {
			if (utils.isOnProductsSubPage()) {
				alertDialogue.open('deleteMainOrderProducts', items);
			} else if (utils.isOnServicesSubPage()) {
				alertDialogue.open('deleteMainOrderServices', items);
			}
		}
	};

	/*
	 * deletes selected items in draft order
	 * in draft order we track selected items internally
	 * so we need to get them from outside and pass to alert dialogue
	 * to be able to delete them on proceed
	 */
	const deleteDraftOrderItemsUnsafe = (items: Product[] | IService[]) => {
		const { products, services } = data.draftOrder;

		if (utils.isOnProductsSubPage()) {
			const candidatesIds = utils.constructNewIdSet(items as Product[]);

			const filtered = products.filter((product: Product) => !candidatesIds.has(String(product.id)));
			const dto = utils.getDraftOrderDTO();

			dto.products = filtered;

			return mutate(dto)
				.unwrap()
				.then(() => orderNotificationService.success('Товари видалено!'))
				.catch(() => orderNotificationService.error('Виникла помилка. Товари не видалено!'));
		} else if (utils.isOnServicesSubPage()) {
			if (!utils.wereServicesSavedOnServer(items as IService[])) {
				dispatcher.draftOrder.deleteServicesUnsafe(items as IService[]);
			} else {
				const candidatesIds = utils.constructNewIdSet(items as IService[]);

				const filtered = services.filter((service: IService) => !candidatesIds.has(String(service.id)));
				const dto = utils.getDraftOrderDTO();

				dto.services = filtered;

				return mutate(dto)
					.unwrap()
					.then(() => orderNotificationService.success('Послуги видалено!'))
					.catch(() => orderNotificationService.error('Виникла помилка. Послуги не видалено!'));
			}
		}
	};

	/*
	 * creates a specific suborder - paint-toning suborder where you can add only items with colorants
	 */
	const createPaintToningSuborder = () => {
		if (data.booleans.hasReachedPainToningSubordersLimit) {
			const index = utils.getPaintToningSuborderIndex();

			if (data.booleans.isOnRootOrderTab) {
				if (data.booleans.isOnProductsTabInActiveSuborder) {
					dispatcher.transferring.transferProducts({ from: 0, to: index });
				}
			}
		} else {
			const candidates = data.activeSuborder.selectedProducts;
			const hasSelectedCandidates = candidates.length > 0;

			if (data.booleans.isOnProductsTabInActiveSuborder) {
				if (hasSelectedCandidates) {
					const result = utils.validatePaintToningProducts(candidates);

					if (!result.success) {
						alertDialogue.open('invalidPaintToningProducts');
					} else {
						dispatcher.suborders.createSuborderWithProducts(data.activeSuborder.index, 'paint_toning');
						orderNotificationService.success('Заявку на тонування створено');
					}
				} else {
					dispatcher.suborders.createBlankSuborder({ type: 'paint_toning' });
				}
			}
		}
	};

	/*
	 * toggles draft order type
	 */
	const toggleOrderTypeSafe = () => {
		alertDialogue.open('changeOrderType');
	};

	/*
	 * saves draft order
	 */
	const saveDraftOrderSafe = () => {
		alertDialogue.open('saveMainOrder');
	};
	/*
	 * toggle draft order status
	 */
	const reserveDraftOrderStatusSafe = () => {
		alertDialogue.open('mainOrderReservation');
	};

	/*
	 * before transfer products between suborders of same level (1 -> 2, 2 -> 1)
	 * we are to check if target suborder exists
	 * if it doesn't, than create and transfer
	 */
	const transferProductsWithData = (params: SuborderTransferConfigWithData) => {
		if (!utils.hasTargetSuborder(params.to) || utils.isPaintToningSuborder(params.to)) {
			dispatcher.suborders.createBlankSuborder();
		}

		dispatcher.transferring.transferProductsWithData(params);
	};

	/*
	 * before transfer services between suborders of same level (1 -> 2, 2 -> 1)
	 * we are to check if target suborder exists
	 * if it doesn't, than create and transfer
	 */
	const transferServicesWithData = (params: SuborderTransferConfigWithData) => {
		if (!utils.hasTargetSuborder(params.to) || utils.isPaintToningSuborder(params.to)) {
			dispatcher.suborders.createBlankSuborder({ type: 'paint_toning' });
		}

		dispatcher.transferring.transferServices(params);
	};

	/*
	 * exits splitting mode safely with alert dialogue opened
	 */
	const exitSplittingSafe = () => {
		alertDialogue.open('cancelSplitting');
	};
	/*
	 * exits splitting mode and navigates to the root order resetting all unsaved changes
	 */
	const exitSplittingUnsafe = () => {
		const orderId = data.etalonOrder?.original?.id;

		if (!orderId) {
			return;
		}

		// !! Todo find way to use navigate from useNavigate hook - for now it gives error
		window.location.href = prepareUrl(ROUTES_URLS.ORDER, { id: orderId });
	};

	return {
		switchTab,
		transferSafe,
		appendNewTab,
		saveDraftOrderSafe,
		deleteSuborderSafe,
		toggleOrderTypeSafe,
		deleteSuborderUnsafe,
		abortSuborderTabDelete,
		deleteSuborderItemsSafe,
		deleteDraftOrderItemsSafe,
		createPaintToningSuborder,
		deleteDraftOrderItemsUnsafe,
		deleteItemsInSuborderUnsafe,
		abortActiveSuborderItemsDelete,
		reserveDraftOrderStatusSafe,
		transferProductsWithData,
		transferServicesWithData,
		closeCurrentAlertDialogue: alertDialogue.close,
		exitSplittingUnsafe,
		exitSplittingSafe,
	};
};
