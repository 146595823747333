import clsx from 'clsx';
import Spinner from 'components/Spinner';
import TableCheckbox from 'components/Table/TableComponents/CheckboxColumn/TableCheckbox';
import { useModal } from 'hooks/useModal';
import { useOrderCart } from 'hooks/useOrderCart';
import React, { ChangeEvent, lazy, MouseEvent, Suspense, useEffect, useRef } from 'react';
import { ReactComponent as SquaredCheckIcon } from 'static/images/check-square.svg';
import { ReactComponent as CircledPlusIcon } from 'static/images/plus-circle.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';
import { Product } from 'store/reducers/orders/types';
import { pluralizeProductCount } from 'utils/shared';

import styles from './styles.module.css';
import type { ITableActionsPanelProps } from './types';

const BulkProductSelection = lazy(() => import('../BulkProductSelection'));
const ProductConfigurationToolModal = lazy(() => import('../ProductConfigurationToolModal'));

const TableActionsPanel: React.FC<ITableActionsPanelProps> = ({ allData, className, getToggleSelectionProps, onReset }) => {
	const cart = useOrderCart();
	const timeoutId = useRef<NodeJS.Timeout>(null);

	const { indeterminate, checked, onChange } = getToggleSelectionProps?.() ?? {};

	const saveOrder = (product: Product) => {
		cart.updateProductQuantityByIdInCart(String(product.id), product.pickedAmount);
		cart.clearMaybeSelectOne();
	};
	const onRowClick = (closeCb: Function) => (rowIndex: number, quantity: number | string, id: string) => {
		if (rowIndex === 0) {
			cart.updateProductQuantityInCart(quantity);
		} else {
			cart.updateProductQuantityByIdInCart(String(id), quantity);
			closeCb();

			// for smoother modal close we need delay cleanup in redux store
			timeoutId.current = setTimeout(() => {
				cart.clearMaybeSelectOne();
			}, 2);
		}
	};

	useEffect(() => {
		return () => {
			if (timeoutId.current !== null) {
				clearTimeout(timeoutId.current);
			}
		};
	}, []);

	const selectedItemsCount = cart.products.length;

	const productConfigurationToolModal = useModal({
		modal: ({ closeModal }) => {
			return (
				<Suspense fallback={<Spinner />}>
					<ProductConfigurationToolModal
						stackable
						open
						mode="creation"
						isLoading={cart.isLoading}
						onSave={saveOrder}
						onRowClick={onRowClick(closeModal)}
						onClose={closeModal}
					/>
				</Suspense>
			);
		},
		key: 'modal:bulk-action/product-preview',
	});

	const bulkSelectionModal = useModal({
		modal: ({ closeModal }) => {
			return (
				<Suspense fallback={<Spinner />}>
					<BulkProductSelection stackable onClick={productConfigurationToolModal.openModal} onCancel={closeModal} onClose={closeModal} />
				</Suspense>
			);
		},
		key: 'modal:bulk-action/select-products',
	});

	const handleCancel = () => {
		onChange({ target: { checked: false } });
		cart.clearMaybeSelectedAll();
		onReset?.();
	};
	const handleAllSelection = () => {
		onChange({ target: { checked: true } });
		cart.maybeSelectMany(allData);
	};

	const handleToggleAllSelection = (event: ChangeEvent<HTMLInputElement>) => {
		const { checked: isChecked } = event.target;

		onChange(event);

		if (isChecked) {
			cart.maybeSelectMany(allData);
		} else {
			cart.clearMaybeSelectedAll();
		}
	};

	const openModal = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		bulkSelectionModal.openModal();
	};

	if (!selectedItemsCount) return null;

	return (
		<div className={clsx(styles.wrapper, className)}>
			<div className={clsx(styles.tableActionsPanel)}>
				<TableCheckbox
					id="bulk-actions:toggle/all"
					indeterminate={indeterminate}
					checked={checked}
					onChange={handleToggleAllSelection}
					className={styles.mainCheckbox}
				/>

				<div className={styles.actionButtonsContainer}>
					<button type="button" onClick={openModal} className={styles.actionButton}>
						<CircledPlusIcon />
					</button>
				</div>

				<strong className={clsx(styles.itemsCount, 'text-sx-medium')}>
					<span>Вибрано</span>&nbsp;
					<span className={styles.itemsCountNumber}>{pluralizeProductCount(selectedItemsCount)}</span>
				</strong>

				<button
					type="button"
					onClick={handleAllSelection}
					className={clsx(styles.extraActionButton, styles.withState, styles.selectAll, 'text-sm-semibold')}
				>
					<SquaredCheckIcon />
					<span>Вибрати всі</span>
				</button>

				<button type="button" onClick={handleCancel} className={clsx(styles.extraActionButton, styles.cancel, 'text-sm-semibold')}>
					<XCloseIcon />
					<span>Скасувати</span>
				</button>
			</div>
		</div>
	);
};

export default TableActionsPanel;
