import { store } from 'store';
import { productsSliceApi } from 'store/reducers/products/productsSliceApi';

export const fetchProducts = async ({ request }) => {
	const url = new URL(request.url);
	const searchParams = url.searchParams;

	const promise = store.dispatch(productsSliceApi.endpoints.getProducts.initiate(searchParams.toString())).unwrap();

	return promise;
};
