import { isString } from 'utils/type-guards';
import { clientErrorMessages } from 'validation/messages/client';
import { sharedErrorMessages } from 'validation/messages/common';
import { edrpouRegex, emailRegex, nameRegex, phoneRegex } from 'validation/regex';
import { z } from 'zod';

// === SHARED
export const optionShapeSchema = (msg?: string) =>
	z.object(
		{
			label: z.string(),
			value: z.string(),
		},
		{ required_error: msg },
	);

export const username = z.string({
	required_error: sharedErrorMessages.name.required,
});

export const name = z
	.string({
		required_error: sharedErrorMessages.name.required,
	})
	.regex(nameRegex, sharedErrorMessages.name.notValid);

export const authEmail = z
	.string({
		required_error: sharedErrorMessages.email.required,
	})
	.email(sharedErrorMessages.email.notValid);

export const phoneNumber = z
	.string({
		required_error: sharedErrorMessages.phone.required,
	})
	.regex(phoneRegex, sharedErrorMessages.phone.notValid);

export const phonesArray = z
	.object(
		{
			number: phoneNumber,
			type: z.string({ required_error: sharedErrorMessages.phone.required }),
		},
		{
			required_error: sharedErrorMessages.phone.required,
		},
	)
	.array();

export const address = optionShapeSchema(sharedErrorMessages.address.required);
export const comment = z.object({ plainText: z.string(), html: z.string() }).nullish();
export const image = z
	.string({
		required_error: sharedErrorMessages.image.required,
	})
	.nullish();

// === BASE CLIENT
export const clientEmail = z
	.string({
		required_error: sharedErrorMessages.email.required,
	})
	.refine((value) => value === '' || emailRegex.test(value), {
		message: sharedErrorMessages.email.notValid,
	});

export const contract = optionShapeSchema(clientErrorMessages.contract.required).array().nullish();
export const segment = optionShapeSchema(clientErrorMessages.contract.required).extend({ type: z.string() }).nullish();

export const facilities = z.string({ required_error: clientErrorMessages.facilities.required }).nullish();

export const clientState = z
	.string({
		required_error: clientErrorMessages.state.required,
	})
	.nullish();

export const specialization = optionShapeSchema()
	.array()
	.refine((value) => Array.isArray(value) && !!value[0]?.label, sharedErrorMessages.optionShape.required);
// === INDIVIDUAL CLIENT
export const passport = z
	.string({
		required_error: clientErrorMessages.passport.required,
	})
	.nullish();

export const attachments = z.object({ href: z.string(), name: z.string() }).nullish().array();

export const ipnCode = z
	.string({
		required_error: clientErrorMessages.ipnCode.required,
	})
	.nullish();

// === LEGAL CLIENT
export const edrpouCode = z
	.string({
		required_error: clientErrorMessages.edrpouCode.required,
	})
	.regex(edrpouRegex, clientErrorMessages.edrpouCode.notValid);

export const organizationName = z.string({
	required_error: clientErrorMessages.organizationName.required,
});

// === EMPLOYEE
export const socialMedia = z.object({
	messenger: z.string(),
	nickname: z.string().nullish(),
});

export const socialMediaArray = socialMedia.array();
export const department = optionShapeSchema();
// eslint-disable-next-line camelcase
export const position_id = optionShapeSchema();
// eslint-disable-next-line camelcase
export const filiation_id = optionShapeSchema();
export const description = comment;
// eslint-disable-next-line camelcase
export const head_id = optionShapeSchema().array();
export const supervisor = optionShapeSchema().array();

// === min max number input
export const minmaxNumberField = (min: number = 1, max: number) => {
	return z.preprocess(
		(value) => (isString(value) ? parseInt(value) : value),
		z
			.number({ invalid_type_error: sharedErrorMessages.numberType.errorType })
			.min(min, sharedErrorMessages.numberType.min(min))
			.max(max, sharedErrorMessages.numberType.max(max)),
	);
};
