import { useEffect, useState } from 'react';

import type { InnerControlledMultipleAutocompleteProps, Suggestion } from '../types';
import { mergeSuggestions, resolveValue } from '../utils';

export const useMultipleInnerControlledAutocomplete = ({ defaultValue, tagLimit }: InnerControlledMultipleAutocompleteProps) => {
	const [suggestion, setSuggestion] = useState<string[] | Suggestion[]>(defaultValue);

	useEffect(() => {
		setSuggestion(defaultValue);
	}, [defaultValue]);

	const handleSetSuggestionOption = (newSuggestion: Suggestion | string) => {
		const patchedValue = mergeSuggestions(newSuggestion, suggestion) as Suggestion[] | string[];

		setSuggestion(patchedValue);
	};

	const handleUnselect = (label: string) => {
		const filtered = suggestion.filter((option) => resolveValue(option) !== label) as Suggestion[] | string[];

		setSuggestion(filtered);
	};

	const hasReachedLimit = suggestion?.length === tagLimit;

	return {
		suggestion,
		setSuggestion: handleSetSuggestionOption,
		onUnselect: handleUnselect,
		hasReachedLimit,
	};
};
