import { useOrderViewer } from 'hooks/useOrderViewer';
import React from 'react';

import EmptySuborder from '../EmptySuborder';

const EmptyOrderGate: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { activeSuborder, utils } = useOrderViewer();

	return !utils.isSuborderEmpty(activeSuborder.original) ? children : <EmptySuborder />;
};

export default EmptyOrderGate;
