import 'static/styles/style.css';

import clsx from 'clsx';
import { breakPoints } from 'const';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { isNumber, isObject } from 'utils/type-guards';

import { TableContext } from './Context';
import Pagination from './Pagination';
import styles from './style.module.css';
import TableHeader from './TableComponents/TableHeader';
import type { IProps } from './types';
import { insertSelectableColumn } from './utils';

const MobileTableView = lazy(() => import('./TableComponents/MobileTableView'));

const Table = <TData,>({ actionsPanelSlot: ActionsPanelSlot, ...props }: IProps<TData>) => {
	const {
		allData,
		columns,
		filledFromSecondCell,
		selectable,
		insertSelectableAt,
		pageType,
		onClickRow,
		onChangeSelection,
		className,
		perPage = 12,
		preselectedRowIds,
		useSyncSelectionTracker,
		pinColumns,
		extendRowProps,
		defineRowDisabledForSelection,
		overrideToggleAllRowSelectedProps,
	} = props;
	const [data, setData] = useState(allData);
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const hasPreselectedRows = !!preselectedRowIds && preselectedRowIds.length > 0;

	const table = useTable(
		{
			columns,
			data,
			initialState: { pageSize: perPage, ...(hasPreselectedRows && { selectedRowIds: preselectedRowIds }) },
		},
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((tableColumns) =>
				insertSelectableColumn(tableColumns, {
					selectable,
					insertSelectableAt,
					pageType,
					useSyncSelectionTracker,
					overrideToggleAllRowSelectedProps,
				}),
			);
		},
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		gotoPage,
		state: { pageIndex },
		setPageSize,
		nextPage,
		previousPage,
		pageCount,
		selectedFlatRows,
		getToggleAllRowsSelectedProps,
		toggleAllRowsSelected,
	} = table;

	const { tableRef } = usePinnedColumns({
		page,
		pinColumns,
		trSelector: '[data-tbody-table-row]',
		thSelector: '[data-thead-table-row]',
		pinnedClassName: styles.pinned,
		extraDeps: selectedFlatRows,
	});

	const { activeSelectionsCount } = useSyncSelectionTracker?.({ toggleAllRowsSelected, selectedFlatRows, allData }) ?? {};
	const tableContextValue = useMemo(
		() => ({ ...table, activeSelectionsCount: activeSelectionsCount ?? 0 }),
		[table, table.page, allData, activeSelectionsCount],
	);

	useEffect(() => {
		setPageSize(perPage);
	}, [perPage]);

	const handleClickOnRow = (row: TData) => {
		onClickRow?.(row);
	};

	useEffect(() => {
		setData(allData);
	}, [allData]);

	useEffect(() => {
		onChangeSelection?.(selectedFlatRows);
	}, [selectedFlatRows]);

	const shouldRenderActionSlot = !!ActionsPanelSlot;

	if (isMobile) {
		return (
			<Suspense fallback={<PageContentSkeleton />}>
				<TableContext.Provider value={tableContextValue}>
					<MobileTableView {...props} />

					{pageCount > 1 && (
						<Pagination
							previousPage={previousPage}
							nextPage={nextPage}
							pageIndex={pageIndex}
							pagesCount={pageCount}
							goToPage={gotoPage}
						/>
					)}
				</TableContext.Provider>
			</Suspense>
		);
	}

	return (
		<TableContext.Provider value={tableContextValue}>
			<div className={clsx(styles.tableContainer, className)}>
				<div data-table-wrapper className={clsx(styles.table2)}>
					{shouldRenderActionSlot && (
						<ActionsPanelSlot
							selectedItems={selectedFlatRows}
							getToggleSelectionProps={getToggleAllRowsSelectedProps}
							allData={allData}
						/>
					)}
					<table
						{...getTableProps()}
						className={clsx(styles.table, { [styles.pinSelection]: selectable && pinColumns?.includes('selection') })}
						ref={tableRef}
					>
						<TableHeader headerGroups={headerGroups} />

						<tbody {...getTableBodyProps()}>
							{page.map((row) => {
								const resolvedRow = defineRowDisabledForSelection?.(row) || row;

								prepareRow(resolvedRow);
								const {
									style: extendedStyles,
									className: extendedRowClassName,
									...restExtendedProps
								} = extendRowProps?.(resolvedRow) ?? {};

								return (
									<tr
										{...row.getRowProps()}
										{...restExtendedProps}
										data-tbody-table-row
										key={row.id}
										className={clsx(styles.tableRow, 'text-sx-regular', extendedRowClassName)}
										style={{ cursor: onClickRow && 'pointer', ...(isObject(extendedStyles) && extendedStyles) }}
										onClick={(e) => {
											e.stopPropagation();
											handleClickOnRow(row.original);
										}}
									>
										{row.cells.map((cell) => {
											if (cell.column.hidden || cell.column.mobile) return null;
											const minWidth = isNumber(cell.column.width) ? `${cell.column.width}px` : cell.column.width;

											return (
												<td
													data-hidden={cell.column.hidden}
													data-accessor-id={cell.column.id}
													key={cell.column.id}
													className={clsx({
														[styles.tableCell]: selectable || filledFromSecondCell,
														[styles.tableCellAlternative]: !selectable && !filledFromSecondCell,
													})}
													style={{ minWidth }}
												>
													{cell.render('Cell')}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				{pageCount > 1 && (
					<Pagination previousPage={previousPage} nextPage={nextPage} pageIndex={pageIndex} pagesCount={pageCount} goToPage={gotoPage} />
				)}
			</div>
		</TableContext.Provider>
	);
};

export default Table;
