import type { IColumns } from 'models/IColumns';
import { useEffect, useState } from 'react';
import { localStorageService } from 'services/localStorageService';

export type UseAdjustableColumnsProps = {
	saveConfigKey: string;
};
export type StoredAdjustedColumn = { accessor: string; hidden: boolean };

export const useAdjustableColumns = (columns: IColumns[], { saveConfigKey }: UseAdjustableColumnsProps) => {
	const [adjustedColumns, setAdjustedColumns] = useState<IColumns[]>(hydrateState(saveConfigKey, columns));

	useEffect(() => {
		const columnsToSave = adjustedColumns.map(({ accessor, hidden }) => ({ accessor, hidden }));

		localStorageService.save(saveConfigKey, columnsToSave);
	}, [adjustedColumns]);

	useEffect(() => {
		const previouslySavedColumns = localStorageService.load<StoredAdjustedColumn[]>(saveConfigKey);

		if (previouslySavedColumns) {
			const newColumns = columns.map(serializeSavedColumns(previouslySavedColumns));

			setAdjustedColumns(newColumns);
		} else {
			setAdjustedColumns([...columns]);
		}
	}, [columns]);

	const adjust = (columnsToBeAdjusted: IColumns[]) => {
		setAdjustedColumns(columnsToBeAdjusted);
	};

	return { columns: adjustedColumns, adjust };
};

function hydrateState(key: UseAdjustableColumnsProps['saveConfigKey'], columns: IColumns[]) {
	return function hydrate() {
		const savedAdjustedColumns = localStorageService.load<StoredAdjustedColumn[]>(key);

		if (savedAdjustedColumns) {
			return columns.map(serializeSavedColumns(savedAdjustedColumns));
		}

		return [...columns] ?? [];
	};
}

function serializeSavedColumns(previouslySavedColumns: StoredAdjustedColumn[]) {
	return function serialize(column: IColumns) {
		const savedColumn = previouslySavedColumns.find((saved) => saved.accessor === column.accessor);

		return savedColumn ? { ...column, accessor: savedColumn.accessor, hidden: savedColumn.hidden } : column;
	};
}
