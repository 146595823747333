/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import clsx from 'clsx';
import React from 'react';
import { useTable } from 'react-table';

import MobileReservedProductTableTd from './MobileReservedProductTableTd';
import MobileReservedProductTableTr from './MobileReservedProductTableTr';
import styles from './styles.module.css';
import type { IProps } from './types';

const MobileReservedProductsTable: React.FC<IProps & { columns: any[] }> = ({ data, columns }) => {
	const { getTableProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

	return (
		<table {...getTableProps()} data-reserved-products-table className={clsx(styles.reservedProductsTable, styles.mobile)}>
			<tbody className={styles.tbody}>
				{rows.map((row) => {
					prepareRow(row);
					return (
						<MobileReservedProductTableTr {...row.getRowProps()}>
							{row.cells.map((cell, index) => {
								const [headerGroup] = headerGroups;
								const column = headerGroup.headers[index];

								return <MobileReservedProductTableTd cell={column.render('Header')} content={cell.render('Cell')} />;
							})}
						</MobileReservedProductTableTr>
					);
				})}
			</tbody>
		</table>
	);
};

export default MobileReservedProductsTable;
