/* eslint-disable new-cap */
import clsx from 'clsx';
import Button from 'components/Button';
import ClickAwayListener from 'components/ClickAwayListener';
import Toggle from 'components/Toggle';
import { breakPoints } from 'const';
import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';

import styles from './style.module.css';
import { IProps } from './types';

const ColumnsSettingsMenu: React.FC<IProps> = ({ open, columns, className, setColumns, onClose, style }) => {
	const [actualColumns, setActualColumns] = useState(columns);
	const [isChanged, setIsChanged] = useState(true);
	const handleChangeColumns = (clickedColumnAccessor: string) => {
		const updatedColumns = actualColumns.map((column) => {
			if (column.accessor === clickedColumnAccessor) {
				return {
					...column,
					hidden: !column.hidden,
				};
			}
			return column;
		});
		setActualColumns(updatedColumns);
		setIsChanged(false);
	};

	const handleSaveChanges = () => {
		setColumns(actualColumns);
		onClose();
	};

	const handleCancelChanges = () => {
		onClose();
		setActualColumns(columns);
		setIsChanged(true);
	};

	useEffect(() => {
		setActualColumns(columns);
	}, [columns]);

	const drawerContent = (
		<>
			<h3>Налаштуйте стовпчики таблиці:</h3>

			<div className={styles.columnWrapper}>
				{actualColumns?.map((column) => {
					if (column.mobile) return null;

					return (
						column.isAdjustable && (
							<div key={column.accessor} className={styles.rowBox}>
								<span>
									<>
										{column.icon}
										<p>
											{typeof column?.Header === 'function'
												? column?.Header({ column, isAdjustable: column.isAdjustable })
												: column?.Header}
										</p>
									</>
								</span>
								<Toggle onChange={() => handleChangeColumns(column.accessor)} checked={!column.hidden} />
							</div>
						)
					);
				})}
			</div>

			<div className={styles.buttonsWrapper}>
				<Button text="Скасувати" variant="bordered" onClick={handleCancelChanges} />
				<Button text="Застосувати" variant="default" onClick={handleSaveChanges} disabled={isChanged} className={styles.disableBtn} />
			</div>
		</>
	);

	return (
		open && (
			<>
				<MediaQuery minWidth={breakPoints.MOBILE} maxWidth={breakPoints.DESKTOP - 1}>
					<div className={clsx(styles.modal, className)} style={style}>
						<ClickAwayListener onClickAway={onClose}>
							<div className={clsx(styles.box)}>{drawerContent}</div>
						</ClickAwayListener>
					</div>
				</MediaQuery>

				<MediaQuery minWidth={breakPoints.DESKTOP}>
					<ClickAwayListener onClickAway={onClose}>
						<div className={clsx(styles.box, className)}>{drawerContent}</div>
					</ClickAwayListener>
				</MediaQuery>
			</>
		)
	);
};

export default ColumnsSettingsMenu;
