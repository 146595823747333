import { useOrderCart } from 'hooks/useOrderCart';
import { ICreateOrderSliceState, Product } from 'store/reducers/orders/types';

export const useProductConfigurationToolModal = ({ mode }: { mode: ICreateOrderSliceState['mode'] }) => {
	const cart = useOrderCart();

	const closeModal = () => {
		cart.closeModal();
		cart.clearMaybeSelectOne();
	};

	const openModal = (product: Product) => {
		cart.maybeSelectOne({ product, mode });
		cart.openModal();
	};

	return { closeModal, openModal, isOpenModal: cart.isModalOpened };
};
