import clsx from 'clsx';
import React from 'react';
import { ReactComponent as PaintIcon } from 'static/images/paint.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';

import styles from './styles.module.css';
import type { ColorantPanelProps } from './types';

const ColorantPanel: React.FC<ColorantPanelProps> = ({ className, disabled = false, onAutoCompute, onManualCompute }) => {
	const handleAutoColorantComputations = () => {
		onAutoCompute();
	};
	const handleManualColorantComputations = () => {
		onManualCompute();
	};

	return (
		<div className={clsx(styles.colorantPanel, className)}>
			<button
				onClick={handleManualColorantComputations}
				type="button"
				className={clsx(styles.colorantButton, styles.manual)}
				disabled={disabled}
			>
				<PlusIcon />
				<span>Додати пігменти вручну</span>
			</button>

			<button onClick={handleAutoColorantComputations} type="button" className={clsx(styles.colorantButton, styles.auto)} disabled={disabled}>
				<PaintIcon />
				<span>Розрахувати пігменти</span>
			</button>
		</div>
	);
};

export default ColorantPanel;
