import { useEffect, useRef, useTransition } from 'react';

export type UsePinnedColumnsProps = {
	/**
	 * it is array of column accessors, e.g accessor: 'id'
	 */
	pinColumns: string[] | undefined;
	page: { cells: { column: { id: string; hidden?: boolean } }[] }[];
	trSelector: string;
	thSelector: string;
	pinnedClassName: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extraDeps?: any;
};

export const usePinnedColumns = ({ pinColumns, trSelector, thSelector, pinnedClassName, page, extraDeps }: UsePinnedColumnsProps) => {
	const tableRef = useRef<HTMLTableElement>(null);
	const [, startTransition] = useTransition();

	const hasPinnedColumns = !!pinColumns && pinColumns.length > 0;

	useEffect(() => {
		if (tableRef.current && hasPinnedColumns) {
			const applyPinnedStyles = (tbodyElements: HTMLTableRowElement[], theadElements: HTMLTableRowElement[]) => {
				if (!tbodyElements || !theadElements) return;

				tbodyElements.forEach((tbodyElement, index) => {
					const children = Array.from(tbodyElement.children) as HTMLTableElement[];
					const thHeadChildren = Array.from(theadElements[index]?.children ?? []) as HTMLTableElement[];

					const targetColumnsIds = children.reduce((acc, tdElement, idx) => {
						const isPinned = pinColumns.includes(tdElement.dataset.accessorId);

						if (isPinned && tdElement.dataset.hidden !== 'true') {
							acc.push(idx);
						}

						return acc;
					}, []);

					targetColumnsIds.forEach((idx) => {
						const tdElement = children[idx];
						const thElement = thHeadChildren[idx];

						const firstIndex = targetColumnsIds[0];

						const leftPosition = children.slice(firstIndex, idx).reduce((totalWidth, el) => {
							const columnWidth = !idx ? '0' : el.getBoundingClientRect().width;
							return totalWidth + (typeof columnWidth === 'number' ? columnWidth : 0);
						}, 0);

						if (thElement) {
							thElement.style.left = `${leftPosition}px`;
							thElement.classList.add(pinnedClassName);
						}

						tdElement.style.left = `${leftPosition}px`;
						tdElement.classList.add(pinnedClassName);
					});
				});
			};

			const makeColumnsPinned = () => {
				const tbodyRows = Array.from(tableRef.current.querySelectorAll(trSelector)) as HTMLTableRowElement[];
				const theadRows = Array.from(document.querySelectorAll(thSelector)) as HTMLTableRowElement[];

				applyPinnedStyles(tbodyRows, theadRows);
			};

			startTransition(() => makeColumnsPinned());
		}
	}, [hasPinnedColumns, pinColumns, page, extraDeps]);

	return { tableRef };
};
