import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { clientsSliceApi } from './clientsSliceApi';

export const selectAllClients = (queryParams: string) => {
	const selector = clientsSliceApi.endpoints.getClients.select(queryParams);

	return createDraftSafeSelector(selector, (response) => response.data ?? []);
};

export const selectAllContracts = createDraftSafeSelector(
	clientsSliceApi.endpoints.getContracts.select(undefined),
	(response) => response.data ?? [],
);
