import Button from 'components/Button';
import Modal from 'components/Modal';
import { IProps as ModalProps } from 'components/Modal/types';
import AvailabilityChecker from 'components/OrderCreatePageComponents/ProductConfigurationTool/AvailabilityChecker';
import ConfigurationToolHeader from 'components/OrderCreatePageComponents/ProductConfigurationTool/ConfigurationToolHeader';
import QuantitySelector from 'components/OrderCreatePageComponents/ProductConfigurationTool/QuantitySelector';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useState } from 'react';
import CircleCheckIcon from 'static/images/check-rounded.svg';
import { MeasureUnit, Product } from 'store/reducers/orders/types';
import { calculateTotalPrice, numberify } from 'utils/shared';

import styles from './styles.module.css';

type TransferProductDetailsModalProps = Pick<ModalProps, 'onClose'>;

const TransferProductDetailsModal: React.FC<TransferProductDetailsModalProps> = ({ onClose }) => {
	const {
		suborders: { seeDetailsForItem },
	} = useOrderViewer();

	const { brand, title, pickedAmount, measureUnit, price } = seeDetailsForItem as Product;

	const [state, setState] = useState(() => ({
		quantity: pickedAmount,
		unit: measureUnit,
	}));

	const handleMeasureUnitChange = (unit: MeasureUnit) => {
		setState({ ...state, unit });
	};
	const handleQuantityChange = (quantity: number) => {
		setState({ ...state, quantity: quantity });
	};

	const handleAction = () => {};

	const quantity = numberify(state.quantity);
	const totalPrice = calculateTotalPrice({ price, pickedAmount: quantity } as Product);

	return (
		<Modal open stackable onClose={onClose} maxWidth="90vw" maxHeight="90vh" minHeight="500px" className={styles.modal}>
			<div className={styles.wrapper}>
				<ConfigurationToolHeader title={brand} subtitle={title} onXCloseClick={onClose} />

				<div className={styles.contentWrapper}>
					<QuantitySelector
						totalPrice={totalPrice}
						quantity={quantity}
						unit={state.unit}
						onMeasureUnitChange={handleMeasureUnitChange}
						onQuantityChange={handleQuantityChange}
						className={styles.quantitySelector}
					/>
					<div className={styles.actionButtonWrapper}>
						<Button onClick={handleAction} variant="smallRounded" text="Вибрати" icon={CircleCheckIcon} className={styles.actionButton} />
					</div>

					<AvailabilityChecker />
				</div>
			</div>
		</Modal>
	);
};

export default TransferProductDetailsModal;
