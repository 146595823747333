/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { useOrderCart } from './useOrderCart';

type UseSyncProductSelectionTrackerProps = { toggleAllRowsSelected?: (value?: boolean) => void; selectedFlatRows?: any[]; allData: any[] };

const fallbackProps = {} as UseSyncProductSelectionTrackerProps;

export const useSyncProductSelectionTracker = ({ toggleAllRowsSelected, allData }: UseSyncProductSelectionTrackerProps = fallbackProps) => {
	const cart = useOrderCart();
	const products = cart.products;

	useEffect(() => {
		if (products.length <= 0) {
			toggleAllRowsSelected?.(false);
		}
	}, [products]);

	const selectAll = () => {
		toggleAllRowsSelected?.(true);
		cart.maybeSelectMany(allData);
	};
	const unselectAll = () => {
		toggleAllRowsSelected?.(false);
		cart.clearMaybeSelectedAll();
	};

	return { activeSelectionsCount: products.length, selectAll, unselectAll, toggle: cart.toggleProductInOrderCart };
};
