import { isObject, isURLSearchParams } from 'utils/type-guards';
import { z } from 'zod';

const extractString = (obj: unknown) => (isObject(obj) ? obj.label : obj);

export const colorantCalculationSearchParamsSchema = z.object({
	brand: z.preprocess(extractString, z.string().min(1, 'Обовʼязкове поле')),
	collection: z.preprocess(extractString, z.string().min(1, 'Обовʼязкове поле')),
	code: z.preprocess(extractString, z.string().min(1, 'Обовʼязкове поле')),
	palette: z.preprocess(extractString, z.string().min(1, 'Обовʼязкове поле')),
	tare: z.preprocess(extractString, z.string().min(1, 'Обовʼязкове поле')),
	quantity: z.preprocess((value) => Number(value), z.number().min(1, 'Мінімальна кількість - 1 шт.')),
});
export const colorantParametersSchema = z.object({
	colorCode: z.preprocess(extractString, z.string().min(1, 'Обовʼязкове поле')),
	colorants: z.any().array().nullish(),
	product: z.any(),
	services: z.any(),
});

export type ColorantStepSearchParamsSchema = Required<z.infer<typeof colorantCalculationSearchParamsSchema>>;
export type ColorantParametersSchema = Required<z.infer<typeof colorantParametersSchema>>;
export type ColorantStepSearchParamsKeys = keyof ColorantStepSearchParamsSchema;

export const defaultColorantSearchParams: ColorantStepSearchParamsSchema = {
	brand: '',
	collection: '',
	palette: '',
	code: '',
	tare: '',
	quantity: 1,
};
export const defaultColorantParametersValues: ColorantParametersSchema = {
	...defaultColorantSearchParams,
	colorCode: '',
	colorants: [],
	product: {},
	services: [],
};

export const validateColorantParameters = (params: ColorantParametersSchema) => colorantParametersSchema.safeParse(params);

export const validateColorantCalculationStepSearchParams = (data: URLSearchParams | Record<string, unknown>) => {
	if (!isURLSearchParams(data)) {
		const result = colorantCalculationSearchParamsSchema.safeParse(data);

		return result;
	}

	const pivotSearchParamsKeys = Object.keys(colorantCalculationSearchParamsSchema.shape);
	const schema = pivotSearchParamsKeys.reduce((list, key) => ({ ...list, [key]: data.get(key) }), {});

	const result = colorantCalculationSearchParamsSchema.safeParse(schema);

	return result;
};
