import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import styles from './styles.module.css';
import type { TooltipProps } from './types';
import { disableUserSelect, enableUserSelect, preventDefaultBehavior } from './utils';

const Tooltip: React.FC<TooltipProps> = ({
	text,
	children,
	showDelayMs = 200,
	withArrowPointer = false,
	position = 'bottom',
	bgColor,
	color,
	className,
}) => {
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [isActive, setIsActive] = useState(false);
	const timeoutID = useRef<NodeJS.Timeout | null>(null);

	const showTooltip = () => {
		disableUserSelect();

		timeoutID.current = setTimeout(() => {
			setIsActive(true);
		}, showDelayMs);
	};

	const closeTooltip = (e: React.PointerEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (timeoutID.current) {
			clearTimeout(timeoutID.current);
		}

		enableUserSelect();
		setIsActive(false);
	};

	useEffect(() => {
		if (tooltipRef.current) {
			tooltipRef.current.addEventListener('contextmenu', preventDefaultBehavior);
		}

		return () => {
			if (timeoutID.current) {
				clearTimeout(timeoutID.current);
			}
			if (tooltipRef.current) {
				tooltipRef.current.removeEventListener('contextmenu', preventDefaultBehavior);
			}

			enableUserSelect();
		};
	}, []);

	return (
		<div
			data-tooltip
			ref={tooltipRef}
			style={
				{
					'--tooltip-show-delay': `${showDelayMs}ms`,
					...(bgColor && { '--tooltip-bg-color': bgColor }),
					...(color && { '--tooltip-color': color }),
				} as React.CSSProperties
			}
			className={clsx(styles.tooltip, styles[position], { [styles.active]: isActive, [styles.withArrowPointer]: withArrowPointer }, className)}
			onPointerDown={showTooltip}
			onPointerUp={closeTooltip}
			onPointerLeave={closeTooltip}
		>
			{children}

			<span data-tooltip-popup className={styles.tooltipText}>
				{text}
			</span>
		</div>
	);
};

export default Tooltip;
