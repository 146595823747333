import type { IColorEntity } from '../ColorfulBadge/types';
const colorsPaletteEntities: Record<string, IColorEntity> = {
	1: { fontColor: '#175CD3', dotColor: '#2E90FA', bgColor: '#EFF8FF' },
	2: { fontColor: '#344054', dotColor: '#667085', bgColor: '#F2F4F7' },
	3: { fontColor: '#B54708', dotColor: '#F79009', bgColor: '#FFFAEB' },
	4: { fontColor: '#B42318', dotColor: '#F04438', bgColor: '#FEF3F2' },
	5: { fontColor: '#027A48', dotColor: '#12B76A', bgColor: '#ECFDF3' },
	6: { fontColor: '#C11574', dotColor: '#EE46BC', bgColor: '#FDF2FA' },
};

export const getColorByIndex = (key: number): IColorEntity => {
	const numKeys = Object.keys(colorsPaletteEntities).length;

	const normalizedIndex = ((key - 1) % numKeys) + 1;
	return colorsPaletteEntities[normalizedIndex];
};
