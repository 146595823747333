import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ProductFilterNode, UseProductFilterProps } from './types';
import { findFilterItem, findRootNodeById } from './utils';

export const useProductFilter = (filters: ProductFilterNode, config: UseProductFilterProps) => {
	const [availableFilters, setAvailableFilters] = useState(filters?.children);
	const [activeFilters, setActiveFilters] = useState<ProductFilterNode[] | null>(null);
	const [, setSearchParams] = useSearchParams();

	const { urlQueryKey, clearQueryKeyOnReset } = config ?? {};

	useEffect(() => {
		const existingQueryParams = window.location.search;
		const searchParams = new URLSearchParams(existingQueryParams);
		const searchParamsArray = Array.from(searchParams.entries());

		if (searchParams.size) {
			const { result } = findFilterItem(filters.children, searchParamsArray);
			const rootNode = result[result?.length - 1];
			const hasFilters = result.length > 0;
			const newActiveFilters = hasFilters ? result : null;
			const newAvailableFilters = hasFilters ? rootNode?.children : filters?.children;

			setActiveFilters(newActiveFilters);
			setAvailableFilters(newAvailableFilters);
		}
	}, []);

	const patchSearchParams = (newFilter: ProductFilterNode | null, bulk?: boolean) => {
		if (!urlQueryKey) {
			return;
		}

		const existingQueryParams = window.location.search;
		const newSearchParams = new URLSearchParams(existingQueryParams);

		if (newSearchParams.has(clearQueryKeyOnReset)) {
			newSearchParams.delete(clearQueryKeyOnReset);
		}

		if (newFilter === null) {
			newSearchParams.delete(urlQueryKey);
		} else {
			if (bulk) {
				const searchParamsArray = Array.from(newSearchParams.entries()).filter(([key]) => key === urlQueryKey);
				const filterIndex = searchParamsArray.findIndex(([, value]) => value === newFilter.id);
				const filtersSlice = searchParamsArray.slice(filterIndex);

				filtersSlice.forEach(([key, value]) => {
					newSearchParams.delete(key, value);
				});
			} else {
				if (newSearchParams.has(urlQueryKey, newFilter.id)) {
					newSearchParams.delete(urlQueryKey, newFilter.id);
				} else {
					newSearchParams.append(urlQueryKey, newFilter.id);
				}
			}
		}

		setSearchParams(newSearchParams);
	};

	const unselect = useCallback((node: ProductFilterNode, props?: { bulk?: boolean }) => {
		let rootNode = findRootNodeById(filters, node.root);

		if (rootNode === null) {
			rootNode = filters;
		}

		const { bulk } = props ?? {};

		setActiveFilters((prev) => {
			const indexOfNode = prev.findIndex((item) => item.id === node.id);

			const newFilters = prev.slice(0, indexOfNode);
			return newFilters;
		});

		setAvailableFilters(rootNode?.children);
		patchSearchParams(node, bulk);
	}, []);

	const unselectCurrent = useCallback(() => {
		if (!activeFilters) {
			return reset();
		}

		const lastItemIndex = activeFilters?.length ?? 0;
		const current = activeFilters[lastItemIndex - 1];

		if (!current) {
			return reset();
		}

		unselect(current);
	}, [activeFilters]);

	const select = useCallback((node: ProductFilterNode) => {
		setActiveFilters((prev) => {
			if (prev === null) {
				return [node];
			}

			const newFilters = [...prev, node];
			return newFilters;
		});
		setAvailableFilters(node.children);
		patchSearchParams(node);
	}, []);

	const reset = useCallback(() => {
		setActiveFilters(null);
		setAvailableFilters(filters.children);
		patchSearchParams(null, true);
	}, []);

	const start = useCallback(() => {
		setActiveFilters([]);
	}, []);

	return {
		available: availableFilters,
		active: activeFilters,
		setAvailableFilters,
		unselectCurrent,
		unselect,
		select,
		start,
		reset,
	};
};
