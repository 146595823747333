import 'react-day-picker/dist/style.css';

import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import ToasterProvider from 'contexts/ToasterProvider';
import PageLayoutSkeleton from 'layouts/PageLayout/PageLayoutSkeleton';
import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';

const App: React.FC = () => {
	return (
		<Suspense fallback={<PageLayoutSkeleton />}>
			<OrderAlertDialoguesProvider>
				<ToasterProvider>
					<RouterProvider router={router} />
				</ToasterProvider>
			</OrderAlertDialoguesProvider>
		</Suspense>
	);
};

export default App;
