/* eslint-disable react/prop-types */
import 'static/styles/style.css';

import clsx from 'clsx';
import Button from 'components/Button';
import EmptyOrder from 'components/OrderCreatePageComponents/EmptyOrder';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { breakPoints, ROUTES_URLS } from 'const';
import { useOrderContext } from 'contexts/OrderProvider';
import { useOrderViewer } from 'hooks/useOrderViewer';
import ActionButton from 'layouts/PageLayout/ActionButton';
import React, { lazy, Suspense } from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import PlusIcon from 'static/images/plus.svg';
import SaveIcon from 'static/images/save.svg';
import XCloseIcon from 'static/images/x-close.svg';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';

const OrderMobileTable = lazy(() => import('components/Table/MobileViews/OrderMobileTable'));
const ChangeProductPricePanel = lazy(() => import('components/ChangeProductPricePanel'));

const OrderProducts: React.FC = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { toggleSelectedItems, exitChangePriceMode, isChangePriceMode, productColumns, pinColumns } = useOrderContext();

	const { api, utils, draftOrder } = useOrderViewer();

	const navigateToSelectProduct = () => {
		const url = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id });

		navigate(url);
	};

	const isEmptyOrder = !draftOrder.productsCount;

	if (isEmptyOrder) {
		return <EmptyOrder />;
	}

	return (
		<>
			<Suspense fallback={<Spinner />}>{isChangePriceMode && <ChangeProductPricePanel className={styles.changeProductPricePanel} />}</Suspense>

			<Table
				selectable
				allData={draftOrder.products}
				columns={productColumns}
				pageType="order"
				pinColumns={pinColumns}
				overrideToggleAllRowSelectedProps={utils.overrideAllSelection}
				defineRowDisabledForSelection={utils.assignIsDisabledLabel}
				mobileViewComponent={({ row }) => <OrderMobileTable row={row} onEditProductClick={() => {}} />}
				onChangeSelection={toggleSelectedItems}
				insertSelectableAt={1}
				className={styles.orderProductsTable}
				mobileHeader={({ headerGroups }) => (
					<Suspense fallback={<Spinner />}>
						<MobileHeader
							gridCustomLayout="44px 44px auto"
							headerGroups={headerGroups}
							renderHeaders={['.', 'selection', 'title']}
							justify="flex-start"
							className={styles.mobileHeader}
						/>
					</Suspense>
				)}
			/>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Suspense>
					<ActionButton
						variant="default"
						onClick={navigateToSelectProduct}
						text="Додати товар"
						icon={PlusIcon}
						className={styles.addProductButton}
					/>
					<div className="safe-area-bottom" />
				</Suspense>
			</MediaQuery>

			{isChangePriceMode && (
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<div className={clsx(styles.fixedBtn, styles.priceChangeControls)}>
						<Button text="Скасувати" variant="rounded" icon={XCloseIcon} onClick={exitChangePriceMode} className={styles.cancelButton} />
						<Button text="Зберегти" variant="rounded" icon={SaveIcon} className={styles.saveButton} />
					</div>
				</MediaQuery>
			)}

			{!isChangePriceMode && (
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<div className={styles.fixedBtn}>
						<Button
							text="Додати товар"
							variant="rounded"
							icon={PlusIcon}
							onClick={navigateToSelectProduct}
							className={styles.addProductButton}
						/>
					</div>
				</MediaQuery>
			)}

			{api.isProcessing && <Spinner />}
		</>
	);
};

export default OrderProducts;
