import { isNumber, isObject, isString } from 'utils/type-guards';

import type { IProps, Suggestion } from '../types';

export const resolveValue = (option?: string | Suggestion) => {
	return isString(option) ? option : isObject<Suggestion>(option) ? option?.label : isNumber(option) ? String(option) : undefined;
};

export const filterIncluded = (options: IProps['valuesList'], value: string) => {
	return options?.filter((suggestion) => resolveValue(suggestion).toLowerCase().includes(value?.toLowerCase())) ?? [];
};
export const findIndex = (options: IProps['valuesList'], value: string) => {
	return options?.findIndex((suggestion) => suggestion?.label?.toLowerCase() === value?.toLowerCase()) ?? -1;
};

export const getHydrateStateData = (options: IProps['valuesList'], value?: string | Suggestion | string[] | Suggestion[]) => {
	const resolvedOptions = options ?? [];
	const isArray = Array.isArray(value);
	const resolvedValue = isArray ? value.map(resolveValue) : resolveValue(value);
	const userInput = isArray ? '' : (resolvedValue as string);

	const filteredSuggestions = isArray
		? value.filter((v) => options?.some((option) => resolveValue(option).toLowerCase().includes(resolveValue(v).toLowerCase())))
		: filterIncluded(resolvedOptions, resolvedValue as string);

	const activeSuggestion = isArray
		? value.findIndex((v) => options?.some((option) => resolveValue(option) === resolveValue(v)))
		: findIndex(resolvedOptions, resolvedValue as string);

	return {
		userInput,
		hasFocus: false,
		activeSuggestion,
		filteredSuggestions,
		shouldDebounce: true,
		showSuggestions: false,
	};
};

export const mergeSuggestions = (newSuggestion: Suggestion | string, suggestions: Suggestion[] | string[]) => {
	const found = suggestions.find((suggestion) => resolveValue(suggestion) === resolveValue(newSuggestion));

	if (found) return suggestions;

	return [...suggestions, newSuggestion];
};
