/* eslint-disable react/prop-types */
import {
	LockedServiceCellCounterGate,
	LockedServiceCellGate,
	LockedServiceCellSelectFieldGate,
	LockedServiceCellWithDeleteButtonGate,
} from 'components/OrderPageComponents/(LockerGates)/LockedServiceCellGate';
import ServiceCounter from 'components/OrderServicesPageComponents/ServiceCounter';
import ServiceDeleteButton from 'components/OrderServicesPageComponents/ServiceDeleteButton';
import ServiceInputField from 'components/OrderServicesPageComponents/ServiceInputField';
import RegularServiceSelectField from 'components/OrderServicesPageComponents/ServiceSelectField/RegularServiceSelectField';
import ServiceOptionSelectDependantField from 'components/OrderServicesPageComponents/ServiceSelectField/ServiceSelectDependantField';
import ServiceOptionSelectField from 'components/OrderServicesPageComponents/ServiceSelectField/ServiceSelectMajorField';
import { HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useMemo } from 'react';
import { useGetPaintBaseToneOptionListQuery, useGetPaintCollectionOptionListQuery } from 'store/reducers/orders/ordersSliceApi';

export const useRegularServicesColumns = () => {
	const columns = useMemo(
		() => [
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="flex-start" value="Послуги" />
					</HeaderCell>
				),
				accessor: 'service',
				Cell: ({ row }) => (
					<RegularCell>
						<RegularCellContent color="var(--gray-900)" fontWeight="500" justify="flex-start">
							<LockedServiceCellGate itemId={row.original.id} rowIndex={row.id}>
								{row.original.service}
							</LockedServiceCellGate>
						</RegularCellContent>
					</RegularCell>
				),
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="flex-start" value="Тип послуги" />
					</HeaderCell>
				),
				accessor: 'type',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="flex-start" width="100%">
								<LockedServiceCellSelectFieldGate itemId={row.original.id}>
									<ServiceOptionSelectField
										skip={row.original.serviceId !== '22'}
										id={row.original.id}
										placeholder="Тип відсутній"
									/>
								</LockedServiceCellSelectFieldGate>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="flex-start" value="Обʼєкти послуги" />
					</HeaderCell>
				),
				accessor: 'workType',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="flex-start" width="100%">
								<LockedServiceCellSelectFieldGate itemId={row.original.id}>
									<ServiceOptionSelectDependantField
										skip={row.original.serviceId !== '22'}
										id={row.original.id}
										placeholder="Не потрібно вказувати"
									/>
								</LockedServiceCellSelectFieldGate>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: ({ column, rows }) => (
					<HeaderCell>
						<HeaderCellContent sortable withIndeterminate disabled={rows.length < 2} column={column} justify="center" value="Ціна" />
					</HeaderCell>
				),
				sortType: 'alphanumeric',
				accessor: 'price',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="center" width="100%">
								<ServiceInputField id={row.original.id} value={row.original.price} />
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="center" value="Кількість" />
					</HeaderCell>
				),
				accessor: 'quantity',
				width: 'fit-content',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="center" width="100%">
								<LockedServiceCellCounterGate itemId={row.original.id}>
									<ServiceCounter id={row.original.id} value={row.original.quantity} />
								</LockedServiceCellCounterGate>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="center" value="Прибрати" />
					</HeaderCell>
				),
				accessor: 'action',
				Cell: ({ row }) => {
					return (
						<RegularCell justify="center">
							<RegularCellContent>
								<LockedServiceCellWithDeleteButtonGate itemId={row.original.id}>
									<ServiceDeleteButton item={row.original} />
								</LockedServiceCellWithDeleteButtonGate>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
		],
		[],
	);

	return columns;
};
export const usePaintToningServicesColumns = () => {
	const {
		draftOrder: { onInputChangePaintToning, onSelectChangePaintToning },
	} = useOrderViewer();

	const columns = useMemo(
		() => [
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="flex-start" value="Послуги тонування" />
					</HeaderCell>
				),
				accessor: 'service',
				Cell: ({ row }) => (
					<RegularCell>
						<RegularCellContent color="var(--gray-900)" fontWeight="500" justify="flex-start">
							{row.original.service}
						</RegularCellContent>
					</RegularCell>
				),
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="flex-start" value="Код кольору" />
					</HeaderCell>
				),
				accessor: 'colorCode',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="flex-start" width="100%">
								<input
									defaultValue={row.original.colorCode}
									placeholder="Введіть код кольору..."
									onChange={onInputChangePaintToning(row.original.id, 'colorCode')}
								/>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="flex-start" value="Колекція" />
					</HeaderCell>
				),
				accessor: 'collection',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="flex-start" width="100%">
								<RegularServiceSelectField
									value={row.original.collection}
									onSelect={onSelectChangePaintToning(row.original.id, 'collection')}
									popupHeaderText="Оберіть колекцію"
									placeholder="Оберіть колекцію"
									useQuery={useGetPaintCollectionOptionListQuery}
								/>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: ({ column, rows }) => (
					<HeaderCell>
						<HeaderCellContent sortable withIndeterminate disabled={rows.length < 2} column={column} justify="center" value="База" />
					</HeaderCell>
				),
				accessor: 'baseTone',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="center" width="100%">
								<RegularServiceSelectField
									value={row.original.baseTone}
									onSelect={onSelectChangePaintToning(row.original.id, 'baseTone')}
									popupHeaderText="Оберіть базу"
									placeholder="Оберіть базу"
									useQuery={useGetPaintBaseToneOptionListQuery}
								/>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: ({ column, rows }) => (
					<HeaderCell>
						<HeaderCellContent sortable withIndeterminate disabled={rows.length < 2} column={column} justify="center" value="Тара" />
					</HeaderCell>
				),
				width: 'fit-content',
				accessor: 'tare',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="center" width="100%">
								{row.original.tare}
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: ({ column, rows }) => (
					<HeaderCell>
						<HeaderCellContent
							sortable
							withIndeterminate
							disabled={rows.length < 2}
							column={column}
							justify="center"
							value="Ціна тонування"
						/>
					</HeaderCell>
				),
				accessor: 'price',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="center" width="100%">
								<ServiceInputField id={row.original.id} value={row.original.price} />
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="center" value="Кількість одиниць фарби" />
					</HeaderCell>
				),
				accessor: 'quantity',
				Cell: ({ row }) => {
					return (
						<RegularCell>
							<RegularCellContent justify="center" width="100%">
								<LockedServiceCellCounterGate itemId={row.original.id}>
									<ServiceCounter id={row.original.id} value={row.original.quantity} />
								</LockedServiceCellCounterGate>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
			{
				Header: () => (
					<HeaderCell>
						<HeaderCellContent justify="center" value="Прибрати" />
					</HeaderCell>
				),
				accessor: 'action',
				Cell: ({ row }) => {
					return (
						<RegularCell justify="center">
							<RegularCellContent>
								<LockedServiceCellWithDeleteButtonGate itemId={row.original.id}>
									<ServiceDeleteButton item={row.original} />
								</LockedServiceCellWithDeleteButtonGate>
							</RegularCellContent>
						</RegularCell>
					);
				},
				mobile: false,
			},
		],
		[],
	);

	return columns;
};
