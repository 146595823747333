import { useAppDispatch } from 'hooks/redux';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { IService, IServiceType, IServiceWorkType } from 'models/IServices';
import { useCallback } from 'react';
import { orderNotificationService } from 'services/orderNotificationService';
import { Product } from 'store/reducers/orders/types';
import { orderPreviewActions as actions } from 'store/reducers/orderViewer';
import { ServiceOption, SuborderTransferConfig, SuborderTransferConfigWithData } from 'store/reducers/orderViewer/types';

import { DataFormatterFn, SelectTransferProductsPayload, SuborderTypePayload } from '../types';

const DEBOUNCE_TIME = 500;

export const useDispatcher = () => {
	const dispatch = useAppDispatch();

	/*
	 * memoized callbacks
	 */
	const updatePropertyInDraftOrderMemoized = useCallback((id: string, property: string, value: unknown) => {
		dispatch(actions.updateProductProperty({ id, [property]: value, target: 'draft' }));
	}, []);
	const updatePropertyInSuborderMemoized = useCallback((id: string, property: string, value: unknown, forceRerender?: boolean) => {
		dispatch(actions.updateProductProperty({ id, [property]: value, target: 'suborder', forceRerender: !!forceRerender }));
	}, []);
	const updateInputInPaintDraftOrderToningServiceMemoized = useCallback((id: string, property: string, value: string) => {
		dispatch(actions.updatePaintToningService({ id, [property]: value }));
	}, []);
	const updateInputInPaintToningSuborderServiceMemoized = useCallback((id: string, property: string, value: string) => {
		dispatch(actions.updatePaintToningService({ id, [property]: value }));
	}, []);
	const changeServicePriceInDraftOrderMemoized = useCallback((id: string, price: number | string, forceRerender?: boolean) => {
		dispatch(actions.updateServicePrice({ id, price, forceRerender: !!forceRerender }));
	}, []);
	const changeServicePriceInSuborderMemoized = useCallback((id: string, price: number | string, forceRerender?: boolean) => {
		dispatch(actions.updateServicePrice({ id, price, target: 'suborder', forceRerender: !!forceRerender }));
	}, []);
	/*
	 * memoized and debounced callbacks
	 */
	const updatePropertyInDraftOrderDebounced = useDebouncedCallback(updatePropertyInDraftOrderMemoized, DEBOUNCE_TIME);
	const updatePropertySuborderOrderDebounced = useDebouncedCallback(updatePropertyInSuborderMemoized, DEBOUNCE_TIME);
	const updateInputInDraftOrderPaintToningServiceDebounced = useDebouncedCallback(updateInputInPaintDraftOrderToningServiceMemoized, DEBOUNCE_TIME);
	const updateInputInPaintToningSuborderServiceDebounced = useDebouncedCallback(updateInputInPaintToningSuborderServiceMemoized, DEBOUNCE_TIME);
	const changeServicePriceInDraftOrderDebounced = useDebouncedCallback(changeServicePriceInDraftOrderMemoized, DEBOUNCE_TIME);
	const changeServicePriceInSuborderDebounced = useDebouncedCallback(changeServicePriceInSuborderMemoized, DEBOUNCE_TIME);

	/*
	 * input handlers
	 */
	const onInputChangeInDraftOrder = useCallback((id: string, property: string, formatter?: DataFormatterFn) => {
		const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			const formattedValue = formatter ? formatter(value) : value;

			updatePropertyInDraftOrderDebounced(id, property, formattedValue);
		};

		return inputChangeHandler;
	}, []);
	const onInputChangeInSuborderOrder = useCallback((id: string, property: string, formatter?: DataFormatterFn) => {
		const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			const formattedValue = formatter ? formatter(value) : value;
			const update = !!formatter ? updatePropertyInSuborderMemoized : updatePropertySuborderOrderDebounced;

			update(id, property, formattedValue, !!formatter);
		};

		return inputChangeHandler;
	}, []);

	const onPriceChangeInDraftOrderServices = useCallback((id: string, formatter?: DataFormatterFn) => {
		const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			const formattedPrice = formatter ? formatter(value) : value;
			const update = !!formatter ? changeServicePriceInDraftOrderMemoized : changeServicePriceInDraftOrderDebounced;

			update(id, formattedPrice, !!formatter);
		};

		return inputChangeHandler;
	}, []);
	const onPriceChangeInSuborderServices = useCallback((id: string, formatter?: DataFormatterFn) => {
		const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			const formattedPrice = formatter ? formatter(value) : value;
			const update = !!formatter ? changeServicePriceInSuborderMemoized : changeServicePriceInSuborderDebounced;

			update(id, formattedPrice, !!formatter);
		};

		return inputChangeHandler;
	}, []);

	/*
	 * suborders
	 */
	const createBlankSuborder = (payload?: SuborderTypePayload) => {
		dispatch(actions.createBlankSuborder(payload));
	};
	const createSuborderWithProducts = (from?: number, type?: SuborderTypePayload['type']) => {
		dispatch(actions.createSuborderWithProducts({ from, type }));
	};
	const createSuborderWithServices = (data: IService[], from?: number) => {
		dispatch(actions.createSuborderWithServices({ from, data }));
	};
	const setActiveSuborderIndex = (index: number) => {
		dispatch(actions.setActiveSuborderIndex({ index }));
	};
	const setActiveCategoryTabIndex = (index: number) => {
		dispatch(actions.setActiveCategoryTabIndex({ index }));
	};
	const setIndexOfSuborderToBeDeleted = (index: number) => {
		dispatch(actions.setIndexOfSuborderToDelete({ index }));
	};
	const clearIndexOfSuborderToBeDeleted = () => {
		dispatch(actions.clearIndexOfSuborderToDelete());
	};
	const deleteInMemorySuborder = (id: string, index: number) => {
		dispatch(actions.removeSuborder({ id, index }));
	};

	/*
	 * services manipulations
	 */
	const addServiceToDraftOrder = (service: ServiceOption) => {
		dispatch(actions.addService({ service }));
	};
	const addServiceToSuborder = (service: ServiceOption) => {
		dispatch(actions.addService({ service, target: 'suborder' }));
	};
	const deleteManyServicesInDraftOrder = (services: IService[]) => {
		dispatch(actions.removeManyServices({ services: services }));
	};
	const deleteManyServicesInSuborder = (services: IService[]) => {
		dispatch(actions.removeManyServices({ services: services, target: 'suborder' }));
	};
	const changeServiceQuantityInDraftOrder = (id: string, quantity: number) => {
		dispatch(actions.updateServiceQuantity({ id, quantity }));
	};
	const changeServiceQuantityInSuborder = (id: string, quantity: number) => {
		dispatch(actions.updateServiceQuantity({ id, quantity, target: 'suborder' }));
	};
	const changeServicePriceInDraftOrder = onPriceChangeInDraftOrderServices;
	const changeServicePriceInSuborder = onPriceChangeInSuborderServices;

	const setServiceTypeInDraftOrder = useCallback((id: string, option: IServiceType) => {
		dispatch(actions.setServiceType({ id, option }));
	}, []);
	const setServiceWorkTypeInDraftOrder = useCallback((id: string, option: IServiceWorkType) => {
		dispatch(actions.setServiceWorkType({ id, option }));
	}, []);

	const setServiceTypeInSuborder = useCallback((id: string, option: IServiceType) => {
		dispatch(actions.setServiceType({ id, option, target: 'suborder' }));
	}, []);
	const setServiceWorkTypeInSuborder = useCallback((id: string, option: IServiceWorkType) => {
		dispatch(actions.setServiceWorkType({ id, option, target: 'suborder' }));
	}, []);

	/*
	 * transferring
	 */
	const transferProducts = (params: SuborderTransferConfig) => {
		dispatch(actions.transfer(params));
		orderNotificationService.transferred(params.to);
	};
	const transferProductsWithData = (params: SuborderTransferConfigWithData) => {
		dispatch(actions.transfer(params));
		orderNotificationService.transferred(params.to);
	};
	const transferServices = (params: SuborderTransferConfigWithData) => {
		dispatch(actions.transferServices(params));
		orderNotificationService.transferredServices(params.to);
	};
	const selectProductsToTransfer = (payload: SelectTransferProductsPayload) => {
		const products = payload.map(({ original }) => original);
		dispatch(actions.selectProductToTransfer({ products }));
	};
	const setTransferPreviewProduct = (product: Product) => {
		dispatch(actions.setTransferPreviewProduct({ product }));
	};
	const clearTransferPreviewProduct = () => {
		dispatch(actions.clearTransferPreviewProduct());
	};

	/*
	 * draft order
	 */
	const toggleOrderTypeUnsafe = () => {
		dispatch(actions.toggleRootOrderType());
	};

	/*
	 * paint toning service
	 */
	const onInputChangeInPaintToningDraftOrderService = (id: string, property: string) => (option: unknown) => {
		updateInputInDraftOrderPaintToningServiceDebounced({ id, [property]: option });
	};
	const onSelectInPaintToningDraftOrderService = (id: string, property: string) => (option: unknown) => {
		dispatch(actions.updatePaintToningService({ id, [property]: option }));
	};
	const onInputChangeInPaintToningInSuborderService = (id: string, property: string) => (option: unknown) => {
		updateInputInPaintToningSuborderServiceDebounced({ id, [property]: option });
	};
	const onSelectInPaintToningIsSuborderService = (id: string, property: string) => (option: unknown) => {
		dispatch(actions.updatePaintToningService({ id, target: 'suborder', [property]: option }));
	};

	/*
	 * clearing
	 */
	const clearOrderViewer = () => {
		dispatch(actions.clearOrderViewer());
	};

	const setDeleteCandidates = (items: Product[] | IService[]) => {
		dispatch(actions.setItemsDeleteCandidates({ items }));
	};
	const clearDeleteCandidates = () => {
		dispatch(actions.clearItemsDeleteCandidates());
	};

	/*
	 * on navigate to order splitting page replaces root suborder with draft one
	 */
	const syncDraftOrderWithRootSuborder = () => {
		dispatch(actions.syncDraftOrderWithRootSuborder());
	};

	return {
		draftOrder: {
			toggleOrderTypeUnsafe,
			addService: addServiceToDraftOrder,
			onInputChangeInProducts: onInputChangeInDraftOrder,
			onSelectChangeInPaintToningService: onSelectInPaintToningDraftOrderService,
			onInputChangeInPaintToningService: onInputChangeInPaintToningDraftOrderService,
			onQuantityChangeInService: changeServiceQuantityInDraftOrder,
			onPriceChangeInService: changeServicePriceInDraftOrder,
			deleteServicesUnsafe: deleteManyServicesInDraftOrder,
			onSelectChangeServiceType: setServiceTypeInDraftOrder,
			onSelectChangeServiceWorkType: setServiceWorkTypeInDraftOrder,
			syncDraftOrderWithRootSuborder,
		},
		suborders: {
			createBlankSuborder,
			deleteInMemorySuborder,
			setActiveSuborderIndex,
			setActiveCategoryTabIndex,
			createSuborderWithProducts,
			createSuborderWithServices,
			addService: addServiceToSuborder,
			setIndexOfSuborderToBeDeleted,
			clearIndexOfSuborderToBeDeleted,
		},
		activeSuborder: {
			onInputChangeInProducts: onInputChangeInSuborderOrder,
			onSelectInPaintToningService: onSelectInPaintToningIsSuborderService,
			onInputChangeInPaintToningService: onInputChangeInPaintToningInSuborderService,
			onSelectServiceTypeInService: setServiceTypeInSuborder,
			onSelectServiceWorkTypeInService: setServiceWorkTypeInSuborder,
			setDeleteCandidates,
			clearDeleteCandidates,
			deleteManyServices: deleteManyServicesInSuborder,
			onQuantityChangeInService: changeServiceQuantityInSuborder,
			onPriceChangeInService: changeServicePriceInSuborder,
			onSelectChangeServiceType: setServiceTypeInSuborder,
			onSelectChangeServiceWorkType: setServiceWorkTypeInSuborder,
		},
		transferring: {
			transferProducts,
			transferServices,
			transferProductsWithData,
			selectProductsToTransfer,
			setTransferPreviewProduct,
			clearTransferPreviewProduct,
		},
		clearOrderViewer,
	};
};
