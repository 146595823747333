import { ORDER_NEWLY_CREATED_STATUS, ORDER_RESERVATION_STATUS } from 'const';
import { orderNotificationService } from 'services/orderNotificationService';
import { useUpdateOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { checkIfOrderIsReserved } from 'utils/shared';

import { UseApiDI } from '../types';

export const useApi = ({ utils, dialogueService, data }: UseApiDI) => {
	const [mutate, requestState] = useUpdateOrderMutation();

	const requestSaveDraftOrderUnsafe = async () => {
		const dto = utils.getDraftOrderDTO();

		return mutate(dto)
			.unwrap()
			.then(() => orderNotificationService.success('Заявку збережено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Заявку не зюережено!'));
	};
	const requestReserveDraftOrderUnsafe = async () => {
		const isReserved = checkIfOrderIsReserved(data.etalonOrder.original.status);
		const status = isReserved ? ORDER_NEWLY_CREATED_STATUS : ORDER_RESERVATION_STATUS;
		const dto = utils.getDraftOrderDTO();

		dto.status = status;

		return mutate(data)
			.unwrap()
			.then(() => orderNotificationService.success('Статус змінено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Статс не змінено!'));
	};
	const requestReserveSuborderSafe = () => {
		if (data.activeSuborder.index === 0) {
			return;
		}

		dialogueService.open('suborderReservation');
	};
	const requestReserveSuborderUnsafe = async () => {
		const dto = utils.getSubordersWithReservationDTO();

		return mutate(dto)
			.unwrap()
			.then(() => orderNotificationService.success('Статус змінено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Статс не змінено!'));
	};
	const requestSaveSplittingSafe = () => {
		dialogueService.open('saveSplitting');
	};
	const requestSaveSplittingUnsafe = async () => {
		const dto = utils.getSubordersDTO();

		return mutate(dto)
			.unwrap()
			.then(() => orderNotificationService.success('Заявку розбито та збережено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Заявку не збережено!'));
	};

	return {
		requestState,
		mutate,
		handlers: {
			requestReserveSuborderSafe,
			requestReserveSuborderUnsafe,
			requestSaveDraftOrderUnsafe,
			requestSaveSplittingSafe,
			requestSaveSplittingUnsafe,
			requestReserveDraftOrderUnsafe,
		},
	};
};
