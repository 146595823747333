import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { Fragment } from 'react';
import { numberify, toPrecise } from 'utils/shared';

import styles from './styles.module.css';
import { ServiceInputFieldProps } from './types';

function formatter(value: string) {
	const formattedValue = numberify(value) || 1;

	return toPrecise(formattedValue, 2);
}

const ServiceInputField: React.FC<ServiceInputFieldProps> = ({ id, value }) => {
	const { activeSuborder, draftOrder, utils, forceRerenderKey } = useOrderViewer();
	const source = utils.isOnSuborderSplittingPage() ? activeSuborder : draftOrder;

	const handler = source.onPriceChangeInService;

	return (
		<Fragment key={forceRerenderKey}>
			<input
				className={styles.inputField}
				defaultValue={toPrecise(numberify(value))}
				type="tel"
				onChange={handler(id)}
				onBlur={handler(id, formatter)}
			/>
		</Fragment>
	);
};

export default ServiceInputField;
