/* eslint-disable react/prop-types */
import { HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { adjustableColumnsIcons } from 'pages/CreateOrder/icons';
import React, { ReactNode } from 'react';

export type CreatePriceTypeColumnProps = {
	header: ReactNode;
	cell: ReactNode;
	accessor: string;
	color: string;
};
export const createPriceTypeColumn = ({ header, cell, accessor, color }: CreatePriceTypeColumnProps) => {
	const priceColumn = {
		Header: ({ isAdjustable }) => {
			if (isAdjustable) return header;

			return (
				<HeaderCell>
					<HeaderCellContent color={color}>{header}</HeaderCellContent>
				</HeaderCell>
			);
		},
		accessor,
		width: 56,
		Cell: () => (
			<RegularCell justify="center" width="100%">
				<RegularCellContent color={color}>{cell}</RegularCellContent>
			</RegularCell>
		),
		mobile: false,
		icon: <adjustableColumnsIcons.Uuid />,
		hidden: false,
	};

	return priceColumn;
};
