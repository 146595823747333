import { useOrderContext } from 'contexts/OrderProvider';
import React from 'react';

type FullScreenGateProps = React.PropsWithChildren;

const FullScreenGate: React.FC<FullScreenGateProps> = ({ children }) => {
	const { isFullScreenMode } = useOrderContext();

	if (isFullScreenMode) return <div style={{ display: 'none' }}>{children}</div>;

	return children;
};

export default FullScreenGate;
