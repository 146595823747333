import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IAuthResponse } from 'models/IAuthResponse';
import { ILogin } from 'models/IUser';

export const loginRequest = createAsyncThunk('auth/login', async (loginData: ILogin, thunkAPI) => {
	try {
		const response = await axios.post<IAuthResponse>('https://unitb-appback.alterego.biz.ua/api/v1/auth/sign_in', loginData, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return { res: response.data };
	} catch (e) {
		return thunkAPI.rejectWithValue(e);
	}
});
