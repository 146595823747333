import clsx from 'clsx';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalHeader from 'components/Modal/ModalHeader';
import MainSearch from 'components/NavBarMain/MainSearch';
import Spinner from 'components/Spinner';
import Textarea from 'components/Textarea';
import { breakPoints, ROUTES_URLS } from 'const';
import { useOrderComment } from 'hooks/orders';
import { useAppDispatch } from 'hooks/redux';
import { useMainSearch } from 'hooks/useMainSearch';
import BottomPanel from 'layouts/PageLayout/BottomPanel';
import React, { type MouseEvent, useEffect, useState } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import PlusIcon from 'static/images/plus.svg';
import PlusBlue from 'static/images/plus-blue.svg';
import TrashIcon from 'static/images/trash.svg';
import { setSearchBy } from 'store/reducers/search';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';
import type { IProps } from './types';

const EmptyOrder: React.FC<IProps> = ({ className }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { config } = useMainSearch({ flow: 'order:empty-order' });

	const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
	const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });
	const comment = useOrderComment({ onSuccess: closeDrawer });

	useEffect(() => {
		dispatch(setSearchBy({ searchBy: 'product' }));
	}, []);

	const handleAddCommentClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		setIsPopupOpen(true);
	};

	const handleAddProductClick = () => {
		const url = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id: comment.orderId });

		navigate(url);
	};

	function closeDrawer() {
		setIsPopupOpen(false);
	}

	const popupContent = (
		<>
			<Textarea
				name="comment"
				{...(!isMobile && { label: 'Додати коментар' })}
				placeholder="Наприклад, про компанію з якою ви працюєте"
				className={clsx(styles.textarea, { [styles.small]: isMobile })}
				setValue={comment.setComment}
				value={comment.value}
			/>

			<div className={styles.controlsWrapper}>
				<Button onClick={comment.delete} icon={TrashIcon} variant="default" text="Видалити" background="#D71B87" />
				<Button onClick={comment.save} icon={PlusIcon} variant="default" text="Зберегти" />
			</div>
		</>
	);

	return (
		<div className={clsx(styles.wrapper, className)}>
			<MainSearch {...config} />

			<div className={styles.subWrapper}>
				<p className={clsx('text-md-semibold', styles.addFirstProduct, styles.empty)}>Додайте перший товар до списку</p>

				<p className={clsx('text-sm-regular', 'color-grey-600', styles.emptyState)}>
					{`За вашим запитом "${'s'}" не знайдено жодного співпадіння. Будь ласка, спробуйте ще раз.`}
				</p>

				<div className={styles.controlsWrapper}>
					<MediaQuery minWidth={breakPoints.MOBILE - 1}>
						<Button
							text="Додати коментар"
							onClick={handleAddCommentClick}
							icon={PlusBlue}
							variant="rounded"
							background="#ffffff"
							hoverBg="#000"
							className={styles.inverseButton}
						/>
						<Button
							text="Додати товар"
							onClick={handleAddProductClick}
							icon={PlusIcon}
							variant="rounded"
							background="#2E90FA"
							hoverBg="#000"
							className={styles.addProductButton}
						/>
					</MediaQuery>

					<MediaQuery minWidth={breakPoints.MOBILE}>
						<Drawer open={isPopupOpen} title="Додати" onClose={closeDrawer}>
							{popupContent}
						</Drawer>
					</MediaQuery>

					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<Modal open={isPopupOpen} onClose={closeDrawer} className={styles.modalOverlay}>
							<ModalHeader title="Додати коментар" onXCloseClick={closeDrawer} />
							<ModalBody className={clsx(styles.modalBody, 'hide-scroll-bar')}>{popupContent}</ModalBody>
						</Modal>
					</MediaQuery>
				</div>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<BottomPanel className={styles.bottomPanel}>
						<Button
							text="Додати коментар"
							onClick={handleAddCommentClick}
							icon={PlusIcon}
							variant="default"
							background="#ffffff"
							hoverBg="#000"
						/>
						<Button
							text="Додати товар"
							onClick={handleAddProductClick}
							icon={PlusIcon}
							variant="default"
							background="red"
							hoverBg="#000"
							className={styles.bottomPanelAddProductButton}
						/>
					</BottomPanel>
				</MediaQuery>

				{comment.isLoading && <Spinner overlayBg="rgba(0, 0, 0, 0.5);" />}
			</div>
		</div>
	);
};

export default EmptyOrder;
